import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import "./index.scss";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { RESET_PASSWORD, API_URL_USER } from "../api/constant";
import { invokeApi } from "../api/index";
import rapid from "../../resources/icons/rapid-logo.png";

const ResetPassword = () => {
  const history = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [signupDetails, setSignupDetails] = useState({});

  const onInputChange = (event) => {
    setSignupDetails({
      ...signupDetails,
      [event.target.name]: event.target.value,
      [event.target.name + "Err"]: false,
    });
  };

  const isFormValid = () => {
    let errors = {};

    if (!signupDetails.password) {
      errors["passwordErr"] = true;
    }

    if (signupDetails.confirmPassword !== signupDetails.password) {
      errors["confirmPasswordErr"] = true;
    }

    if (Object.keys(errors).length !== 0) {
      setSignupDetails({ ...signupDetails, ...errors });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      let urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get("token");
      let payload = {
        password: signupDetails.password,
      };
      invokeApi(
        "POST",
        `${API_URL_USER}${RESET_PASSWORD}?token=${token}`,
        payload,
        null,
        true
      ).then((response) => {
        if (response.status == 204) {
          toast.success("Password Reset Successfully", { autoClose: 2000 });
          history("/login");
        } else {
          toast.error("Password Reset Failed, Try again", { autoClose: 2000 });
          history("/forgotpassword");
        }
      });
    }
  };

  return (
    <div className={"login"}>
      <Grid container>
        <Grid item md={4} xs={12} className={"loginBanner"}>
          <div
            style={{
              position: "relative",
              top: "20%",
              color: "white",
              fontSize: "50px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              src={rapid}
              alt="Rapid"
              style={{ width: "150px", height: "50px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{ padding: "50px", paddingRight: "80px" }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#085575",
              marginBottom: "20px",
            }}
          >
            Reset Password
          </div>
          <div>
            <TextField
              name="password"
              onChange={onInputChange}
              color="primary"
              placeholder="Password"
              value={signupDetails.password}
              variant="standard"
              type={showPassword ? "text" : "password"}
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: signupDetails.passwordErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility style={{ color: "#0fb9c8" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#0fb9c8" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div>
            <TextField
              name="confirmPassword"
              onChange={onInputChange}
              color="primary"
              placeholder="Confirm Password"
              value={signupDetails.confirmPassword}
              variant="standard"
              type={showPasswordConfirm ? "text" : "password"}
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: signupDetails.confirmPasswordErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPasswordConfirm(!showPasswordConfirm)
                      }
                      edge="end"
                    >
                      {showPasswordConfirm ? (
                        <Visibility style={{ color: "#0fb9c8" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#0fb9c8" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <span
              style={{
                display: signupDetails.confirmPasswordErr ? "" : "none",
                fontSize: "10px",
                fontWeight: 100,
                color: "red",
              }}
            >
              Confirm password doesn't match
            </span>
          </div>

          <div
            style={{
              textAlign: "center",
              margin: "20px 0px 5px 0px",
              width: "104%",
              display: "flex",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              style={{ backgroundColor: "#085575", margin: "10px" }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                history("/login");
              }}
              style={{ backgroundColor: "#085575", margin: "10px" }}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResetPassword;
