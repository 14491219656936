import React from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "./signup";
import LoginForm from "./login";
import "./index.scss";

const Login = () => {
  const history = useNavigate();
  const isLoggedIn = JSON.parse(sessionStorage.getItem("user"))?.tokens
    ? true
    : false;
  let signUp = false;
  if (isLoggedIn) {
    history("/dashboard");
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("signUp") === "true") {
      signUp = true;
    }
  }

  return <div>{signUp ? <SignUp /> : <LoginForm />}</div>;
};

export default Login;
