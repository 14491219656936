import React, { useState, useEffect } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "./index.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import { toast } from "react-toastify";

const FileUpload = ({
  title,
  clsName,
  callBack,
  value,
  setIsFileUploading,
}) => {
  const [file, setFile] = useState(null);

  const onChange = (e) => {
    if (e.target.files[0].name.includes("tar.gz")) {
      setIsFileUploading(true);

      setFile(e.target.files[0]);
    } else {
      toast.error("File Not Valid", { autoClose: 2000 });
    }
  };
  useEffect(() => {
    callBack(file);
  }, [file]);
  useEffect(() => {
    if (value !== "" && value !== undefined) {
      setFile((prevVal) => ({ ...prevVal, name: value }));
    }
  }, [value]);
  return (
    <form className={`spidle-upload-file  ${clsName} `}>
      {file === null ? (
        <>
          <label
            htmlFor="file-upload"
            className="MuiButtonBase-root upload-button"
          >
            <span className="file-title">{`${title}  `}</span>
            <FileUploadOutlinedIcon
              style={{ color: "#0fcbd0", padding: "5px" }}
            />
          </label>
        </>
      ) : (
        <label title={(file && file.name) || ""}>
          <p className="file-title">{(file && file.name) || ""}</p>
        </label>
      )}
      <input
        id="file-upload"
        type="file"
        accept=".tar.gz"
        onChange={(event) => {
          onChange(event);
          event.target.value = null;
        }}
      />
      {file !== null && (
        <span>
          <IconButton
            style={{ padding: "5px", color: "red" }}
            onClick={() => setFile(null)}
            title="Clear"
          >
            <DeleteIcon />
          </IconButton>
        </span>
      )}
    </form>
  );
};

export default FileUpload;
