import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import { TextField, Button } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { invokeApi, checkPermission } from "./api/index";
import {
  API_URL_DEVICE,
  DEVICE,
  GROUPS,
  DEVICE_DROPDOWN,
  GROUP_DROPDOWN,
  DEVICE_EDIT,
  DEVICE_USAGE,
  UNGROUPED,
  ADD_NEW_DEVICES_TO_GROUP,
  REMOVE_DEVICES_FROM_GROUP,
  DELETE_GROUP,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "../components/common/Select";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "24px 0px 24px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columns = [
  { id: "controllerId", label: "Controller Id" },
  { id: "deviceName", label: "Device Name" },
  { id: "description", label: "Description" },
  { id: "status", label: "Status" },
  { id: "createdBy", label: "Created By" },
  { id: "createdAt", label: "Created Date" },
  { id: "updatedBy", label: "Modified By" },
  { id: "updatedAt", label: "Modified Date" },
  { id: "actions", label: "Actions", align: "center" },
];

const groupColumns = [
  { id: "name", label: "Group Name" },
  { id: "description", label: "Description" },
  { id: "createdBy", label: "Created By" },
  { id: "createdAt", label: "Created Date" },
  { id: "updatedBy", label: "Modified By" },
  { id: "updatedAt", label: "Modified Date" },
  { id: "actions", label: "Actions" },
];

const Devices = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [groupTotalList, setGroupTotalList] = useState(0);
  const [groupPage, setGroupPage] = useState(0);
  const [deviceTotalList, setDeviceTotalList] = useState(0);
  const [devicePage, setDevicePage] = useState(0);
  const [modal, setModal] = useState({});
  const [groupModal, setGroupModal] = useState({ open: false });
  const [obj, setObj] = useState({});
  const [nameErr, setNameErr] = useState(false);
  const [devices, setDevices] = useState([]);
  const [groupDevices, setGroupDevices] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupBuffer, setGroupBuffer] = useState([]);
  const [domainName, setDomainName] = useState("");
  const [groupRefresh, setGroupRefresh] = useState(0);
  const [deviceBuffer, setDeviceBuffer] = useState([]);
  const [deviceRefresh, setDeviceRefresh] = useState(0);
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(null);
  const [column, setColumn] = useState(null);
  const [edit, setEdit] = useState(null);
  const [isAllowed, setIsAllowed] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [allowedDevices, setAllowedDevices] = useState({});
  const [active, setActive] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [checks, setChecks] = useState([]);
  const [copyClipboardInput, setCopyClipboardInput] = useState("");
  const [isInputDisable, setIsInputDisable] = useState(false);
  const [value, setValue] = React.useState(0);
  const [delModal, setDelModal] = useState({
    id: null,
    status: false,
    type: "",
  });
  const user = JSON.parse(sessionStorage.getItem("user"))?.user;
  const limits = JSON.parse(sessionStorage.getItem("user"))?.limits;
  useEffect(() => {
    setDomainName(window.location.host);
  }, []);
  useEffect(() => {
    props.headerName("Devices & Groups");
    let allowed = checkPermission(
      "ALLOW_REGISTER_DEVICE",
      user?.isAdmin,
      limits?.allowRegisterDevice
    );
    setIsAllowed(allowed);
    grpDropDownUpdate();
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE_USAGE}`)
      .then((res) => {
        setAllowedDevices((prev) => ({
          ...prev,
          devices: res.devices,
          groups: res.groups,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setGroupFilter(group);
    setDeviceFilter(device);
    setDevicePage(0);
    setDeviceBuffer([]);
    setDeviceRefresh(Math.random());
  }, [group, device]);

  useEffect(() => {
    if (groupFilter != null) {
      groupChange(groupFilter);
    }
  }, [groupFilter]);

  useEffect(() => {
    groupUpdate(groupPage + 1);
  }, [groupRefresh]);

  useEffect(() => {
    deviceFetch(devicePage + 1);
  }, [deviceRefresh]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setChecks([]);
    setRefresh(Math.random());
  };

  const deleteGroupFun = (val) => {
    invokeApi("DELETE", `${API_URL_DEVICE}${DELETE_GROUP}` + val)
      .then((res) => {
        if (res.code == 400) {
          toast.error(res.message, { autoClose: 2000 });
        } else if (res.status == 204) {
          toast.success("Group deleted Successfully", { autoClose: 2000 });
          setGroupPage(0);
          setGroupBuffer([]);
          setGroupRefresh(Math.random());
        }
      })
      .catch((err) => {
        console.log("delete group failed", err);
      });
  };

  const deleteDeviceFun = (val) => {
    invokeApi("PUT", `${API_URL_DEVICE}${DEVICE_EDIT}` + val, {
      active: false,
    })
      .then((res) => {
        setDevicePage(0);
        setDeviceBuffer([]);
        setDeviceRefresh(Math.random());
      })
      .catch((err) => {
        console.log("Delete Device failed");
      });
  };

  const deleteDevice = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
          }}
        >
          <span style={{ paddingLeft: "40%" }}>Are You Sure ?</span>

          <span
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
                type: "",
              }));
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px 20px 0px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              if (delModal.type === "device") {
                deleteDeviceFun(delModal.id);
                setDelModal((prevVal) => ({
                  ...prevVal,
                  id: null,
                  status: false,
                  type: "",
                }));
              } else {
                deleteGroupFun(delModal.id);
                setDelModal((prevVal) => ({
                  ...prevVal,
                  id: null,
                  status: false,
                  type: "",
                }));
              }
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const deleteFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={delModal.status === true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setDelModal((prevVal) => ({
              ...prevVal,
              status: false,
              id: null,
              type: "",
            }));
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, maxWidth: "550px" }}>
          {deleteDevice()}
        </Box>
      </Modal>
    );
  };

  const createData = (
    deviceId,
    id,
    controllerId,
    deviceName,
    description,
    stat,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  ) => {
    let status = (
      <div style={{ display: "flex", paddingLeft: 10 }}>
        {stat == "ONLINE" ? (
          <CheckCircleIcon style={{ color: "green" }} />
        ) : (
          <CancelIcon style={{ color: "red" }} />
        )}{" "}
        {/* <DeleteOutlineIcon
          style={{ color: "#28d1d5", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            deleteFun(deviceId);
          }}
        />
        <EditIcon
          style={{
            color: "#28d1d5",
            cursor: "pointer",
            height: "25px",
            width: "20px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setEdit(deviceId);
          }}
        /> */}
      </div>
    );
    let actions = (
      <div>
        <DeleteOutlineIcon
          style={{ color: "#28d1d5", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            // deleteFun(deviceId);
            setDelModal((prevVal) => ({
              ...prevVal,
              id: deviceId,
              status: true,
              type: "device",
            }));
          }}
        />
        <EditIcon
          style={{
            color: "#28d1d5",
            cursor: "pointer",
            height: "25px",
            width: "20px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setEdit(deviceId);
          }}
        />
      </div>
    );
    return {
      id,
      controllerId,
      deviceName,
      description,
      status,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      deviceId,
      actions,
    };
  };

  const removeDevice = (array) => {
    if (array.length > 0) {
      invokeApi(
        "POST",
        `${API_URL_DEVICE}${REMOVE_DEVICES_FROM_GROUP}` +
          groupModal.details.gId,
        {
          deviceIds: array,
        }
      )
        .then((response) => {
          if (response.id) {
            toast.success("Devices Removed successfully", {
              autoClose: 2000,
            });
            setGroupModal({ open: false });
            // editFetch(groupModal.details);
          } else {
            toast.error("Removing device failed", {
              autoClose: 2000,
            });
          }
        })
        .catch((err) => {
          console.log("Removing device failed");
        });
    } else {
      toast.success("Group Updated successfully", {
        autoClose: 2000,
      });
      setGroupModal({ open: false });
    }
  };

  useEffect(() => {
    setGroupModal({
      ...groupModal,
      groupedDevices: groupModal.groupedDevices?.map((iter) => {
        return {
          ...iter,
          checkBox: (
            <Checkbox
              checked={checks.includes(iter.deviceId)}
              onChange={() => {
                checkFun(iter.deviceId);
              }}
            />
          ),
          actions: (
            <div
              onClick={() => {
                checkFun(iter.deviceId);
              }}
              style={{
                backgroundColor: checks.includes(iter.deviceId)
                  ? "#e56767"
                  : "#075a6a",
                borderRadius: "20px",
                padding: "5px",
                color: "white",
                cursor: "pointer",
                width: "max-content",
              }}
            >
              <RemoveIcon
                style={{
                  fontSize: "20px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginRight: "5px",
                  color: "#0fcbd0",
                  position: "relative",
                  top: "2px",
                }}
              />
              <span
                style={{
                  position: "relative",
                  top: "-5px",
                  fontSize: "12px",
                }}
              >
                Remove Device
              </span>
            </div>
          ),
        };
      }),
      ungroupedDevices: groupModal.ungroupedDevices?.map((iter) => {
        return {
          ...iter,
          checkBox: (
            <Checkbox
              checked={checks.includes(iter.deviceId)}
              onChange={() => {
                checkFun(iter.deviceId);
              }}
            />
          ),
          actions: (
            <div
              onClick={() => {
                checkFun(iter.deviceId);
              }}
              style={{
                backgroundColor: checks.includes(iter.deviceId)
                  ? "#e56767"
                  : "#075a6a",
                borderRadius: "20px",
                padding: "5px",
                color: "white",
                cursor: "pointer",
                width: "max-content",
              }}
            >
              <AddIcon
                style={{
                  fontSize: "20px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  color: "#0fcbd0",
                  marginRight: "5px",
                  position: "relative",
                  top: "2px",
                }}
              />
              <span
                style={{
                  position: "relative",
                  top: "-5px",
                  fontSize: "12px",
                }}
              >
                Add Device
              </span>
            </div>
          ),
        };
      }),
    });
  }, [refresh]);

  const checkFun = (deviceId) => {
    if (checks.includes(deviceId)) {
      let c = checks;
      c.splice(c.indexOf(deviceId), 1);
      setChecks(c);
      setRefresh(Math.random());
    } else {
      let c = checks;
      c.push(deviceId);
      setChecks(c);
      setRefresh(Math.random());
    }
  };

  const deviceTabelCreateData = (
    deviceId,
    id,
    controllerId,
    deviceName,
    description,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    bool
  ) => {
    let checkBox = (
      <Checkbox
        checked={false}
        onChange={() => {
          checkFun(deviceId);
        }}
      />
    );
    let actions = (
      <div
        onClick={() => {
          checkFun(deviceId);
        }}
        style={{
          backgroundColor: "#075a6a",
          borderRadius: "20px",
          padding: "5px",
          color: "white",
          cursor: "pointer",
          width: "max-content",
        }}
      >
        {bool ? (
          <>
            <RemoveIcon
              style={{
                fontSize: "20px",
                backgroundColor: "white",
                borderRadius: "10px",
                marginRight: "5px",
                color: "#0fcbd0",
                position: "relative",
                top: "2px",
              }}
            />
            <span
              style={{
                position: "relative",
                top: "-5px",
                fontSize: "12px",
              }}
            >
              Remove Device
            </span>
          </>
        ) : (
          <>
            <AddIcon
              style={{
                fontSize: "20px",
                backgroundColor: "white",
                borderRadius: "10px",
                color: "#0fcbd0",
                position: "relative",
                marginRight: "5px",
                top: "2px",
              }}
            />
            <span
              style={{
                position: "relative",
                top: "-5px",
                fontSize: "12px",
              }}
            >
              Add Device
            </span>
          </>
        )}
      </div>
    );

    return {
      id,
      checkBox,
      controllerId,
      deviceName,
      description,
      status,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      deviceId,
      actions,
    };
  };

  const editFetch = (details) => {
    if (details == null) {
      invokeApi("GET", `${API_URL_DEVICE}${UNGROUPED}`)
        .then((res) => {
          let unGroup = res.results.map((iter, pos) => {
            return deviceTabelCreateData(
              iter.id,
              pos,
              iter.controllerId,
              iter.deviceName,
              iter.description,
              iter.status,
              iter.createdBy,
              iter.createdAt
                ? moment(iter.createdAt).local().format("DD MMM YYYY (hh:mm A)")
                : "",
              iter.updatedBy,
              iter.updatedAt
                ? moment(iter.updatedAt).local().format("DD MMM YYYY (hh:mm A)")
                : "",
              false
            );
          });
          setGroupModal({
            open: true,
            details: details,
            groupedDevices: [],
            ungroupedDevices: unGroup,
          });
        })
        .catch((err) => {
          console.log("Fetching Ungrouped Device list failed");
        });
    } else {
      invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + details.gId)
        .then((res) => {
          let devicedata = res.map((iter, pos) => {
            return deviceTabelCreateData(
              iter.id,
              pos,
              iter.controllerId,
              iter.deviceName,
              iter.description,
              iter.status,
              iter.createdBy,
              iter.createdAt
                ? moment(iter.createdAt).local().format("DD MMM YYYY (hh:mm A)")
                : "",
              iter.updatedBy,
              iter.updatedAt
                ? moment(iter.updatedAt).local().format("DD MMM YYYY (hh:mm A)")
                : "",
              true
            );
          });
          invokeApi("GET", `${API_URL_DEVICE}${UNGROUPED}`)
            .then((res) => {
              let unGroup = res.results.map((iter, pos) => {
                return deviceTabelCreateData(
                  iter.id,
                  pos,
                  iter.controllerId,
                  iter.deviceName,
                  iter.description,
                  iter.status,
                  iter.createdBy,
                  iter.createdAt
                    ? moment(iter.createdAt)
                        .local()
                        .format("DD MMM YYYY (hh:mm A)")
                    : "",
                  iter.updatedBy,
                  iter.updatedAt
                    ? moment(iter.updatedAt)
                        .local()
                        .format("DD MMM YYYY (hh:mm A)")
                    : "",
                  false
                );
              });
              setGroupModal({
                open: true,
                details: { ...details },
                groupedDevices: devicedata,
                ungroupedDevices: unGroup,
              });
            })
            .catch((err) => {
              console.log("Fetching Ungrouped Device list failed");
            });
        })
        .catch((err) => {
          console.log("Fetching Device list failed");
        });
    }
  };

  const createGroupData = (
    gId,
    name,
    description,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt
  ) => {
    let actions = (
      <div>
        <DeleteOutlineIcon
          style={{ color: "#28d1d5", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            // deleteGroupFun(gId);
            setDelModal((prevVal) => ({
              ...prevVal,
              id: gId,
              status: true,
              type: "group",
            }));
          }}
        />
        <EditIcon
          style={{
            color: "#28d1d5",
            cursor: "pointer",
            height: "25px",
            width: "20px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            editFetch({
              gId,
              name,
              description,
              createdBy,
              createdAt,
              updatedBy,
              updatedAt,
              actions,
            });
          }}
        />
      </div>
    );
    return {
      gId,
      name,
      description,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      actions,
    };
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = res.map((iter) => {
          return { value: iter.id, name: iter.deviceName };
        });
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
  };

  const copyToClipboard = (text) => {
    if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        toast.success("Command Copied..!", { autoClose: 2000 });
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        toast.error("Copy to clipboard failed.", ex, { autoClose: 2000 });
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  const grpDropDownUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          // if (data.length > 0) {
          //   groupChange(data[0].value);
          // }
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  const deviceFetch = (pg) => {
    if (!deviceBuffer.includes(pg)) {
      let params = group
        ? {
            page: pg,
            group: group,
          }
        : {
            page: pg,
          };
      invokeApi("GET", `${API_URL_DEVICE}` + DEVICE_DROPDOWN, null, params)
        .then((response) => {
          if (response) {
            let data = response.results
              .filter((iter) => iter.active)
              .map((iter, pos) => {
                return createData(
                  iter.id,
                  pos,
                  iter.controllerId,
                  iter.deviceName,
                  iter.description,
                  iter.status,
                  iter.createdBy,
                  moment(iter.createdAt)
                    .local()
                    .format("DD MMM YYYY (hh:mm A)"),
                  iter.updatedBy,
                  moment(iter.updatedAt).local().format("DD MMM YYYY (hh:mm A)")
                );
              });
            let array = deviceBuffer;
            array.push(pg);
            setDeviceBuffer(array);
            setDeviceTotalList(response.totalResults);
            if (pg == 1) {
              setDevices(data);
              setGroupDevices(data);
            } else {
              setDevices([...devices, ...data]);
              setGroupDevices([...groupDevices, ...data]);
            }
          } else {
            toast.error("Fetching Devices failed", { autoClose: 2000 });
          }
        })
        .catch((err) => {
          console.log("Fetching devices failed", err);
        });
    }
  };

  const groupUpdate = (pg) => {
    if (!groupBuffer.includes(pg)) {
      invokeApi("GET", `${API_URL_DEVICE}${GROUPS}`, null, { page: pg })
        .then((response) => {
          let grp = response.results.map((iter) => {
            return createGroupData(
              iter.id,
              iter.name,
              iter.description,
              iter.createdBy,
              iter.createdAt
                ? moment(iter.createdAt).local().format("DD MMM YYYY (hh:mm A)")
                : "",
              iter.updatedBy,
              iter.updatedAt
                ? moment(iter.updatedAt).local().format("DD MMM YYYY (hh:mm A)")
                : ""
            );
          });
          let array = groupBuffer;
          array.push(pg);
          if (pg == 1) {
            setGroups(grp);
          } else {
            setGroups([...groups, ...grp]);
          }
          setGroupBuffer(array);
          setGroupTotalList(response.totalResults);
        })
        .catch((err) => {
          console.log("Fetching groups failed", err);
        });
    }
  };

  const editdevice = () => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "10px 20px 10px 20px",
            color: "#c1d8dd",
          }}
        >
          <span>Edit Device</span>
          <span
            onClick={() => {
              setGroupModal({ open: false });
              setEdit(null);
              setEditDetails(null);
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div style={{ padding: "20px" }}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} md={2}>
              <div>Controller Id</div>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                name="name"
                className={
                  editDetails?.controllerId.length < 1
                    ? "createGroupFieldsError"
                    : "createGroupFields"
                }
                onChange={(e) => {
                  setEditDetails({
                    ...editDetails,
                    controllerId: e.target.value,
                  });
                }}
                color="primary"
                placeholder="Name"
                value={editDetails?.controllerId}
                variant="standard"
                style={{ width: "75%" }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <div>Device Name</div>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                name="name"
                className={
                  editDetails?.deviceName.length < 1
                    ? "createGroupFieldsError"
                    : "createGroupFields"
                }
                onChange={(e) => {
                  setEditDetails({
                    ...editDetails,
                    deviceName: e.target.value,
                  });
                }}
                color="primary"
                placeholder="Name"
                value={editDetails?.deviceName}
                style={{ width: "75%" }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <div>Description</div>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                name="name"
                className={
                  editDetails?.description.length < 1
                    ? "createGroupFieldsError"
                    : "createGroupFields"
                }
                onChange={(e) => {
                  setEditDetails({
                    ...editDetails,
                    description: e.target.value,
                  });
                }}
                color="primary"
                placeholder="Name"
                value={editDetails?.description}
                variant="standard"
                style={{ width: "75%" }}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
              width: "100px",
            }}
            onClick={() => {
              invokeApi("PUT", `${API_URL_DEVICE}${DEVICE_EDIT}` + edit, {
                controllerId: editDetails.controllerId,
                description: editDetails.description,
                deviceName: editDetails.deviceName,
              })
                .then((res) => {
                  setEdit(null);
                  setEditDetails(null);
                  setDevicePage(0);
                  setDeviceBuffer([]);
                  setDeviceRefresh(Math.random());
                })
                .catch((err) => {
                  console.log("Update device failed", err);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
              width: "100px",
            }}
            onClick={() => {
              setEdit(null);
              setEditDetails(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const selectAll = (e) => {
    let array = [];
    if (e) {
      if (value == 0) {
        array = groupModal.groupedDevices.map((iter) => {
          return iter.deviceId;
        });
      } else {
        array = groupModal.ungroupedDevices.map((iter) => {
          return iter.deviceId;
        });
      }
      setChecks(array);
      setRefresh(Math.random());
    } else {
      setChecks([]);
      setRefresh(Math.random());
    }
  };

  const editModalFun = (val) => {
    return (
      <Paper sx={{ width: "100%" }} style={{ width: "75vw" }}>
        <TableContainer style={{ maxHeight: "50vh", overflow: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key="head">
                <TableCell key={"check"}>
                  <Checkbox
                    onChange={(e) => {
                      selectAll(e.target.checked);
                    }}
                  />
                </TableCell>
                {columns.map((col) => (
                  <TableCell
                    key={col.id}
                    align={col.align}
                    style={{ minWidth: col.minWidth, fontWeight: 900 }}
                  >
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(val == "edit"
                ? groupModal.groupedDevices?.length
                : groupModal.ungroupedDevices?.length) > 0 ? (
                (val == "edit"
                  ? groupModal.groupedDevices
                  : groupModal.ungroupedDevices
                )
                  .filter((i) => {
                    if (filter != null) {
                      return i[column] == filter;
                    } else {
                      return true;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, pos) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        // onClick={() => {
                        //   setModal({
                        //     ...modal,
                        //     open: true,
                        //     details: row,
                        //     register: false,
                        //   });
                        // }}
                        // style={{ cursor: "pointer" }}
                      >
                        <TableCell key={"check" + pos}>
                          {row.checkBox}
                        </TableCell>
                        {columns.map((col) => {
                          const value = row[col.id];
                          return (
                            <TableCell key={col.id} align={col.align}>
                              {col.format && typeof value === "number"
                                ? col.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableCell colSpan={10} align="center">
                  No records found
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={
            (val == "edit"
              ? groupModal.groupedDevices
              : groupModal.ungroupedDevices
            )?.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  };

  const groupModalContent = () => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "10px 20px 10px 20px",
            color: "#c1d8dd",
          }}
        >
          <span>
            {groupModal?.details == null ? "Create Group" : "Edit Group"}
          </span>
          <span
            onClick={() => {
              setGroupModal({ open: false });
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <Grid
          container
          spacing={2}
          style={{
            width: "100%",
            margin: "0px",
            padding: "20px 30px 0px 10px",
          }}
        >
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} style={{ alignItems: "center" }}>
              <Grid
                item
                xs={12}
                md={3}
                style={{
                  // top: "10px",
                  position: "relative",
                  fontWeight: 200,
                  maxWidth: "fit-content",
                }}
              >
                <span style={{ float: "right" }}>Group Name</span>
              </Grid>
              <Grid item xs={12} md={8}>
                {groupModal?.details != null ? (
                  <span
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "8px 2px 8px 12px",
                        fontWeight: "normal",
                      }}
                    >
                      {groupModal?.details?.name}
                    </div>
                  </span>
                ) : (
                  <TextField
                    name="name"
                    className={
                      nameErr ? "createGroupFieldsError" : "createGroupFields"
                    }
                    onChange={(e) => {
                      setObj({
                        ...obj,
                        name: e.target.value,
                      });
                      setNameErr(false);
                    }}
                    color="primary"
                    placeholder="Name"
                    fullWidth
                    value={obj.name}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              spacing={0}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid
                item
                xs={12}
                md={2}
                style={{
                  top: "6px",
                  position: "relative",
                  fontWeight: 200,
                  paddingRight: 6,
                }}
              >
                <span style={{ float: "right" }}>Query</span>
              </Grid>
              <Grid item xs={12} md={3}>
                <Select
                  list={[{ name: "Controller Id", value: "controllerId" }]}
                  name={"Column"}
                  value={column}
                  onChange={(e) => {
                    if (e == null) {
                      setGroupDevices(devices);
                    }
                    setColumn(e);
                  }}
                  style={{
                    borderRadius: "5px",
                    padding: "7px",
                    marginTop: "0px",
                  }}
                ></Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="query"
                  fullWidth
                  className="createGroupFields"
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                  color="primary"
                  placeholder="Query"
                  value={obj.query}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: "10px 20px 20px 20px" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Edit Devices"
                  {...a11yProps(0)}
                  style={{ textTransform: "none" }}
                />
                <Tab
                  label="Add Devices"
                  {...a11yProps(1)}
                  style={{ textTransform: "none" }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {editModalFun("edit")}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {editModalFun("add")}
            </TabPanel>
          </Box>
        </div>
        <div className="footer">
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              if (value == 0) {
                if (groupModal.details == null) {
                  if (obj.name) {
                    invokeApi("POST", `${API_URL_DEVICE}${GROUPS}`, {
                      name: obj.name,
                      deviceIds: checks,
                      active: true,
                    })
                      .then((response) => {
                        if (response.id) {
                          toast.success("Group Created successfully", {
                            autoClose: 2000,
                          });
                          setGroupPage(0);
                          setGroupBuffer([]);
                          setGroupRefresh(Math.random());
                          setGroupModal({ open: false });
                          //editFetch({ gId: response.id });
                        } else {
                          toast.error("Group Creation failed", {
                            autoClose: 2000,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log("Group Creation failed", err);
                      });
                  } else {
                    setNameErr(true);
                  }
                } else {
                  removeDevice(checks);
                }
              } else {
                if (groupModal.details == null) {
                  if (obj.name) {
                    invokeApi("POST", `${API_URL_DEVICE}${GROUPS}`, {
                      name: obj.name,
                      deviceIds: checks,
                      active: true,
                    })
                      .then((response) => {
                        if (response.id) {
                          toast.success("Group Created successfully", {
                            autoClose: 2000,
                          });
                          setGroupPage(0);
                          setGroupBuffer([]);
                          setGroupRefresh(Math.random());
                          setGroupModal({ open: false });
                          // editFetch({ gId: response.id });
                        } else {
                          toast.error("Group Creation failed", {
                            autoClose: 2000,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log("Group Creation failed");
                      });
                  } else {
                    setNameErr(true);
                  }
                } else {
                  if (checks.length > 0) {
                    invokeApi(
                      "POST",
                      `${API_URL_DEVICE}${ADD_NEW_DEVICES_TO_GROUP}` +
                        groupModal.details.gId,
                      {
                        deviceIds: checks,
                      }
                    )
                      .then((response) => {
                        if (response.id) {
                          toast.success("Devices added successfully", {
                            autoClose: 2000,
                          });
                          setGroupModal({ open: false });
                          //editFetch(groupModal.details);
                        } else {
                          toast.error("Adding device failed", {
                            autoClose: 2000,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log("Adding device failed");
                      });
                  } else {
                    toast.success("Group Updated successfully", {
                      autoClose: 2000,
                    });
                    setGroupModal({ open: false });
                  }
                }
              }
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              setGroupModal({ open: false });
            }}
          >
            Close
          </Button>
        </div>
      </>
    );
  };

  const modalContent = () => {
    if (modal.register == true) {
      return (
        <>
          <div
            style={{
              backgroundColor: "#075a6a",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              padding: "20px",
              color: "#c1d8dd",
            }}
          >
            <span>Register New Device</span>
            <span
              onClick={() => {
                setModal({});
                setCopyClipboardInput("");
                setIsInputDisable(false);
              }}
              style={{ float: "right", cursor: "pointer" }}
            >
              X
            </span>
          </div>
          <div style={{ padding: "20px" }}>
            <div style={{ fontSize: "11px", height: "20px" }}>
              To register a new device to rapid, run one of the next commands on
              the device terminal:
            </div>
            <br />
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <span
                style={{
                  backgroundColor: "#cbdfe342",
                  position: "relative",
                  top: "-10px",
                  padding: "10px",
                  left: "-20px",
                  borderRadius: "10px",
                }}
              >
                Root User
              </span>

              <div style={{ marginTop: "15px" }}>
                <Grid container spacing={2} style={{}}>
                  <Grid item xs={12} md={7}>
                    <div
                      id="cmd1"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#28d1d53b",
                        padding: "10px",
                        fontSize: "12px",
                        // maxWidth: "fit-content",
                        minWidth: "max-content",
                      }}
                    >
                      {`curl "https://downloads.rapidup.io/v1${
                        domainName === "app.rapidup.io"
                          ? "/installer"
                          : "/dev-installer"
                      }" | sh -s ` +
                        JSON.parse(sessionStorage.getItem("user")).user.token}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={5}
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <TextField
                      name="name"
                      onChange={(e) => {
                        // if (e.keyCode == 32)
                        setCopyClipboardInput(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === " ") {
                          setIsInputDisable(true);
                        }
                      }}
                      disabled={isInputDisable ? true : false}
                      color="primary"
                      placeholder="Device name"
                      variant="standard"
                      style={{
                        maxWidth: "50%",
                        border: "1px solid grey",
                        borderRadius: 10,
                        paddingLeft: 8,
                      }}
                      value={copyClipboardInput}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                    <div
                      onClick={() => {
                        if (copyClipboardInput === "") {
                          toast.error("Device Name is required", {
                            autoClose: 2000,
                          });
                        } else {
                          copyToClipboard(
                            document.getElementById("cmd1").innerHTML +
                              ` ${copyClipboardInput}`
                          );
                        }
                      }}
                      style={{
                        backgroundColor: "#08cacfde",
                        borderRadius: "10px",
                        padding: "5px 10px 5px 10px",
                        color: "white",
                        cursor: "pointer",
                        width: "150px",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "15px",
                          height: "15px",
                          padding: "5px",
                          borderRadius: "50%",
                        }}
                      >
                        <ContentCopyIcon
                          style={{ color: "#28d1d5", fontSize: "15px" }}
                        />
                      </div>{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        Copy to clipboard
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <br />

            {/* <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <span
                style={{
                  backgroundColor: "#cbdfe342",
                  position: "relative",
                  top: "-10px",
                  padding: "10px",
                  left: "-20px",
                  borderRadius: "10px",
                }}
              >
                Method 2 - Regular User
              </span>

              <div style={{ marginTop: "15px" }}>
                <Grid container spacing={2} style={{}}>
                  <Grid item xs={12} md={9}>
                    <div
                      id="cmd2"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#28d1d53b",
                        padding: "10px",
                        fontSize: "12px",
                      }}
                    >
                      Darwin Kernel Version 21.3.0: Wed Jan 5 21:37:58 PST 2022;
                      root:xnu-8019.80.24
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div
                      onClick={() => {
                        copyToClipboard(
                          document.getElementById("cmd2").innerHTML
                        );
                      }}
                      style={{
                        backgroundColor: "#08cacfde",
                        borderRadius: "10px",
                        padding: "5px 10px 5px 10px",
                        color: "white",
                        cursor: "pointer",
                        width: "150px",
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "15px",
                          height: "15px",
                          padding: "5px",
                          borderRadius: "50%",
                        }}
                      >
                        <ContentCopyIcon
                          style={{ color: "#28d1d5", fontSize: "15px" }}
                        />
                      </div>{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        Copy to clipboard
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <br /> */}
            <div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Having issues registering the device ?
              </div>
              <div style={{ fontSize: "11px", height: "20px" }}>
                Take a look at our docs troubleshooting page, the solution is
                probably there.
              </div>
            </div>
          </div>
          <hr style={{ margin: "0px 25px 25px" }} />
          <div className="footer">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#075a6a",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              onClick={() => {
                setModal({});
                setCopyClipboardInput("");
                setIsInputDisable(false);
              }}
            >
              Close
            </Button>
          </div>
        </>
      );
    }
    //  else {
    //   return (
    //     <>
    //       <div
    //         style={{
    //           backgroundColor: "#075a6a",
    //           borderTopLeftRadius: "20px",
    //           borderTopRightRadius: "20px",
    //           padding: "10px",
    //           color: "#c1d8dd",
    //         }}
    //       >
    //         <span>Create Group</span>
    //         <span
    //           onClick={() => {
    //             setModal({});
    //           }}
    //           style={{ float: "right", cursor: "pointer" }}
    //         >
    //           X
    //         </span>
    //       </div>
    //       <Grid
    //         container
    //         spacing={2}
    //         style={{
    //           width: "100%",
    //           margin: "0px",
    //           padding: "20px 30px 20px 10px",
    //         }}
    //       >
    //         <Grid item xs={12} md={6}>
    //           <Grid container spacing={2}>
    //             <Grid
    //               item
    //               xs={12}
    //               md={2}
    //               style={{ top: "10px", position: "relative" }}
    //             >
    //               Name
    //             </Grid>
    //             <Grid item xs={12} md={10}>
    //               <TextField
    //                 name="name"
    //                 className={
    //                   nameErr ? "createGroupFieldsError" : "createGroupFields"
    //                 }
    //                 onChange={(e) => {
    //                   setObj({
    //                     ...obj,
    //                     name: e.target.value,
    //                   });
    //                   setNameErr(false);
    //                 }}
    //                 color="primary"
    //                 placeholder="Name"
    //                 fullWidth
    //                 value={obj.name}
    //                 variant="standard"
    //                 InputProps={{
    //                   disableUnderline: true,
    //                 }}
    //               />
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //         <Grid item xs={12} md={6}>
    //           <Grid container spacing={0}>
    //             <Grid
    //               item
    //               xs={12}
    //               md={2}
    //               style={{ top: "10px", position: "relative" }}
    //             >
    //               <span style={{ float: "right" }}>Query</span>
    //             </Grid>
    //             <Grid item xs={12} md={4}>
    //               <Select
    //                 list={[{ name: "Controller Id", value: "controllerId" }]}
    //                 name={"Column"}
    //                 value={column}
    //                 onChange={(e) => {
    //                   if (e == null) {
    //                     setGroupDevices(devices);
    //                   }
    //                   setColumn(e);
    //                 }}
    //                 style={{
    //                   borderRadius: "5px",
    //                   padding: "7px",
    //                   marginTop: "0px",
    //                 }}
    //               ></Select>
    //             </Grid>
    //             <Grid item xs={12} md={6}>
    //               <TextField
    //                 name="query"
    //                 fullWidth
    //                 className="createGroupFields"
    //                 onChange={(e) => {
    //                   setObj({
    //                     ...obj,
    //                     query: e.target.value,
    //                   });
    //                   if (column && e.target.value) {
    //                     let data = [...devices];
    //                     let final = data.filter((iter) => {
    //                       return iter[column] == e.target.value;
    //                     });
    //                     setGroupDevices(final);
    //                   } else {
    //                     setGroupDevices(devices);
    //                   }
    //                 }}
    //                 color="primary"
    //                 placeholder="Query"
    //                 value={obj.query}
    //                 variant="standard"
    //                 InputProps={{
    //                   disableUnderline: true,
    //                 }}
    //               />
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <div style={{ padding: "20px" }}>
    //         <Paper
    //           sx={{
    //             width: "100%",
    //           }}
    //         >
    //           <TableContainer style={{ maxHeight: "50vh", overflow: "auto" }}>
    //             <Table stickyHeader aria-label="sticky table">
    //               <TableHead>
    //                 <TableRow key="head">
    //                   {columns.map((col) => (
    //                     <TableCell
    //                       key={col.id}
    //                       align={col.align}
    //                       style={{ minWidth: col.minWidth, fontWeight: 900 }}
    //                     >
    //                       {col.label}
    //                     </TableCell>
    //                   ))}
    //                 </TableRow>
    //               </TableHead>
    //               <TableBody>
    //                 {groupDevices.length > 0 ? (
    //                   groupDevices
    //                     .slice(
    //                       page * rowsPerPage,
    //                       page * rowsPerPage + rowsPerPage
    //                     )
    //                     .map((row) => {
    //                       return (
    //                         <TableRow
    //                           hover
    //                           role="checkbox"
    //                           tabIndex={-1}
    //                           key={row.id}
    //                           // onClick={() => {
    //                           //   setModal({
    //                           //     ...modal,
    //                           //     open: true,
    //                           //     details: row,
    //                           //     register: false,
    //                           //   });
    //                           // }}
    //                           // style={{ cursor: "pointer" }}
    //                         >
    //                           {columns.map((col) => {
    //                             const value = row[col.id];
    //                             return (
    //                               <TableCell key={col.id} align={col.align}>
    //                                 {col.format && typeof value === "number"
    //                                   ? col.format(value)
    //                                   : value}
    //                               </TableCell>
    //                             );
    //                           })}
    //                         </TableRow>
    //                       );
    //                     })
    //                 ) : (
    //                   <TableCell colSpan={8} align="center">
    //                     No records found
    //                   </TableCell>
    //                 )}
    //               </TableBody>
    //             </Table>
    //           </TableContainer>
    //           <TablePagination
    //             rowsPerPageOptions={[10, 25, 100]}
    //             component="div"
    //             count={groupDevices.length}
    //             rowsPerPage={rowsPerPage}
    //             page={page}
    //             onPageChange={handleChangePage}
    //             onRowsPerPageChange={handleChangeRowsPerPage}
    //           />
    //         </Paper>
    //       </div>
    //       <div className="footer">
    //         <Button
    //           variant="contained"
    //           style={{
    //             textTransform: "none",
    //             backgroundColor: "#0fcbd0",
    //             color: "white",
    //             borderRadius: "10px",
    //             margin: "10px",
    //           }}
    //           onClick={() => {
    //             if (obj.name) {
    //               invokeApi("POST", `${API_URL_DEVICE}${GROUPS}`, {
    //                 name: obj.name,
    //                 conditions: {
    //                   controllerId: groupDevices[0].controllerId,
    //                 },
    //               })
    //                 .then((response) => {
    //                   if (response.id) {
    //                     toast.success("Group Created successfully", {
    //                       autoClose: 2000,
    //                     });
    //                     groupUpdate();
    //                     setModal({});
    //                   } else {
    //                     toast.error("Group Creation failed", {
    //                       autoClose: 2000,
    //                     });
    //                   }
    //                 })
    //                 .catch((err) => {
    //                   toast.error("Group Creation failed", { autoClose: 2000 });
    //                 });
    //             } else {
    //               setNameErr(true);
    //             }
    //           }}
    //         >
    //           Save
    //         </Button>
    //         <Button
    //           variant="contained"
    //           style={{
    //             textTransform: "none",
    //             backgroundColor: "#075a6a",
    //             color: "white",
    //             borderRadius: "10px",
    //             margin: "10px",
    //           }}
    //           onClick={() => {
    //             setModal({});
    //           }}
    //         >
    //           Close
    //         </Button>
    //       </div>
    //     </>
    //   );
    // }
  };

  useEffect(() => {
    if (groupModal.open == false) {
      setChecks([]);
      setValue(0);
    }
  }, [groupModal]);

  useEffect(() => {
    if (edit != null) {
      let d = devices.find((i) => i.deviceId == edit);
      setEditDetails(d);
    }
  }, [edit]);

  const editFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={edit != null ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setEdit(null);
            setEditDetails(null);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, maxWidth: "800px" }}>
          {editdevice()}
        </Box>
      </Modal>
    );
  };

  const groupModalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={groupModal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setGroupModal({ open: false });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="createGroup"
          style={{ p: 2, width: "fit-content", minWidth: "max-content" }}
        >
          {groupModalContent()}
        </Box>
      </Modal>
    );
  };

  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({});
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, minWidth: "1090px" }}>
          {modalContent()}
        </Box>
      </Modal>
    );
  };

  const activeTab = () => {
    if (active == 0) {
      return (
        <div style={{ padding: "20px" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow key="head">
                    {columns.map((col) => (
                      <TableCell
                        key={col.id}
                        align={col.align}
                        style={{ minWidth: col.minWidth, fontWeight: 900 }}
                      >
                        {col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {devices
                    .slice(devicePage * 10, devicePage * 10 + 10)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((col) => {
                            const value = row[col.id];
                            return (
                              <TableCell key={col.id} align={col.align}>
                                {col.format && typeof value === "number"
                                  ? col.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={deviceTotalList}
              rowsPerPage={10}
              page={devicePage}
              onPageChange={(event, newPage) => {
                setDevicePage(newPage);
                setDeviceRefresh(Math.random());
              }}
            />
          </Paper>
          {modalFun()}
          {editFun()}
        </div>
      );
    } else {
      return (
        <div style={{ padding: "20px" }}>
          <Paper sx={{ width: "100%", maxHeight: "65vh", overflow: "auto" }}>
            <TableContainer sx={{ maxHeight: 400 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow key="head">
                    {groupColumns.map((col) => (
                      <TableCell
                        key={col.id}
                        align={col.align}
                        style={{ minWidth: col.minWidth, fontWeight: 900 }}
                      >
                        {col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups
                    .slice(groupPage * 10, groupPage * 10 + 10)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.gid}
                        >
                          {groupColumns.map((col) => {
                            const value = row[col.id];
                            return (
                              <TableCell key={col.id} align={col.align}>
                                {col.format && typeof value === "number"
                                  ? col.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={groupTotalList}
              rowsPerPage={10}
              page={groupPage}
              onPageChange={(event, newPage) => {
                setGroupPage(newPage);
                setGroupRefresh(Math.random());
              }}
            />
          </Paper>
          {groupModalFun()}
          {/* {modalFun()}
          {editFun()} */}
        </div>
      );
    }
  };

  let groupPrivilege =
    isAllowed &&
    (allowedDevices.groups?.cur < allowedDevices.groups?.allowed ||
      allowedDevices.groups?.allowed == -1);
  let devicePrivilege =
    isAllowed &&
    (allowedDevices.devices?.cur < allowedDevices.devices?.allowed ||
      allowedDevices.devices?.allowed == -1);
  return (
    <div>
      <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            margin: "10px",
            fontSize: ".8rem",
            paddingLeft: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filter Devices
        </div>
        <div>
          <Select
            list={groupList}
            name={"Group"}
            value={groupFilter}
            onChange={(e) => {
              if (e == null) {
                setDeviceFilter(null);
                setGroupFilter(null);
              } else {
                setGroupFilter(e);
              }
            }}
          />
        </div>
        {/* <div>
          <Select
            list={devicesList}
            value={deviceFilter}
            name={"Device"}
            onChange={(e) => {
              setDeviceFilter(e);
            }}
          />
        </div> */}
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            width: "100px",
            marginTop: "-5px",
            margin: "10px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              height: "25px",
            }}
            onClick={() => {
              dispatch(deviceFun(deviceFilter));
              dispatch(groupFun(groupFilter));
            }}
          >
            Apply
          </Button>
        </div>
      </div>

      <div>
        <div className="devicesGroup">
          <div
            style={{
              width: "fit-content",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "15px",
              position: "relative",
              top: "10px",
              height: "20px",
              left: "20px",
            }}
          >
            <span
              onClick={() => {
                setActive(0);
              }}
              style={{
                backgroundColor: active === 0 ? "#08cacfde" : "white",
                padding: "5px 15px 5px 15px",
                borderRadius: "20px",
                color: active === 0 ? "white" : "black",
                cursor: "pointer",
              }}
            >
              Devices
            </span>
            <span
              onClick={() => {
                setActive(1);
              }}
              style={{
                backgroundColor: active === 1 ? "#08cacfde" : "white",
                padding: "5px 15px 5px 15px",
                borderRadius: "20px",
                color: active === 1 ? "white" : "black",
                cursor: "pointer",
              }}
            >
              Groups
            </span>
          </div>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
            }}
          >
            <div style={{ display: "flex" }}>
              {active == 0 ? (
                <div
                  onClick={() => {
                    if (devicePrivilege) {
                      setModal({
                        ...modal,
                        open: true,
                        details: null,
                        register: true,
                      });
                    } else {
                      toast.error("Register device limit exceeded", {
                        autoClose: 2000,
                      });
                    }
                  }}
                  style={{
                    backgroundColor: devicePrivilege ? "#08cacfde" : "#c7c6c3",
                    borderRadius: "15px",
                    padding: "5px 10px 5px 10px",
                    color: "white",
                    cursor: devicePrivilege ? "pointer" : "not-allowed",
                    width: "auto",
                    marginTop: "20px",
                    marginRight: "20px",
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{ paddingTop: "3px", fontSize: "20px" }}
                  />{" "}
                  <span
                    style={{
                      position: "relative",
                      top: "-5px",
                      fontSize: "12px",
                    }}
                  >
                    Register Device
                  </span>
                </div>
              ) : (
                <div
                  onClick={() => {
                    if (groupPrivilege) {
                      editFetch(null);
                      setObj({});
                    } else {
                      toast.error("Create group limit exceeded", {
                        autoClose: 2000,
                      });
                    }
                  }}
                  style={{
                    backgroundColor: groupPrivilege ? "#075a6a" : "#c7c6c3",
                    borderRadius: "15px",
                    padding: "5px 10px 5px 10px",
                    color: "white",
                    cursor: groupPrivilege ? "pointer" : "not-allowed",
                    width: "auto",
                    marginTop: "20px",
                    marginRight: "20px",
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{ paddingTop: "3px", fontSize: "20px" }}
                  />{" "}
                  <span
                    style={{
                      position: "relative",
                      top: "-5px",
                      fontSize: "12px",
                    }}
                  >
                    Create Group
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {activeTab()}
      {deleteFun()}
    </div>
  );
};

export default Devices;
