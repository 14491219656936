import React, { useState, useEffect } from "react";
import UpgradePlan from "./UpgradePlan";
import { useNavigate } from "react-router-dom";
import Users from "./Users";
import Account from "./Account";
import { invokeApi } from "../components/api/index";
import AddCard from "./AddCard";
import { toast } from "react-toastify";
import {
  API_URL_DEVICE,
  SHOW_USER_SUBSCRIPTION,
  LIST_UPGRADE_PLAN,
} from "../components/api/constant";
const Setting = (props) => {
  const history = useNavigate();
  const [toggle, setToggle] = useState(true);
  const [active, setActive] = useState("account");
  const [defaultPlan, setDefaultPlan] = useState({});
  const [userActiveSub, setUserActiveSub] = useState({
    status: "",
    price: "",
    plan: "",
    subId: "",
    deviceSupported: "",
    invoicePeriodStart: "",
    invoicePeriodEnd: "",
    latestInvoiceStatus: "",
  });
  const [isPayment, setIspayment] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setActive(urlParams.get("tab"));
  }, [window.location.href]);

  useEffect(() => {
    props.headerName("Setting");
    invokeApi("GET", `${API_URL_DEVICE}${LIST_UPGRADE_PLAN}`)
      .then((response) => {
        if (response) {
          response.map((ele) => {
            if (ele.name === "Free Plan") {
              setDefaultPlan(ele);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    invokeApi("GET", `${API_URL_DEVICE}${SHOW_USER_SUBSCRIPTION}`)
      .then((response) => {
        if (!response.message) {
          setUserActiveSub((prevObj) => ({
            ...prevObj,
            status: response.status,
            price: response.planId.price,
            plan: response.planId.name,
            deviceSupported: response.planId.limits.allowedMaxDeviceCount,
            subId: response.id,
            invoicePeriodStart: new Date(response.invoicePeriodStart * 1000)
              .toUTCString()
              .split(" ")
              .slice(0, 4)
              .join(" "),
            invoicePeriodEnd: new Date(response.invoicePeriodEnd * 1000)
              .toUTCString()
              .split(" ")
              .slice(0, 4)
              .join(" "),
            latestInvoiceStatus: response.latestInvoiceStatus,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const activeTab = () => {
    if (active === "account") {
      return (
        <Account activeSubDetails={userActiveSub} defaultPlan={defaultPlan} />
      );
    } else if (active === "users") {
      return (
        <div>
          <Users />
        </div>
      );
    }
  };
  return (
    <div
      style={{
        padding: "20px",
        height: "80vh",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          padding: "15px",
          width: "fit-content",
          backgroundColor: "white",
          borderRadius: "15px",
        }}
      >
        <span
          onClick={() => {
            setToggle(!toggle);
            setActive("account");
            history("/setting?tab=account");
          }}
          style={{
            backgroundColor: active === "account" ? "#08cacfde" : "white",
            padding: "5px 15px 5px 15px",
            borderRadius: "20px",
            color: active === "account" ? "white" : "black",
            cursor: "pointer",
          }}
        >
          Account
        </span>
        <span
          onClick={() => {
            setToggle(!toggle);
            setActive("users");
            history("/setting?tab=users");
          }}
          style={{
            backgroundColor: active === "users" ? "#08cacfde" : "white",
            padding: "5px 15px 5px 15px",
            borderRadius: "20px",
            color: active === "users" ? "white" : "black",
            cursor: "pointer",
          }}
        >
          User Management
        </span>
      </div>
      <div style={{ paddingTop: "10px" }}>{activeTab()}</div>
    </div>
  );
};

export default Setting;
