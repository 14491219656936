import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TerminalTwoToneIcon from "@mui/icons-material/TerminalTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import { TextField, Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { invokeApi } from "./api/index";
import {
  API_URL_DEVICE,
  DEVICE,
  GROUPS,
  DEVICE_DROPDOWN,
  GROUP_DROPDOWN,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "../components/common/Select";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";

const columns = [
  { id: "controllerId", label: "Controller Id" },
  { id: "name", label: "Name" },
  { id: "status", label: "Status" },
  { id: "remoteAccess", label: "Remote Access" },
];

function createData(id, cId, name, stat, remote) {
  let status = (
    <div style={{ display: "flex" }}>
      {stat == "ONLINE" ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <CancelIcon style={{ color: "red" }} />
      )}
    </div>
  );
  let controllerId = (
    <span
      style={{
        backgroundColor: "#d2e1e3",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      {cId}
    </span>
  );
  let remoteAccess = <TerminalTwoToneIcon />;
  return {
    id,
    controllerId,
    name,
    status,
    remoteAccess,
  };
}

const RemoteAccess = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [devices, setDevices] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = res.map((iter) => {
          return { value: iter.id, name: iter.deviceName };
        });
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
    setGroupFilter(e);
  };

  const groupUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  useEffect(() => {
    props.headerName("Remote Access");
    groupUpdate();
  }, []);

  useEffect(() => {
    setDeviceFilter(device);
    setGroupFilter(group);
    let params = group ? DEVICE + group : DEVICE_DROPDOWN;
    invokeApi("GET", `${API_URL_DEVICE}` + params)
      .then((response) => {
        if (response) {
          let data = (group ? response : response.results).map((iter, pos) => {
            return createData(
              pos,
              iter.controllerId,
              iter.deviceName,
              iter.status,
              "remoteAccess"
            );
          });
          setDevices(data);
        }
      })
      .catch((err) => {
        console.log("Fetching devices failed", err);
      });
  }, [group, device]);

  useEffect(() => {
    if (groupFilter != null) {
      groupChange(groupFilter);
    }
  }, [groupFilter]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            margin: "10px",
            fontSize: ".8rem",
            paddingLeft: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filter Devices
        </div>
        <div>
          <Select
            list={groupList}
            name={"Group"}
            value={groupFilter}
            onChange={(e) => {
              if (e == null) {
                setDeviceFilter(null);
                setGroupFilter(null);
              } else {
                setGroupFilter(e);
              }
            }}
          />
        </div>
        <div>
          <Select
            list={devicesList}
            value={deviceFilter}
            name={"Device"}
            onChange={(e) => {
              setDeviceFilter(e);
            }}
          />
        </div>
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            width: "100px",
            marginTop: "-5px",
            margin: "10px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              height: "25px",
            }}
            onClick={() => {
              dispatch(deviceFun(deviceFilter));
              dispatch(groupFun(groupFilter));
              // setFilter(Math.random());
            }}
          >
            Apply
          </Button>
        </div>
      </div>

      <div style={{ padding: "20px" }}>
        <div style={{ paddingBottom: "5px" }}>Devices</div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow key="head">
                  {columns.map((col) => (
                    <TableCell
                      key={col.id}
                      align={col.align}
                      style={{ minWidth: col.minWidth, fontWeight: 900 }}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {devices
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        style={{ cursor: "pointer" }}
                      >
                        {columns.map((col) => {
                          const value = row[col.id];
                          return (
                            <TableCell key={col.id} align={col.align}>
                              {col.format && typeof value === "number"
                                ? col.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={devices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RemoteAccess;
