import * as React from "react";
import { useEffect, useState } from "react";
import { invokeApi } from "./api/index";
import { API_URL_DEVICE, DEVICE, GROUP_DROPDOWN } from "./api/constant";
import Select from "./common/Select";
import Load from "./Load";
import Traffic from "./Traffic";
import Connections from "./Connections";
import { Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";

const RealTimeGraphs = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [inf, setInf] = useState([]);
  const [propValue, setPropValue] = useState({
    dId: null,
    inf: [],
  });
  const [toggle, setToggle] = useState(0);

  const interfacesUpdate = (e) => {
    if (devicesList.length > 0) {
      let temp = devicesList.find((i) => i.value == e);
      if (temp) {
        setInf(temp.inf);
      } else {
        setInf([]);
      }
    } else {
      groupChange(groupFilter);
    }
  };

  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = [];
        devicedata = res.map((iter) => {
          return {
            value: iter.id,
            name: iter.deviceName,
            inf: iter.interfaces,
          };
        });
        setDevicesList(devicedata);
        if (deviceFilter != null) {
          let temp = devicedata.find((i) => i.value == deviceFilter);
          if (temp) {
            setInf(temp.inf);
          } else {
            setInf([]);
          }
        }
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
  };

  const applyCall = () => {
    dispatch(deviceFun(deviceFilter));
    dispatch(groupFun(groupFilter));
    setPropValue({
      dId: deviceFilter,
      inf: inf,
    });
  };

  const groupUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  useEffect(() => {
    props.headerName("Real Time Graphs");
    groupUpdate();
  }, []);

  useEffect(() => {
    setDeviceFilter(device);
    setGroupFilter(group);
  }, [group, device]);

  useEffect(() => {
    if (groupFilter != null) {
      groupChange(groupFilter);
    }
  }, [groupFilter]);

  useEffect(() => {
    if (deviceFilter != null) {
      interfacesUpdate(deviceFilter);
    }
  }, [deviceFilter]);

  const displaySwitch = () => {
    switch (toggle) {
      case 0:
        return <Load {...props} deviceId={propValue.dId} />;
      case 1:
        return (
          <Traffic
            {...props}
            deviceId={propValue.dId}
            interfaces={propValue.inf}
          />
        );
      default:
        return <Connections {...props} deviceId={propValue.dId} />;
    }
  };

  return (
    <div className="real-time-graph">
      <div className="graph-content">
        <div className="filter-options">
          <div className="toggle-container">
            <span
              onClick={() => {
                setToggle(0);
              }}
              className={
                toggle === 0 ? "toggle-tab-active" : "toggle-tab-inActive"
              }
            >
              Load
            </span>
            <span
              onClick={() => {
                setToggle(1);
              }}
              className={
                toggle === 1 ? "toggle-tab-active" : "toggle-tab-inActive"
              }
            >
              Traffic
            </span>
            <span
              onClick={() => {
                setToggle(2);
              }}
              className={
                toggle === 2 ? "toggle-tab-active" : "toggle-tab-inActive"
              }
            >
              Connections
            </span>
          </div>
          <Select
            className="dropDown"
            list={groupList}
            value={groupFilter}
            name={"Group"}
            onChange={(e) => {
              if (e == null) {
                setDeviceFilter(null);
                setDevicesList([]);
                setGroupFilter(null);
                setInf([]);
              } else {
                setGroupFilter(e);
              }
            }}
          />
          <Select
            className="dropDown"
            list={devicesList}
            name={"Device"}
            value={deviceFilter}
            onChange={(e) => {
              let temp = devicesList.find((i) => i.value == e);
              setDeviceFilter(e);
              if (temp) {
                setInf(temp.inf);
              } else {
                setInf([]);
              }
            }}
          />
          <div>
            <Button
              variant="contained"
              className="apply-btn"
              onClick={() => {
                applyCall();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="graph-container">{displaySwitch()}</div>
      </div>
    </div>
  );
};

export default RealTimeGraphs;
