import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "@material-ui/core";
import { invokeApi } from "./api/index";
import {
  API_URL_DEVICE,
  CHUNKED_KERNEL_LOGS,
  TYPE_KERNEL_LOGS,
  REVOKE,
  INVOKE,
  DEVICE,
  GROUP_DROPDOWN,
} from "./api/constant";
import Select from "./common/Select";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";
var channelId = null;
const KernelLogs = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [kernelLogs, setKernelLogs] = useState(null);
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(0);
  const [resp, setResp] = useState(null);
  let deviceFilterLet = deviceFilter;
  let revokeId = null;

  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = [];
        devicedata = res.map((iter) => {
          return { value: iter.id, name: iter.deviceName };
        });
        // if (bool && devicedata.length > 0) {
        //   setDeviceFilter(devicedata[0].value);
        //   // setFilter(Math.random());
        // }
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
  };
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob(
      kernelLogs.map((ele) => `${ele}\n`),
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "kernelLogs.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const groupUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  useEffect(() => {
    if (resp != null && resp != "") {
      let data = resp.kernalLogs.split("\n");
      setKernelLogs(data);
    }
  }, [resp]);
  useEffect(() => {
    props.headerName("Kernel Logs");
    groupUpdate();
  }, []);

  const revoke = () => {
    if (revokeId != null) {
      setKernelLogs(null);
      invokeApi("POST", `${API_URL_DEVICE}${REVOKE}` + revokeId, {
        type: TYPE_KERNEL_LOGS,
        deviceId: deviceFilterLet,
        // inf: "en0",
        reset: false,
      });
    }
  };

  const bindWithChunking = (channel, event, callback) => {
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;
      if (
        ev.receivedFinal &&
        ev.chunks.length === Object.keys(ev.chunks).length
      ) {
        callback(JSON.parse(ev.chunks.join("")));
        delete events[data.id];
      }
    });
  };

  useEffect(() => {
    setDeviceFilter(device);
    setGroupFilter(group);
    if (device != null) {
      invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
        type: TYPE_KERNEL_LOGS,
        deviceId: device,
        reset: false,
      }).then((res) => {
        revokeId = res.id;
      });
      let channel = props.pusher.subscribe(device);
      channelId = device;
      bindWithChunking(channel, CHUNKED_KERNEL_LOGS, function (res) {
        if (res.clientId == channelId) {
          setResp(res);
        }
      });
      setTimeout(() => {
        setResp("");
      }, 5000);
    }

    return () => {
      revoke();
    };
  }, [group, device]);

  useEffect(() => {
    if (groupFilter != null) {
      groupChange(groupFilter);
    }
  }, [groupFilter]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            backgroundColor: "#effafb",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              fotntWeight: "bold",
              marginRight: "20px",
              margin: "10px",
              fontSize: ".8rem",
              paddingLeft: "30px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Filter Devices
          </div>
          <div>
            <Select
              list={groupList}
              value={groupFilter}
              name={"Group"}
              onChange={(e) => {
                if (e == null) {
                  setDeviceFilter(null);
                  setDevicesList([]);
                  setGroupFilter(null);
                } else {
                  setGroupFilter(e);
                }
              }}
            />
          </div>
          <div>
            <Select
              list={devicesList}
              name={"Device"}
              value={deviceFilter}
              onChange={(e) => {
                setDeviceFilter(e);
              }}
            />
          </div>
          <div
            style={{
              fotntWeight: "bold",
              marginRight: "20px",
              // width: "100px",
              marginTop: "-5px",
              margin: "10px",
            }}
          >
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#0fcbd0",
                color: "white",
                borderRadius: "10px",
                height: "25px",
              }}
              onClick={() => {
                revoke();
                dispatch(deviceFun(deviceFilter));
                dispatch(groupFun(groupFilter));
                // setFilter(Math.random());
              }}
            >
              Apply
            </Button>
          </div>
        </div>
        <div>
          {kernelLogs !== null && (
            <div
              style={{
                fotntWeight: "bold",
                width: "100px",
                margin: "10px 20px 10px 20px",
              }}
            >
              <Button
                variant="contained"
                onClick={downloadTxtFile}
                style={{
                  textTransform: "none",
                  backgroundColor: "#0fcbd0",
                  color: "white",
                  borderRadius: "10px",
                  height: "25px",
                  padding: "10px",
                }}
              >
                Download
              </Button>
            </div>
          )}
        </div>
      </div>

      <div style={{ padding: "20px" }}>
        <Paper
          sx={{
            width: "98%",
            overflow: "auto",
            minHeight: "60vh",
            maxHeight: "70vh",
            padding: "10px",
          }}
        >
          {kernelLogs == null
            ? "No Logs available"
            : kernelLogs.map((iter) => {
                return <div style={{ fontWeight: 300 }}>{iter}</div>;
              })}
        </Paper>
      </div>
    </div>
  );
};

export default KernelLogs;
