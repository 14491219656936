import React from "react";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const history = useNavigate();
  return (
    <div style={{ padding: "20px" }}>
      Go back to{" "}
      <span
        onClick={() => {
          history("/login");
        }}
        style={{ cursor: "pointer" }}
      >
        Login Page
      </span>
      <h3>Page Not Found</h3>
    </div>
  );
};

export default PageNotFound;
