import React from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  device: null,
  group: null,
};

export const counterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    deviceFun: (state, action) => {
      return { ...state, device: action.payload };
    },
    groupFun: (state, action) => {
      return { ...state, group: action.payload };
    },
    resetFun: () => {
      return initialState;
    },
  },
});

export const { deviceFun, groupFun, resetFun } = counterSlice.actions;

export default counterSlice.reducer;
