import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import CallIcon from "@mui/icons-material/Call";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import EmailIcon from "@mui/icons-material/Email";
import email from "../../resources/icons/email.png";
import phone from "../../resources/icons/phone.png";
import { invokeApi } from "../api/index";
import { API_URL_USER, REGISTER } from "../api/constant";
import rapid from "../../resources/icons/rapid-logo.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Terms from "./Terms";
var signUpDetailsCopy = null;
const SignUp = () => {
  const [modal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [termCondition, setTermCondition] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    name: "",
    email: "",
    orgName: "",
    contactNo: "",
    password: "",
  });
  signUpDetailsCopy = loginDetails;
  const history = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  const handleKeyEvent = (event) => {
    if (event.keyCode === 13) {
      onSubmit();
    }
  };

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
      [event.target.name + "Err"]: false,
    });
  };

  const isFormValid = () => {
    let errors = {};

    if (!signUpDetailsCopy.name) {
      errors["nameErr"] = true;
    }

    if (!signUpDetailsCopy.email) {
      errors["emailErr"] = true;
    }

    if (!signUpDetailsCopy.orgName) {
      errors["orgNameErr"] = true;
    }

    if (!signUpDetailsCopy.contactNo) {
      errors["contactNoErr"] = true;
    }

    if (!signUpDetailsCopy.password) {
      errors["passwordErr"] = true;
    }

    if (
      errors.emailErr ||
      errors.passwordErr ||
      loginDetails.orgNameErr ||
      loginDetails.contactNoErr ||
      loginDetails.passwordErr ||
      loginDetails.nameErr
    ) {
      setLoginDetails({ ...signUpDetailsCopy, ...errors });
      return false;
    }
    return true;
  };
  const onSubmit = () => {
    if (!termCondition) {
      toast.error(
        "Please go through and check Accept Terms & Conditions to continue",
        { autoClose: 2000 }
      );
    }
    if (isFormValid() && termCondition) {
      invokeApi("POST", `${API_URL_USER}${REGISTER}`, {
        name: signUpDetailsCopy.name,
        email: signUpDetailsCopy.email,
        password: signUpDetailsCopy.password,
        orgName: signUpDetailsCopy.orgName,
        contact: signUpDetailsCopy.contactNo,
      })
        .then((response) => {
          if (response.err) {
            toast.error(response.err, { autoClose: 2000 });
          }
          //   history("/login");
          // }
          // if (response.message) {
          //   toast.error(response.message, { autoClose: 2000 });
          // }
          // toast.success("Email sent for verification", { autoClose: 2000 });
        })
        .catch((err) => {
          if (JSON.stringify(err) == `{}`) {
            toast.success("Email sent for verification", { autoClose: 2000 });
            history("/login");
          } else {
            toast.error(err, { autoClose: 2000 });
          }
        });
    }
  };

  return (
    <div className={"login"}>
      <Grid container>
        <Grid item md={4} xs={12} className={"loginBanner"}>
          <div
            style={{
              position: "relative",
              top: "20%",
              color: "white",
              fontSize: "50px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              src={rapid}
              alt="Rapid"
              style={{ width: "150px", height: "50px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{ padding: "50px", paddingRight: "80px" }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Sign Up
          </div>
          <div>
            <TextField
              name="name"
              onChange={onInputChange}
              color="primary"
              placeholder="Full name"
              value={loginDetails.name}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.nameErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlinedIcon style={{ color: "#0fb9c8" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              name="email"
              onChange={onInputChange}
              color="primary"
              placeholder="Email"
              value={loginDetails.email}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.emailErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={email}
                      alt="email"
                      style={{
                        width: "1.1rem",
                        height: "1rem",
                        padding: "0px 2px 0px 0px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              name="orgName"
              onChange={onInputChange}
              color="primary"
              placeholder="Organization name"
              value={loginDetails.orgName}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.orgNameErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CorporateFareIcon style={{ color: "#0fb9c8" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              name="contactNo"
              onChange={onInputChange}
              color="primary"
              placeholder="Contact no"
              value={loginDetails.contactNo}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.contactNoErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={phone}
                      alt="phone"
                      style={{
                        width: "1.1rem",
                        height: "1rem",
                        padding: "0px 2px 0px 0px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              variant="standard"
              color="primary"
              name="password"
              size="small"
              placeholder="Password"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                border: loginDetails.passwordErr ? "1px solid red" : "",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
              }}
              error={loginDetails.passwordErr}
              onChange={onInputChange}
              value={loginDetails.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility style={{ color: "#0fb9c8" }} />
                      ) : (
                        <VisibilityOff style={{ color: "#0fb9c8" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              fontSize: "12px",
              width: "104%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              value={termCondition}
              onChange={() => {
                setTermCondition(!termCondition);
              }}
            />
            <label
              style={{
                position: "relative",
                color: "#085575",
                cursor: "pointer",
              }}
              onClick={() => {
                setModal(true);
              }}
            >
              Accept Terms & Conditions
            </label>
          </div>
          <div
            style={{
              textAlign: "center",
              margin: "20px 0px 5px 0px",
              width: "104%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              style={{ backgroundColor: "#085575" }}
            >
              SIGN UP
            </Button>
          </div>
          <div>
            {loginDetails.errorMsg && (
              <span className="error-msg">{loginDetails.errorMsg}</span>
            )}
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              backgroundColor: "#c8d5db",
              borderRadius: "10px",
              padding: "10px ",
              width: "100%",
              color: "#075a6a",
            }}
          >
            <span
            // style={{
            //   backgroundColor: "#afc2c4",
            //   borderRadius: "10px",
            //   padding: "5px",
            // }}
            >
              <span style={{ fontWeight: 200 }}>Already have an account?</span>{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history("/login");
                }}
              >
                Sign In
              </span>
            </span>
          </div>
        </Grid>
      </Grid>
      <Modal
        disableEnforceFocus={true}
        open={modal}
        onClose={(e, reason) => {
          setModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="terms-modal">
          <Terms />
        </Box>
      </Modal>
    </div>
  );
};

export default SignUp;
