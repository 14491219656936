import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Chart from "react-apexcharts";
import {
  REVOKE,
  INVOKE,
  API_URL_DEVICE,
  TYPE_TRAFFIC,
  PING,
} from "./api/constant";
import { invokeApi, invokeGraphApi } from "./api/index";
var channelId = null;
const Traffic = (props) => {
  let xAxis = Array(61)
    .fill()
    .map((_, i) => String(i * 5));
  let reverseXaxis = [...xAxis].reverse();
  const [toggle, setToggle] = useState(
    props.interfaces.length > 0 ? props.interfaces[0] : null
  );
  const [original, setOriginal] = useState({
    data1: [],
    data2: [],
  });

  const initial = [
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
  ];
  const [data, setData] = useState(initial);
  const [resp, setResp] = useState(null);
  let deviceFilterLet = props.deviceId;
  let revokeId = null;
  let toggleLet = toggle;

  useEffect(() => {
    setData(initial);
    setOriginal({
      data1: [],
      data2: [],
    });
    const interval = setInterval(() => {
      if (revokeId !== null) {
        invokeGraphApi("POST", `${API_URL_DEVICE}${PING}${revokeId}`)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);
    if (props.deviceId != null && toggle != null) {
      invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
        type: TYPE_TRAFFIC,
        deviceId: props.deviceId,
        inf: toggle,
        reset: false,
      }).then((res) => {
        revokeId = res.id;
      });
      let channel = props.pusher.subscribe(props.deviceId);
      channelId = props.deviceId;
      channel.bind(TYPE_TRAFFIC, function (res) {
        if (res.clientId == channelId) {
          setResp(res);
        }
      });
      return () => {
        clearInterval(interval);
        if (revokeId != null) {
          setOriginal({
            data1: [],
            data2: [],
          });
          setData(initial);
          invokeApi("POST", `${API_URL_DEVICE}${REVOKE}` + revokeId, {
            type: TYPE_TRAFFIC,
            deviceId: deviceFilterLet,
            inf: toggleLet,
            reset: false,
          });
        }
      };
    }
  }, [props.deviceId, toggle, props.interfaces]);

  useEffect(() => {
    setToggle(props.interfaces.length > 0 ? props.interfaces[0] : null);
  }, [props.deviceId]);

  useEffect(() => {
    if (resp != null) {
      if (toggle === resp.inf) {
        let temp = JSON.parse(JSON.stringify(data));
        let originalTemp = { ...original };
        let buffer = {
          b1: [],
          b2: [],
        };
        buffer.b1.push(parseFloat(resp.dataTransfer.net_in.toFixed(3)));
        buffer.b2.push(parseFloat(resp.dataTransfer.net_out.toFixed(3)));

        originalTemp.data1.push(
          parseFloat(resp.dataTransfer.net_in.toFixed(3))
        );
        originalTemp.data2.push(
          parseFloat(resp.dataTransfer.net_out.toFixed(3))
        );

        temp[0].data = temp[0].data
          .slice(1, temp[0].data.length)
          .concat(buffer.b1);
        temp[1].data = temp[1].data
          .slice(1, temp[1].data.length)
          .concat(buffer.b2);

        setOriginal(originalTemp);
        setData(JSON.parse(JSON.stringify(temp)));
      }
    }
  }, [resp]);

  let sum1 = original.data1.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg1 =
    original.data1.length > 0
      ? (sum1 / original.data1.length).toFixed(3) + " kbps"
      : "0.000";

  let sum2 = original.data2.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg2 =
    original.data2.length > 0
      ? (sum2 / original.data2.length).toFixed(3) + " kbps"
      : "0.000";

  let peak1 =
    original.data1.length > 0
      ? Math.max(...original.data1).toFixed(3) + " kbps"
      : "0.000";
  let peak2 =
    original.data2.length > 0
      ? Math.max(...original.data2).toFixed(3) + " kbps"
      : "0.000";

  let value1 =
    original.data1.length > 0
      ? original.data1[original.data1.length - 1] + " kbps"
      : "0.000";
  let value2 =
    original.data2.length > 0
      ? original.data2[original.data2.length - 1] + " kbps"
      : "0.000";
  return (
    <>
      <div
        style={{
          borderRadius: "10px",
        }}
      >
        {props.interfaces.length > 0 ? (
          <>
            <div
              style={{
                padding: "15px",
                width: "fit-content",
                backgroundColor: "#b4d0d687",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              {props.interfaces?.map((iter) => {
                return (
                  <span
                    onClick={() => {
                      setToggle(iter);
                    }}
                    style={{
                      backgroundColor: toggle == iter ? "#08cacfde" : "white",
                      padding: "5px 15px 5px 15px",
                      margin: "0px 5px 0px 5px",
                      borderRadius: "20px",
                      color: toggle == 0 ? "white" : "black",
                      cursor: "pointer",
                    }}
                  >
                    {iter}
                  </span>
                );
              })}
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                borderTopLeftRadius: "0px",
              }}
            >
              <div style={{ padding: "10px" }}>Real-Time Traffic</div>
              <div style={{ height: "40vh", padding: "10px" }}>
                <Chart
                  type="area"
                  height={"100%"}
                  options={{
                    chart: {
                      animations: {
                        enabled: false,
                      },
                      toolbar: {
                        show: false,
                      },
                      zoom: {
                        enabled: true,
                      },
                    },
                    stroke: {
                      show: true,
                      width: 1,
                    },
                    plotOptions: {
                      bar: {
                        distributed: true,
                        horizontal: false,
                        borderRadius: 10,
                      },
                    },
                    colors: ["#1976d2", "#087d94", "#0fcbd0"],
                    dataLabels: {
                      enabled: false,
                    },
                    grid: {
                      show: false,
                    },

                    xaxis: {
                      position: "top",
                      showDuplicates: true,
                      categories: reverseXaxis.map((i) => {
                        return i;
                      }),
                      labels: {
                        formatter: function (value) {
                          if (value != 0 && parseInt(value) % 60 == 0) {
                            return parseInt(value) / 60 + "m";
                          }
                          return "";
                        },
                      },
                    },
                    yaxis: {
                      labels: {
                        formatter: (value) => {
                          return parseFloat(value).toFixed(2) + " kbps";
                        },
                      },
                    },
                    legend: {
                      show: false,
                      position: "right",
                      offsetY: 40,
                    },
                  }}
                  series={[
                    {
                      name: "Inbound",
                      data: data[0].data,
                    },
                    {
                      name: "Outbound",
                      data: data[1].data,
                    },
                  ]}
                />
              </div>
              <div style={{ fontSize: ".8rem" }}>
                <Grid
                  container
                  spacing={2}
                  style={{
                    backgroundColor: "#d2e1e35c",
                    padding: "10px",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{ paddingTop: "0px", paddingLeft: "3%" }}
                  >
                    <div>
                      Inbound: <span>{value1}</span>
                    </div>
                    <div>
                      Outbound: <span>{value2}</span>
                    </div>
                  </Grid>
                  <Grid item xs={4} style={{ paddingTop: "0px" }}>
                    <div>
                      Inbound-Avg: <span>{avg1}</span>
                    </div>
                    <div>
                      Outbound-Avg: <span>{avg2}</span>
                    </div>
                  </Grid>
                  <Grid item xs={4} style={{ paddingTop: "0px" }}>
                    <div>
                      Inbound-Peak: <span>{peak1}</span>
                    </div>
                    <div>
                      Outbound-Peak: <span>{peak2}</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        ) : (
          <div style={{ padding: "30px" }}> No Interfaces avaiable</div>
        )}
      </div>
    </>
  );
};

export default Traffic;
