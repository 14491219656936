import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TerminalTwoToneIcon from "@mui/icons-material/TerminalTwoTone";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { FaExpandAlt } from "react-icons/fa";
import DropZone from "../components/common/DropZone";
import Modal from "@mui/material/Modal";
import { TextField, Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { invokeApi } from "./api/index";
import {
  API_URL_DEVICE,
  DEVICE,
  GROUPS,
  DEVICE_DROPDOWN,
  GROUP_DROPDOWN,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "../components/common/Select";
import EditIcon from "@mui/icons-material/Edit";
import { FaAlignLeft } from "react-icons/fa";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const columns1 = [
  { id: "name", label: "Name" },
  { id: "version", label: "Version" },
  { id: "del", label: "Delete" },
];

const columns2 = [
  { id: "filename", label: "File Name" },
  { id: "size", label: "Size(B)" },
  { id: "lastModification", label: "Last Modification" },
  { id: "detailsStatus", label: "Details Status" },
  { id: "actions", label: "Actions" },
];

function createData1(id, name, version, dele) {
  let del = (
    <DeleteOutlineIcon style={{ color: "#28d1d5", cursor: "pointer" }} />
  );
  return {
    id,
    name,
    version,
    del,
  };
}

const Distributions = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [devices, setDevices] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(0);
  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = res.map((iter) => {
          return { value: iter.id, name: iter.deviceName };
        });
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
    setGroupFilter(e);
  };

  const groupUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          // if (data.length > 0) {
          //   groupChange(data[0].value);
          // }
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  useEffect(() => {
    props.headerName("Distributions");
    // groupUpdate();
  }, []);

  //   useEffect(() => {
  //     let params = groupFilter ? DEVICE + groupFilter : DEVICE_DROPDOWN;
  //     invokeApi("GET", `${API_URL_DEVICE}` + params)
  //       .then((response) => {
  //         if (response) {
  //           let data = (groupFilter ? response : response.results).map(
  //             (iter, pos) => {
  //               return createData(
  //                 pos,
  //                 iter.controllerId,
  //                 iter.deviceName,
  //                 iter.status,
  //                 "remoteAccess"
  //               );
  //             }
  //           );
  //           setDevices(data);
  //         }
  //       })
  //       .catch((err) => {
  //         toast.error("Fetching devices failed");
  //       });
  //   }, [filter]);

  return (
    <div
      style={{
        height: "92%",
        overflow: "auto",
        padding: "5px",
      }}
    >
      <Grid container spacing={2} style={{ margin: "0px", width: "100%" }}>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            height: "43vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#0fcbd030",
                padding: "10px 15px 5px 15px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div>Distribution</div>
              <div style={{ marginLeft: "auto" }}>
                <SearchOutlinedIcon style={{ fontSize: "15px" }} />{" "}
                <AddOutlinedIcon style={{ fontSize: "15px" }} />{" "}
                <FaExpandAlt
                  style={{
                    fontSize: "10px",
                    position: "relative",
                    top: "-2px",
                  }}
                />
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "37vh" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow key="head">
                      {columns1.map((col) => (
                        <TableCell
                          key={col.id}
                          align={col.align}
                          style={{
                            minWidth: col.minWidth,
                            fontWeight: 900,
                            fontSize: "10px",
                          }}
                        >
                          {col.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ cursor: "pointer" }}
                        >
                          {columns1.map((col) => {
                            const value = row[col.id];
                            return (
                              <TableCell key={col.id} align={col.align}>
                                {col.format && typeof value === "number"
                                  ? col.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            height: "43vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#0fcbd030",
                padding: "10px 15px 5px 15px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div>Software Module</div>
              <div style={{ marginLeft: "auto" }}>
                <SearchOutlinedIcon style={{ fontSize: "15px" }} />{" "}
                <AddOutlinedIcon style={{ fontSize: "15px" }} />{" "}
                <FaExpandAlt
                  style={{
                    fontSize: "10px",
                    position: "relative",
                    top: "-2px",
                  }}
                />
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "37vh" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow key="head">
                      {columns1.map((col) => (
                        <TableCell
                          key={col.id}
                          align={col.align}
                          style={{
                            minWidth: col.minWidth,
                            fontWeight: 900,
                            fontSize: "10px",
                          }}
                        >
                          {col.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ cursor: "pointer" }}
                        >
                          {columns1.map((col) => {
                            const value = row[col.id];
                            return (
                              <TableCell key={col.id} align={col.align}>
                                {col.format && typeof value === "number"
                                  ? col.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            height: "43vh",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#0fcbd030",
                padding: "10px 15px 5px 15px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div>Artifact Details</div>
              <div style={{ marginLeft: "auto" }}>
                <FaExpandAlt
                  style={{
                    fontSize: "10px",
                    position: "relative",
                    top: "-2px",
                  }}
                />
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "37vh" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow key="head">
                      {columns2.map((col) => (
                        <TableCell
                          key={col.id}
                          align={col.align}
                          style={{
                            minWidth: col.minWidth,
                            fontWeight: 900,
                            fontSize: "10px",
                          }}
                        >
                          {col.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          style={{ cursor: "pointer" }}
                        >
                          {columns2.map((col) => {
                            const value = row[col.id];
                            return (
                              <TableCell key={col.id} align={col.align}>
                                {col.format && typeof value === "number"
                                  ? col.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "37vh" }}>
          <div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#0fcbd030",
                padding: "10px 15px 5px 15px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div>Distribution</div>
              <div style={{ marginLeft: "auto" }}>
                <EditIcon
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: "-2px",
                  }}
                />{" "}
                <FaAlignLeft
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: "-2px",
                  }}
                />
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "37vh" }}>
              <div
                style={{ display: "flex", flexWrap: "wrap", padding: "10px" }}
              >
                <KeyboardArrowLeftIcon />
                <div
                  style={{
                    backgroundColor: "#d2e1e3",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  <span style={{ padding: "5px 10px 5px 10px" }}>Details</span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>
                    Description
                  </span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>Modules</span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>Tags</span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>Logs</span>
                </div>
                <ChevronRightIcon />
              </div>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "37vh" }}>
          <div>
            <div
              style={{
                display: "flex",
                backgroundColor: "#0fcbd030",
                padding: "10px 15px 5px 15px",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div>Software Module</div>
              <div style={{ marginLeft: "auto" }}>
                <EditIcon
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: "-2px",
                  }}
                />{" "}
                <FaAlignLeft
                  style={{
                    fontSize: "12px",
                    position: "relative",
                    top: "-2px",
                  }}
                />
              </div>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden", height: "37vh" }}>
              <div
                style={{ display: "flex", flexWrap: "wrap", padding: "10px" }}
              >
                <KeyboardArrowLeftIcon />
                <div
                  style={{
                    backgroundColor: "#d2e1e3",
                    borderRadius: "10px",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  <span style={{ padding: "5px 10px 5px 10px" }}>Details</span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>
                    Description
                  </span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>Logs</span>
                  <span style={{ borderLeft: "1px solid gray" }}></span>
                  <span style={{ padding: "5px 10px 5px 10px" }}>Metadata</span>
                </div>
                <ChevronRightIcon />
              </div>
            </Paper>
          </div>
        </Grid>
        <Grid item xs={12} md={4} style={{ height: "37vh" }}>
          <DropZone />
        </Grid>
      </Grid>
    </div>
  );
};

export default Distributions;
