import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import UpgradePlanCard from "./common/UpgradePlanCard";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@material-ui/core";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Grid from "@mui/material/Grid";
import Select from "../components/common/Select";
import { invokeApi } from "./api/index";
import { toast } from "react-toastify";
import {
  API_URL_DEVICE,
  LIST_UPGRADE_PLAN,
  CREATE_SUBSCRIPTION,
  SHOW_USER_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  LIST_CARD,
} from "./api/constant";
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    fontSize: 20,
    color: "white",
  },
  titleContainer: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer_freePlan: {
    backgroundImage: "linear-gradient(to top right, #02955f , white 200%)",
  },
  titleContainer_standardPlan: {
    backgroundImage: "linear-gradient(to top right, #e21313 , white 200%)",
  },
  titleContainer_enterprisePlan: {
    backgroundImage: "linear-gradient(to top right, #138ad3 , white 200%)",
  },
  titleContainer_premiumPlan: {
    backgroundImage: "linear-gradient(to top right, #2e677b , white 200%)",
  },
  titleContainer_customPlan: {
    backgroundImage: "linear-gradient(to top right, #3cda9e , white 200%)",
  },

  amount: {
    color: "#02955f",
    fontWeight: "bold",
    fontSize: 30,
    margin: "auto",
    padding: 5,
  },
  label: {
    margin: "6px",
    fontSize: 18,
    color: "rgb(4, 73, 102)",
  },
  value: {
    margin: "6px",
    fontWeight: "lighter",
    fontSize: 18,
    color: "#0a919d",
  },
  amountColor_freePlan: {
    color: "#02955f",
  },
  amountColor_standardPlan: {
    color: "#e21313",
  },
  amountColor_enterprisePlan: {
    color: "#138ad3",
  },
  amountColor_premiumPlan: {
    color: "#2e677b",
  },
  amountColor_customPlan: {
    color: "#3cda9e",
  },
  amountContainer: {
    display: "flex",
    borderBottom: "1px solid #c4f7f9",
    width: "90%",
    justifyContent: "center",
  },
  list: {
    listStyle: "none",
    paddingLeft: "16px",
  },
}));
const UpgradePlan = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [planlist, setPlanList] = useState([]);
  const [listUserCards, setListUserCards] = useState([]);
  const [modal, setModal] = useState({});
  const [userSubObj, setUserSubObj] = useState({});
  const [activeCard, setActiveCard] = useState({
    name: "",
    value: "",
  });
  const [selectedCard, setSelectedCard] = useState(null);
  const [activePlanData, setActivePlanData] = useState({});
  const [showAllSubscription, setShowAllSubscription] = useState(true);
  const [subButtonText, setSubButtonText] = useState("Subscribe");
  props.headerName("Upgrade plan");

  useEffect(() => {
    props.headerName("Upgrade plan");
    activeUserSubscription();
    invokeApi("GET", `${API_URL_DEVICE}${LIST_CARD}`)
      .then((response) => {
        if (!response.message) {
          response.map((card) => {
            if (!card.isDefault) {
              setListUserCards((prevVal) => [
                ...prevVal,
                {
                  name: `${card.brand}-${card.last4} (Expires ${card.expMonth}/${card.expYear})`,
                  value: card.id,
                },
              ]);
            }
            if (card.isDefault) {
              setActiveCard({
                name: `${card.brand}-${card.last4} (Expires ${card.expMonth}/${card.expYear})`,
                value: card.id,
              });
            }
          });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    invokeApi("GET", `${API_URL_DEVICE}${LIST_UPGRADE_PLAN}`)
      .then((response) => {
        if (response) {
          response.map((ele) => {
            setPlanList((prevList) => {
              let obj = {};
              obj["planId"] = ele.id;
              obj["planName"] = ele.name;
              if (ele.price === 0) {
                obj["amount"] = "Free";
              } else if (ele.price === -1) {
                obj["amount"] = "Flexible";
              } else {
                obj["amount"] = `$${ele.price}`;
              }

              obj["index"] = ele.uiOrder;
              if (ele.limits.allowedMaxDeviceCount > 0) {
                obj[
                  "allowedMaxDeviceCount"
                ] = `${ele.limits.allowedMaxDeviceCount} devices supported`;
              } else {
                obj["allowedMaxDeviceCount"] = `Unlimited devices supported`;
              }
              if (ele.limits.allowedMaxStorage >= 0) {
                obj["allowedStorage"] = `${
                  ele.limits.allowedMaxStorage >= 1024
                    ? ele.limits.allowedMaxStorage / 1024 + " GB"
                    : ele.limits.allowedMaxStorage + " MB"
                }  storage in total`;
              } else {
                obj["allowedStorage"] = "Unlimited Storage";
              }
              if (ele.limits.allowedMaxUserAccounts >= 0) {
                obj["allowedUserAccount"] =
                  ele.limits.allowedMaxUserAccounts > 1
                    ? `${ele.limits.allowedMaxUserAccounts} users per Account`
                    : `${ele.limits.allowedMaxUserAccounts} user per Account`;
              } else {
                obj["allowedUserAccount"] = "Unlimited users";
              }

              if (ele.limits.allowedGroups >= 0) {
                obj["allowedGroupAccount"] =
                  ele.limits.allowedGroups > 1
                    ? `${ele.limits.allowedGroups} groups per Account`
                    : `${ele.limits.allowedGroups} group per Account`;
              } else {
                obj["allowedGroupAccount"] = "Unlimited groups";
              }

              return [...prevList, { ...obj }];
            });
          });
        }
      })
      .catch((err) => {
        console.log("Fetching Fleet details failed", err);
      });
    return () => {
      setPlanList([]);
    };
  }, []);

  useEffect(() => {
    if (userSubObj.subId === undefined) {
      setShowAllSubscription(true);
    } else {
      setShowAllSubscription(false);
    }
  }, [userSubObj]);

  useEffect(() => {
    if (userSubObj !== undefined) {
      planlist.map((plan) => {
        if (plan.planId === userSubObj.activePlanId) {
          setActivePlanData({
            planName: plan.planName,
            price: plan.amount,
            allowedDevices: plan.allowedMaxDeviceCount,
          });
        }
      });
    }
  }, [userSubObj, planlist]);

  const getTitleBackgroundColor = (index) => {
    if (index === 0) {
      return classes.titleContainer_freePlan;
    }
    if (index === 1) {
      return classes.titleContainer_standardPlan;
    }
    if (index === 2) {
      return classes.titleContainer_enterprisePlan;
    }
    if (index === 3) {
      return classes.titleContainer_premiumPlan;
    }
    if (index === 4) {
      return classes.titleContainer_customPlan;
    }
  };
  const getAmountColor = (index) => {
    if (index === 0) {
      return classes.amountColor_freePlan;
    }
    if (index === 1) {
      return classes.amountColor_standardPlan;
    }
    if (index === 2) {
      return classes.amountColor_enterprisePlan;
    }
    if (index === 3) {
      return classes.amountColor_premiumPlan;
    }
    if (index === 4) {
      return classes.amountColor_customPlan;
    }
  };
  const submitHandler = (Id) => {
    if (userSubObj.subId !== undefined) {
      cancelSubscriptionHandler();
    }
    if (userSubObj.subId === undefined) {
      createSubscriptionHandler(Id);
    }
  };
  const activeUserSubscription = () => {
    invokeApi("GET", `${API_URL_DEVICE}${SHOW_USER_SUBSCRIPTION}`)
      .then((response) => {
        if (!response.message) {
          setUserSubObj({
            subId: response.id,
            activePlanId: response.planId.id,
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const createSubscriptionHandler = (Id) => {
    invokeApi("POST", `${API_URL_DEVICE}${CREATE_SUBSCRIPTION}`, {
      planId: Id,
      cardId: selectedCard !== null ? selectedCard : activeCard.value,
    })
      .then((response) => {
        if (response.message) {
          toast.error(`${response.message}`, { autoClose: 2000 });
        } else {
          toast.success(`Subscribe Successfully`, { autoClose: 2000 });
          setShowAllSubscription(false);
        }
        activeUserSubscription();
        setSubButtonText("Subscribe");
        setModal({});
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const cancelSubscriptionHandler = () => {
    invokeApi(
      "PUT",
      `${API_URL_DEVICE}${CANCEL_SUBSCRIPTION}${userSubObj.subId}`
    )
      .then((response) => {
        if (response.message !== "Invalid Subscription") {
          toast.success(`${response.message}`, { autoClose: 2000 });
          activeUserSubscription();
          setShowAllSubscription(true);
          setActivePlanData({});
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const modalFun = () => {
    return (
      <Modal
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({});
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="userPopUp" style={{ p: 4, maxWidth: "600px" }}>
          <div
            style={{
              backgroundColor: "#075a6a",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              padding: "10px",
              color: "#c1d8dd",
            }}
          >
            <span>Confirm Subscription</span>
            <span
              onClick={() => {
                setModal({});
              }}
              style={{ float: "right", cursor: "pointer" }}
            >
              X
            </span>
          </div>
          <div style={{ padding: "0px 30px 20px 30px" }}>
            <h4 style={{ fontSize: 18 }}>Plan Details</h4>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <p className={classes.label}>Selected Plan:</p>
                <p className={classes.value}>{modal.subPlan}</p>
              </div>
              <div style={{ display: "flex" }}>
                <p className={classes.label}>Total Pay:</p>
                <p className={classes.value}>{modal.price}</p>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  margin: "20px 0px 10px 0px",
                  alignItems: "center",
                }}
              >
                <p className={classes.label}>Select Card:</p>
                <Select
                  list={listUserCards}
                  name={activeCard.name === "" ? "Add Card" : activeCard.name}
                  onChange={(e) => {
                    setSelectedCard(e);
                  }}
                  value={selectedCard}
                />
              </div>
            </div>
          </div>
          <div className="footer">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#0fcbd0",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              disabled={subButtonText === "Loading..." ? true : false}
              onClick={() => {
                setSubButtonText("Loading...");
                createSubscriptionHandler(modal.planId);
              }}
            >
              {subButtonText}
            </Button>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#075a6a",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              onClick={() => {
                setModal({});
              }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <div
        style={{
          height: "85vh",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "30px",
          }}
        >
          <p style={{ paddingLeft: 20, paddingBottom: 20 }}>
            Please choose a subscription plan
          </p>
        </div>
        <Grid container spacing={2}>
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingTop: "2%",
              maxHeight: "max-content",
              marginLeft: "12px",
            }}
          >
            {planlist.map((ele, index) => (
              <Grid
                item
                xs={12}
                md={3}
                sm={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <UpgradePlanCard id={ele.planId}>
                  <div
                    className={
                      classes.titleContainer +
                      " " +
                      getTitleBackgroundColor(index)
                    }
                  >
                    <p className={classes.title}>{ele.planName}</p>
                  </div>
                  <div className={classes.amountContainer}>
                    <p className={classes.amount + " " + getAmountColor(index)}>
                      {ele.amount}
                    </p>
                  </div>
                  <div
                    style={{
                      paddingRight: 10,
                    }}
                  >
                    <ul className={classes.list}>
                      <span style={{ display: "flex", marginBottom: "10px" }}>
                        <ArrowRightIcon
                          style={{
                            margin: "-5px 0px 0px 0px",
                            paddingRight: 10,
                            color: "#02955f",
                          }}
                        />
                        <li style={{ fontSize: "small" }}>Monitor</li>
                      </span>
                      <span style={{ display: "flex", marginBottom: "10px" }}>
                        <ArrowRightIcon
                          style={{
                            margin: "-5px 0px 0px 0px",
                            paddingRight: 10,
                            color: "#02955f",
                          }}
                        />
                        <li style={{ fontSize: "small" }}>OTA</li>
                      </span>
                      <span style={{ display: "flex", marginBottom: "10px" }}>
                        <ArrowRightIcon
                          style={{
                            margin: "-5px 0px 0px 0px",
                            paddingRight: 10,
                            color: "#02955f",
                          }}
                        />
                        <li style={{ fontSize: "small" }}>
                          {ele.allowedMaxDeviceCount}
                        </li>
                      </span>
                      <span style={{ display: "flex", marginBottom: "10px" }}>
                        <ArrowRightIcon
                          style={{
                            margin: "-5px 0px 0px 0px",
                            paddingRight: 10,
                            color: "#02955f",
                          }}
                        />
                        <li style={{ fontSize: "small" }}>
                          {ele.allowedStorage}
                        </li>
                      </span>
                      <span style={{ display: "flex", marginBottom: "10px" }}>
                        <ArrowRightIcon
                          style={{
                            margin: "-5px 0px 0px 0px",
                            paddingRight: 10,
                            color: "#02955f",
                          }}
                        />
                        <li style={{ fontSize: "small" }}>
                          {ele.allowedUserAccount}
                        </li>
                      </span>
                      <span style={{ display: "flex", marginBottom: "10px" }}>
                        <ArrowRightIcon
                          style={{
                            margin: "-5px 0px 0px 0px",
                            paddingRight: 10,
                            color: "#02955f",
                          }}
                        />
                        <li style={{ fontSize: "small" }}>
                          {ele.allowedGroupAccount}
                        </li>
                      </span>
                    </ul>
                  </div>

                  {ele.index === 0 ? (
                    ele.planId === userSubObj.activePlanId ? (
                      <Button
                        variant="outlined"
                        style={{
                          margin: 5,
                          marginBottom: 15,
                          borderRadius: 20,
                          color: "#138ad3",
                          borderColor: "#138ad3",
                        }}
                        onClick={() => {
                          setUserSubObj({});
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{
                          color: "#02955f",
                          margin: 5,
                          marginBottom: 15,
                          borderRadius: 20,
                          borderColor: "#02955f",
                        }}
                        onClick={() => {
                          setUserSubObj({
                            subId: 0,
                            activePlanId: ele.planId,
                          });
                        }}
                      >
                        Try Now
                      </Button>
                    )
                  ) : !showAllSubscription ? (
                    ele.planId === userSubObj.activePlanId ? (
                      <Button
                        variant="outlined"
                        style={{
                          margin: 5,
                          marginBottom: 15,
                          borderRadius: 20,
                          color: "#138ad3",
                          borderColor: "#138ad3",
                        }}
                        onClick={() => {
                          submitHandler(ele.planId);
                        }}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{
                          // color: "#02955f",
                          margin: 5,
                          marginBottom: 15,
                          borderRadius: 20,
                          // borderColor: "#02955f",
                        }}
                        disabled
                        onClick={() => {}}
                      >
                        Buy Now
                      </Button>
                    )
                  ) : (
                    <Button
                      variant="outlined"
                      style={{
                        color: ele.index === 1 ? "#e21313" : "#138ad3",
                        margin: 5,
                        marginBottom: 15,
                        borderRadius: 20,
                        borderColor: ele.index === 1 ? "#e21313" : "#138ad3",
                      }}
                      onClick={() => {
                        setModal({
                          open: true,
                          subPlan: ele.planName,
                          price: ele.amount,
                          planId: ele.planId,
                        });
                        // createSubscriptionHandler(ele.planId);
                        // submitHandler(ele.planId);
                      }}
                    >
                      Buy Now
                    </Button>
                  )}
                </UpgradePlanCard>
              </Grid>
            ))}
          </div>
        </Grid>
      </div>
      {modalFun()}
    </div>
  );
};

export default UpgradePlan;
