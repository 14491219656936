import "./index.scss";
const Terms = () => {
  return (
    <div>
      <div>
        THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
        (HEREINAFTER “YOU” or “YOUR”) AND THE APPLICABLE RAPID UP ENTITY LISTED
        HERE (HEREINAFTER “RAPID”) GOVERNING YOUR USE OF RAPID UP ONLINE
        SOFTWARE.
      </div>
      <br />
      <br />

      <b>Acceptance of the Agreement</b>
      <br />
      <p className="p">
        You must be of legal age to enter into a binding agreement to accept the
        Agreement. Please read the following terms of service agreement
        carefully. By accessing or using our sites and our services, you hereby
        agree to be bound by the terms and all terms incorporated herein by
        reference. It is the responsibility of you, the user, customer, or
        prospective customer to read the terms and conditions before proceeding
        to use this site. If you do not expressly agree to all the terms and
        conditions, then please do not access, or use our sites or our services
      </p>
      <br />
      <br />
      <b>Description of Service</b>
      <br />
      <p className="p">
        We provide cloud software for businesses for Device Management & Remote
        updates. You may use the Services for your personal and business use or
        for internal business purpose in the organization that you represent.
        You may connect to the Services using any Internet browser supported by
        the Services. You are responsible for obtaining access to the Internet
        and the equipment necessary to use the Services. Free Plan If you
        register for a free plan, RAPID will make the applicable Services
        available to you on a trial basis free of charge until (i) the end of
        the applicable Services terminated by you (ii) increase in number of
        Devices (limited to 5 devices). (iii) increase in number of users
        (limited to 3 users per organization) (iv) termination by Rapid in its
        sole discretion. Any data that you enter the Services, and any
        customizations made to the Services during the free plan will be
        permanently lost unless you (i) purchase the corresponding paid
        subscription plan for the account, (ii) purchase applicable Service
        upgrades, or (iii) export such data before the end of the trial period.
        Notwithstanding anything contained in this Section, Services are offered
        as-is during the free trial, without any warranty, covenant, support, or
        liability whatsoever, to the extent permitted by law.
      </p>
      <br />
      <br />
      <b>User Sign up Obligations</b>
      <br />
      <p className="p">
        You need to sign up for a user account by providing all required
        information in order to access or use the Services. If you represent an
        organization and wish to use the Services for corporate internal use, we
        recommend that you, and all other users from your organization, sign up
        for user accounts by providing your corporate contact information. In
        particular, we recommend that you use your corporate email address. You
        agree to: (i) provide true, accurate, current and complete information
        about yourself as prompted by the sign up process; and (ii) maintain and
        promptly update the information provided during sign up to keep it true,
        accurate, current, and complete. If you provide any information that is
        untrue, inaccurate, outdated, or incomplete, or if Rapid has reasonable
        grounds to suspect that such information is untrue, inaccurate,
        outdated, or incomplete, Rapid may terminate your user account and
        refuse current or future use of any or all the Services.
      </p>
      <br />
      <br />

      <b>Restrictions on Use</b>
      <br />
      <p className="p">
        In addition to all other terms and conditions of this Agreement, you
        shall not: (i) transfer the Services or otherwise make it available to
        any third party; (ii) provide any service based on the Services without
        prior written permission; (iii) allow user licenses to be shared or used
        by more than one individual other than by way of reassigning the user
        license to a new user; (iv) except as permitted under applicable law,
        attempt to disassemble, reverse engineer or decompile the Services; (v)
        use third party links to sites without agreeing to their website terms &
        conditions; (vi) post links to third party sites or use their logo,
        company name, etc. without their prior written permission; (vii) attempt
        to gain unauthorized access to the Services or its related systems or
        network; (viii) use the Services in any manner that could damage,
        disable, overburden, impair or harm any server, network, computer
        system, resource of Rapid; (ix) use the Services to send or store
        material containing software viruses, worms or other harmful computer
        codes, files, scripts or programs; (x) use the Services in any manner
        that interferes with or disrupts the integrity, security or performance
        of the Services, its components and the data contained therein; (xi)
        create a false identity to mislead any person as to the identity or
        origin of any communication; (xii) host, display, upload, modify,
        publish, transmit, store, update or share any information that belongs
        to another person or entity and to which you do not have any right,
        including personal or confidential information of any person or entity
        with respect to which you do not have consent or permission from such
        person or entity; (xiii) use the Services for transmitting information
        that is false and untrue, and is written or published in any form, with
        the intent to mislead or harass a person, entity or agency for financial
        gain or to cause any injury to any person; (xiv) violate any applicable
        local, state, national or international law; (xv) use the Services for
        any form of competitive or benchmarking purposes; and (xvi) remove or
        obscure any proprietary or other notices contained in the Services.
      </p>
      <br />
      <br />
      <b>Illegal and Spamming Activities</b>
      <br />
      <p className="p">
        You agree to be solely responsible for the contents of your
        transmissions through the Services. You agree not to use the Services
        for illegal purposes or for the transmission of material that is
        unlawful, defamatory, harassing, libelous, invasive of another's
        privacy, abusive, threatening, harmful, vulgar, pornographic, obscene,
        or is otherwise objectionable, offends religious sentiments, promotes
        racism, contains viruses or malicious code, or that which infringes or
        may infringe intellectual property or other rights of another. We
        reserve the right to terminate your access to the Services if there are
        reasonable grounds to believe that you have used the Services for any
        illegal or unauthorized activity.
      </p>
      <br />
      <br />
      <b>Third Party Applications for Data management</b>
      <br />
      <br />
      <p className="p">
        Rapid Services integrate with third-party applications for storing &
        managing the data (hereinafter "Third Party Application(s)"). Access and
        use of the Third-Party Applications may require acceptance of terms of
        service and privacy policies applicable to such Third-Party Applications
        (hereinafter "Third Party Terms"). You are responsible for reading and
        understanding the Third-Party Terms before accessing or using any
        Third-Party Application. You acknowledge and agree that Rapid is not
        liable for any Third-Party Applications. While we will try to provide
        you with advance notice, whenever reasonably possible, you acknowledge
        and agree that Rapid may, at any time and in our sole discretion, and
        without any notice to you, suspend, restrict or disable access to or
        remove from Rapid Services, any Third-Party Application, without any
        liability to you, including without limitation for any loss of profits,
        revenue, data, goodwill or other intangible losses.
      </p>
      <br />
      <br />
      <b>Subscriptions and Payments</b>
      <br />
      <p className="p">
        The Services are available under subscription plans of various
        durations. Payments for subscription plans can be made by Credit Card,
        Debit Card, Bank Transfer, Internet Banking etc. . Your subscription
        will be automatically renewed at the end of each subscription period
        unless you downgrade your paid subscription plan to a free plan or
        inform us that you do not wish to renew the subscription. At the time of
        automatic renewal, the subscription fee will be charged to the Credit
        Card last used by you or will be notified via mail otherwise. We provide
        you the option of changing the details if you would like the payment for
        the renewal to be made through a different Credit Card or via different
        mode. If you do not wish to renew the subscription, you must inform us
        at least fifteen days prior to the renewal date. If you have not
        downgraded to a free plan and if you have not informed us that you do
        not wish to renew the subscription, you will be presumed to have
        authorized Rapid to charge the subscription fee to the Credit Card last
        used by you. From time to time, we may change the price of any Service
        or charge for use of Services. Any increase in charges will not apply
        until the expiry of your then current billing cycle. You will not be
        charged for using any Service unless you have opted for a paid
        subscription plan. In the event any tax such as GST, VAT, sales tax or
        the like is chargeable by Rapid in accordance with any local, state,
        provincial or foreign laws with respect to your subscription to our
        Services ("Taxes"),Rapid will invoice you for such Taxes. You agree to
        pay Rapid such Taxes in addition to the subscription fees. Rapid shall
        provide you with an invoice in the format prescribed by the applicable
        local, state, provincial or foreign laws to help you avail the
        applicable input tax credit for the Taxes so paid.
      </p>
      <br />
      <br />

      <b>Indemnity</b>
      <br />
      <p className="p">
        All users herein agree to insure and hold Rapid, our subsidiaries,
        affiliates, agents, employees, officers, partners and/or licensors
        blameless or not liable for any claim or demand, which may include, but
        is not limited to, reasonable attorney fees made by any third party
        which may arise from any content a user of our site may submit, post,
        modify, transmit or otherwise make available through our Services, the
        use of Rapid Services or your connection with these Services, your
        violations of the Terms of Service and/or your violation of any such
        rights of another person.
      </p>
      <br />
      <br />

      <b>Third party beneficiaries</b>
      <br />
      <p className="p">
        You herein acknowledge, understand, and agree, unless otherwise
        expressly provided in this TOS, that there shall be no third-party
        beneficiaries to this agreement.
      </p>
      <br />
      <br />
      <b>Trademark information</b>
      <br />
      <p className="p">
        You herein acknowledge, understand, and agree that all the Rapid
        trademarks, copyright, trade name, service marks, logos and any brand
        features, and/or product and service names are trademarks and as such,
        are and shall remain the property of Trunexa Inc. You herein agree not
        to display and/or use in any manner the Rapid logo or marks without
        obtaining Trunexa Inc's prior written consent.
      </p>
      <br />
      <br />
      <b>Proprietary rights</b>
      <br />
      <p className="p">
        You do hereby acknowledge and agree that Rapid's Services and any
        essential software that may be used in connection with our Services
        ("Software") shall contain proprietary and confidential material that is
        protected by applicable intellectual property rights and other laws.
        Furthermore, you herein acknowledge and agree that any Content which may
        be contained in any advertisements or information presented by and
        through our Services or by advertisers is protected by copyrights,
        trademarks, patents or other proprietary rights and laws.
      </p>
      <br />
      <br />
      <b>Data Ownership</b>
      <br />
      <p className="p">
        We respect your right to ownership of content created or stored by you.
        You own the content created or stored by you. Unless specifically
        permitted by you, your use of the Services does not grant Rapid the
        license to use, reproduce, adapt, modify, publish, or distribute the
        content created by you or stored in your user account for Rapid’s
        commercial, marketing or any similar purpose. But you grant Rapid
        permission to access, copy, distribute, store, transmit, reformat,
        publicly display, and publicly perform the content of your user account
        solely as required for the purpose of providing the Services to you.
      </p>
      <br />
      <br />
      <b>Hosting Location</b>
      <br />
      <p className="p">
        The location of the cloud facility from which you are served depends on
        the mapping of your region/country to the available cloud facilities at
        the time of your sign-up. We may migrate your account or require you to
        migrate your account to a different cloud facility in the event of any
        updates to the region/country to cloud facility mapping at any point of
        time. You must not mask your internet protocol (IP) address at the time
        of sign-up since your region/country is determined base on your IP
        address. If, at any time, your actual region/country is found to be
        different from the region/country in our records, Rapid may take
        appropriate action such as migrate your account or require you to
        migrate your account to the cloud facility corresponding to your
        region/country, or close your account and deny the Service to you. If
        you are served from a cloud facility outside your region/country and a
        Rapid group entity has an office in your region/country, apart from
        storing the data in the cloud facility assigned to you, we may store a
        local copy of the data in your region/country.
      </p>
      <br />
      <br />
      <b>Governing Law and Jurisdiction</b>
      <br />
      <p className="p">
        The governing law and jurisdiction that will apply in case of any
        dispute or lawsuit arising out of or in connection with this Agreement,
        will depend on your billing address if you are a paid customer and your
        state or country of domicile in all other cases. Accordingly, each party
        agrees to the governing law (without regard to choice or conflicts of
        law rules) and to the exclusive jurisdiction of the courts mentioned
        herein in case of any dispute or lawsuit arising out of or in connection
        with this Agreement .
      </p>
      <br />
      <br />
      <b>Modification of Terms of Service</b>
      <br />
      <p className="p">
        We may modify this Agreement upon notice to you at any time through a
        service announcement or by sending email to your primary email address.
        If we make significant changes to the Agreement that affect your rights,
        you will be provided with at least 30 days advance notice of the changes
        by email to your primary email address.
      </p>
      <br />
      <br />
      <b>End of terms of service</b>
      <br />
      <p className="p">
        If you have any questions or concerns regarding this Agreement, please
        contact us at info@rapidup.io
      </p>
    </div>
  );
};
export default Terms;
