import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { invokeApi } from "../api/index";
import { API_URL_USER, LOGIN } from "../api/constant";
import rapid from "../../resources/icons/rapid-logo.png";
import email from "../../resources/icons/email.png";
var loginDetailsCopy = null;
const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    userName: "",
    password: "",
  });
  loginDetailsCopy = loginDetails;

  const history = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  const handleKeyEvent = (event) => {
    if (event.keyCode === 13) {
      onSubmit();
    }
  };

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
      [event.target.name + "Err"]: false,
    });
  };

  const isFormValid = () => {
    let errors = {};

    if (!loginDetailsCopy.userName) {
      errors["emailErr"] = true;
    }

    if (!loginDetailsCopy.password) {
      errors["passwordErr"] = true;
    }

    if (errors.emailErr || errors.passwordErr) {
      setLoginDetails({ ...loginDetailsCopy, ...errors });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      invokeApi("POST", `${API_URL_USER}${LOGIN}`, {
        email: loginDetailsCopy.userName.trim(),
        password: loginDetailsCopy.password,
      })
        .then((response) => {
          if (response.user) {
            sessionStorage.setItem("user", JSON.stringify(response));
            history("/dashboard");
          } else {
            toast.error(`${response.message}`, { autoClose: 2000 });
          }
        })
        .catch((err) => {
          toast.error("Login In Failed", { autoClose: 2000 });
        });
    }
  };

  return (
    <div className={"login"}>
      <Grid container>
        <Grid item md={4} xs={12} className={"loginBanner"}>
          <div
            style={{
              position: "relative",
              top: "20%",
              color: "white",
              fontSize: "50px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              src={rapid}
              alt="Rapid"
              style={{ width: "150px", height: "50px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{ padding: "50px", paddingRight: "80px" }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Sign In
          </div>
          <div>
            <TextField
              name="userName"
              onChange={onInputChange}
              color="primary"
              placeholder="Email"
              value={loginDetails.userName}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.emailErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={email}
                      alt="email"
                      style={{
                        width: "1.1rem",
                        height: "1rem",
                        padding: "0px 2px 0px 0px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div>
            <TextField
              variant="standard"
              color="primary"
              name="password"
              size="small"
              placeholder="Password"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                border: loginDetails.passwordErr ? "1px solid red" : "",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
              }}
              error={loginDetails.passwordErr}
              onChange={onInputChange}
              value={loginDetails.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      style={{ padding: "10px" }}
                    >
                      {showPassword ? (
                        <LockOpenOutlinedIcon style={{ color: "#0fb9c8" }} />
                      ) : (
                        <LockOutlinedIcon style={{ color: "#0fb9c8" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              margin: "20px 0px 5px 0px",
              width: "104%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={onSubmit}
              style={{ backgroundColor: "#085575" }}
            >
              SIGN IN
            </Button>
          </div>
          <div
            style={{
              fontSize: "10px",
              width: "104%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" />
              <label
                style={{
                  position: "relative",
                  fontWeight: "normal",
                }}
              >
                Remember me
              </label>
            </div>
            <span
              style={{
                float: "right",
                cursor: "pointer",
                fontWeight: "normal",
              }}
              onClick={() => history("/forgotpassword")}
            >
              Forgot password?
            </span>
          </div>
          <div>
            {loginDetails.errorMsg && (
              <span className="error-msg">{loginDetails.errorMsg}</span>
            )}
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              backgroundColor: "#c8d5db",
              borderRadius: "10px",
              padding: "10px ",
              width: "100%",
              color: "#075a6a",
            }}
          >
            <span>
              <span style={{ fontWeight: 200 }}> Don't have an account? </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history("/login?signUp=true");
                }}
              >
                Sign Up
              </span>
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginForm;
