import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "../index.scss";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { styled } from "@material-ui/styles";
import SideMenu from "../components/side-nav";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import Setting from "../components/Setting";
import Devices from "../components/Devices";
import Dashboard from "../components/Dashboard";
import Process from "../components/Process";
import KernelLogs from "../components/KernelLogs";
import RealTimeGraphs from "../components/RealTimeGraphs";
import SystemStatus from "../components/SystemStatus";
import RemoteAccess from "../components/RemotrAccess";
import Distributions from "../components/Distributions";
import RollOut from "../components/RollOut";
import Alert from "../components/Alert";
import { useNavigate } from "react-router-dom";

import { invokeApi, plainApi } from "../components/api/index";
import rapid from "../resources/icons/rapid-logo.png";
import {
  API_URL_DEVICE,
  NOTIFICATIONS,
  NOTIFICATIONS_SEEN,
  NOTIFICATIONS_UNSEEN,
} from "../components/api/constant";
import moment from "moment";
import TimeAgo from "react-timeago";
import CommandExecution from "../components/CommandExecution";
import logout from "../resources/icons/Logout.png";
import account from "../resources/icons/My-Account.png";
import profile from "../resources/icons/Profile.png";
import { makeStyles } from "@material-ui/core/styles";
import UpgradePlan from "../components/UpgradePlan";
import AddCard from "../components/AddCard";
import { useSelector, useDispatch } from "react-redux";
import { resetFun } from "../components/redux/reducer";
import RollOutDetails from "../components/RollOutDetails";
import { toast } from "react-toastify";
import PageNotFound from "../components/login/PageNotFound";
import Header from "../components/common/Header";
import TimeLogs from "../components/Logs/TimeLogs";
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: "20px !important",
    left: "90% !important",
    top: "6% !important",
  },
  notification: {
    left: "75% !important",
    width: "22% !important",
  },
  menuList: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: "2px",
    right: "2px",
    fontSize: "0.5rem",
    minWidth: "12px",
    padding: "2px",
    height: "12px",
  },
}));

export const LandingPage = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [headerName, setHeaderName] = useState(null);
  const [notificationBadgeCount, setNotificationBadgeCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [anchorNoti, setAnchorNoti] = useState(null);
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(null);
  const observer = React.useRef();
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = React.useState(1);
  const [pageArray, setPageArray] = React.useState([]);
  const [unseen, setUnseen] = React.useState([]);
  const openNotification = Boolean(anchorNoti);
  const open = Boolean(anchorEl);
  const user = JSON.parse(sessionStorage.getItem("user"))?.user;

  const getNotification = (pg) => {
    if (!pageArray.includes(pg)) {
      plainApi("GET", `${API_URL_DEVICE}${NOTIFICATIONS}`, null, { page: pg })
        .then((response) => {
          if (response) {
            setTotalPages(response.totalPages + 1);
            let array = [...pageArray];
            let unseenTemp = [...unseen];
            array.push(pg);
            setPageArray(array);
            response.results.forEach((iter) => {
              if (iter.seen === false) {
                unseenTemp.push(iter.id);
              }
            });
            setUnseen(unseenTemp);
            if (pages == 1) {
              setNotifications(response.results);
            } else {
              setNotifications([...notifications, ...response.results]);
            }
          } else {
            console.log("Fetching notification failed");
          }
        })
        .catch((err) => {
          console.log("Fetching notification failed");
        });
    }
  };

  const lastItemRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => pages + 1);
          setRefresh(Math.random());
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  useEffect(() => {
    if (openNotification) {
      if (totalPages == null) {
        getNotification(pages);
      } else {
        if (pages < totalPages) {
          setHasMore(true);
          getNotification(pages);
        } else {
          setHasMore(false);
        }
      }
    } else {
      if (unseen.length > 0) {
        notificationSeenCall();
      }
    }
  }, [refresh, openNotification]);

  const headerNameFun = (name) => {
    setHeaderName(name);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const notificationSeenCall = () => {
    let markSeen = unseen.length;
    if (markSeen > 0 && notificationBadgeCount != 0) {
      plainApi("POST", `${API_URL_DEVICE}${NOTIFICATIONS_SEEN}`, {
        ids: unseen,
      })
        .then((response) => {
          setNotificationBadgeCount(notificationBadgeCount - markSeen);
          setUnseen([]);
        })
        .catch((err) => {
          console.log("Notification Mark Seen failed", err);
        });
    }
  };

  const handleClickNoti = (event) => {
    setAnchorNoti(event.currentTarget);
  };

  const handleCloseNoti = () => {
    setAnchorNoti(null);
  };

  const activeMenuFun = (val) => {
    switch (val) {
      case "Devices & Groups":
        return 2;
      case "Dashboard":
        return 1;
      case "Monitor":
        return 3;
      case "Processes":
        return 33;
      case "System Status":
        return 31;
      case "Kernel Logs":
        return 32;
      case "Real Time Graphs":
        return 34;
      // case "Alert":
      //   return 35;
      // case "Command Execution":
      //   return 42;
      case "Device Control":
        return 4;
      // case "Remote Access":
      //   return 41;
      case "Updates":
        return 5;
      case "OTA Updates":
        return 5;
      // return 51;
      case "Rollout Details":
        return 5;
      // return 51;
      case "Time Log":
        return 5;
      // return 51;
      case "Setting":
      case "Upgrade plan":
        return 6;
      case "Card":
        return 6;
      // case "Distributions":
      //   return 52;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("user"))?.tokens?.access?.token) {
    } else {
      // toast.error("Token expired, please login again", { autoClose: 2000 });
      history("/login");
    }
  }, [window.location.href]);

  useEffect(() => {
    invokeApi("GET", `${API_URL_DEVICE}${NOTIFICATIONS_UNSEEN}`)
      .then((response) => {
        if (response) {
          setNotificationBadgeCount(response.counts);
        }
      })
      .catch((err) => {
        console.log("Fetching notification failed");
      });
  }, []);

  const notificationTypeFun = (val) => {
    switch (val) {
      default:
        return "#0c53f36b";
    }
  };
  return (
    <div>
      <div style={{ width: "100%", minHeight: "100vh", display: "flex" }}>
        <div
          style={{
            backgroundColor: "#044966",
            width: "15%",
            height: "100vh",
          }}
        >
          <div
            className="responsive"
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={rapid}
              alt="Rapid"
              style={{ width: "150px", height: "50px" }}
            />
          </div>
          <SideMenu
            headerName={headerNameFun}
            activeMenu={activeMenuFun(headerName)}
          />
        </div>
        <div
          style={{
            backgroundColor: "rgb(4, 73, 102)",
            width: "85%",
            height: "100vh",
          }}
        >
          <Header
            headerName={headerName}
            notificationBadgeCount={notificationBadgeCount}
            handleClickNoti={handleClickNoti}
            anchorNoti={anchorNoti}
            openNotification={openNotification}
            handleCloseNoti={handleCloseNoti}
            notifications={notifications}
            lastItemRef={lastItemRef}
            hasMore={hasMore}
            notificationTypeFun={notificationTypeFun}
            user={user}
            handleClick={handleClick}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            account={account}
            logout={logout}
          />
          <div
            style={{
              backgroundColor: "#d2e1e3",
              height: "93vh",
              borderBottomLeftRadius: "30px",
            }}
          >
            <Routes>
              <Route
                exact
                path="/"
                element={<Dashboard headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/processes"
                element={<Process headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/system-status"
                element={<SystemStatus headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/kernel-logs"
                element={<KernelLogs headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/real-time-graphs"
                element={
                  <RealTimeGraphs headerName={headerNameFun} {...props} />
                }
              />
              <Route
                path="/alert"
                element={<Alert headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/command-execution"
                element={
                  <CommandExecution headerName={headerNameFun} {...props} />
                }
              />
              <Route
                path="/devices"
                element={<Devices headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/setting"
                element={<Setting headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/distributions"
                element={
                  <Distributions headerName={headerNameFun} {...props} />
                }
              />
              <Route
                path="/rollout"
                element={<RollOut headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/rempte-access"
                element={<RemoteAccess headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/add-card"
                element={<AddCard headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/upgrade-plan"
                element={<UpgradePlan headerName={headerNameFun} {...props} />}
              />
              <Route
                path="/rollout-details/:rollId"
                element={
                  <RollOutDetails headerName={headerNameFun} {...props} />
                }
              />
              <Route
                path="/rollout/logs/:id/:rollId"
                element={<TimeLogs headerName={headerNameFun} {...props} />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            <div
              className="tr-hide-mobile"
              style={{
                width: "85%",
                borderRadius: "0px 0px 0px 30px",
                height: "40px",
                position: "fixed",
                overflow: "hidden",
                backgroundColor: "white",
                bottom: "0%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  height: "20px",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "13px", color: "#245770" }}>
                  @Rapid.2022.All Rights.Reserved
                </p>
                <a
                  href="https://trunexa.com"
                  style={{
                    fontSize: "13px",
                    color: "#245770",
                    textDecoration: "none",
                  }}
                >
                  A division of Trunexa
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: "20px",
                  alignItems: "center",
                  backgroundColor: "#ebf7fa",
                }}
              >
                <p
                  style={{
                    fontSize: "13px",
                    marginBottom: "10px",
                    color: "#245770",
                  }}
                >
                  Terms & Conditions | Privacy Policy | Disclamer
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
