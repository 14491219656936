import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import rapid from "../../resources/icons/rapid-logo.png";
import { FORGOT_PASSWORD, API_URL_USER } from "../api/constant";
import { invokeApi } from "../api/index";

const ForgotPassword = () => {
  const [loginDetails, setLoginDetails] = useState({
    email: "",
  });
  const history = useNavigate();

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, []);

  const handleKeyEvent = (event) => {
    if (event.keyCode === 13) {
      onSubmit();
    }
  };

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
      [event.target.name + "Err"]: false,
    });
  };

  const isFormValid = () => {
    let errors = {};

    if (!loginDetails.email) {
      errors["emailErr"] = true;
    }

    if (errors.emailErr) {
      setLoginDetails({ ...loginDetails, ...errors });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      let payload = {
        email: loginDetails.email.trim(),
      };
      invokeApi(
        "POST",
        `${API_URL_USER}${FORGOT_PASSWORD}`,
        payload,
        null,
        true
      ).then((response) => {
        if (response.status == 204) {
          toast.success(
            "Link Sent Successfully, please check your registered E-Mail",
            { autoClose: 2000 }
          );
          history("/login");
        } else {
          toast.error("Something Went wrong, please try again", {
            autoClose: 2000,
          });
        }
      });
    }
  };

  return (
    <div className={"login"}>
      <Grid container>
        <Grid item md={4} xs={12} className={"loginBanner"}>
          <div
            style={{
              position: "relative",
              top: "20%",
              color: "white",
              fontSize: "50px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <img
              src={rapid}
              alt="Rapid"
              style={{ width: "150px", height: "50px" }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{ padding: "50px", paddingRight: "80px" }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "#085575",
            }}
          >
            Reset Link
          </div>
          <div
            style={{
              fontSize: "10px",
              marginBottom: "10px",
              color: "#085575",
            }}
          >
            Enter your email, and we will send the link.
          </div>
          <div>
            <TextField
              name="email"
              onChange={onInputChange}
              color="primary"
              placeholder="Email"
              value={loginDetails.email}
              variant="standard"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5px 5px 5px 10px",
                margin: "10px 0px 10px 0px",
                width: "100%",
                border: loginDetails.emailErr ? "1px solid red" : "",
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlinedIcon style={{ color: "#1ddcfb" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              margin: "20px 0px 5px 0px",
              width: "104%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSubmit}
              style={{ backgroundColor: "#085575" }}
            >
              Send Reset Link
            </Button>
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <div
              style={{
                backgroundColor: "#afc2c4",
                borderRadius: "10px",
                padding: "5px",
                cursor: "pointer",
                width: "104%",
              }}
              onClick={() => {
                history("/login");
              }}
            >
              <LockOutlinedIcon
                style={{ color: "#085575", position: "relative", top: "2px" }}
              />
              <span
                style={{
                  position: "relative",
                  top: "-4px",
                  left: "10px",
                  color: "#085575",
                }}
              >
                Return to Login Page
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
