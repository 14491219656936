import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: "white",
        width: "80%",
        borderRadius: 30,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
    },
}))
const UpgradePlanCard = (props) => {
    const classes = useStyles();
  return (
      <div className={classes.cardContainer}>
          {props.children}
    </div>
  )
}

export default UpgradePlanCard