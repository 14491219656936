import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Chart from "react-apexcharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  REVOKE,
  INVOKE,
  API_URL_DEVICE,
  CHUNKED_TYPE_CONNECTION,
  TYPE_CONNECTION,
  PING,
} from "./api/constant";
import { invokeApi, invokeGraphApi } from "./api/index";
var channelId = null;
const columns = [
  { id: "n", label: "Network" },
  { id: "p", label: "Protocol" },
  { id: "ld", label: "Source" },
  { id: "rd", label: "Destination" },
];
const Connections = (props) => {
  let xAxis = Array(61)
    .fill()
    .map((_, i) => String(i * 5));
  let reverseXaxis = [...xAxis].reverse();
  const [tabelData, setTabelData] = useState([]);
  const initial = [
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
  ];
  const [data, setData] = useState(initial);
  const [resp, setResp] = useState(null);
  const [original, setOriginal] = useState({
    data1: [],
    data2: [],
    data3: [],
  });
  let deviceFilterLet = props.deviceId;
  let revokeId = null;
  const bindWithChunking = (channel, event, callback) => {
    // Now the chunked variation. Allows arbitrarily long messages.
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;
      if (
        ev.receivedFinal &&
        ev.chunks.length === Object.keys(ev.chunks).length
      ) {
        callback(JSON.parse(ev.chunks.join("")));
        delete events[data.id];
      }
    });
  };

  useEffect(() => {
    setData(initial);
    const interval = setInterval(() => {
      if (revokeId !== null) {
        invokeGraphApi("POST", `${API_URL_DEVICE}${PING}${revokeId}`)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);
    if (props.deviceId != null) {
      invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
        type: TYPE_CONNECTION,
        deviceId: props.deviceId,
        reset: false,
      }).then((res) => {
        revokeId = res.id;
      });
      let channel = props.pusher.subscribe(props.deviceId);
      channelId = props.deviceId;
      bindWithChunking(channel, CHUNKED_TYPE_CONNECTION, function (res) {
        if (res.clientId == channelId) {
          setResp(res);
        }
      });
      return () => {
        clearInterval(interval);
        if (revokeId != null) {
          setOriginal({
            data1: [],
            data2: [],
            data3: [],
          });
          setData(initial);
          setTabelData([]);
          invokeApi("POST", `${API_URL_DEVICE}${REVOKE}` + revokeId, {
            type: TYPE_CONNECTION,
            deviceId: deviceFilterLet,
            // inf: "en0",
            reset: false,
          });
        }
      };
    }
  }, [props.deviceId]);

  useEffect(() => {
    if (resp != null) {
      let temp = JSON.parse(JSON.stringify(data));
      let originalTemp = { ...original };
      let buffer = {
        b1: [],
        b2: [],
        b3: [],
      };
      buffer.b1.push(parseFloat(resp.data.tcp_count));
      buffer.b2.push(parseFloat(resp.data.udp_count));
      buffer.b3.push(parseFloat(resp.data.others_count));

      originalTemp.data1.push(parseFloat(resp.data.tcp_count));
      originalTemp.data2.push(parseFloat(resp.data.udp_count));
      originalTemp.data3.push(parseFloat(resp.data.others_count));

      temp[0].data = temp[0].data
        .slice(1, temp[0].data.length)
        .concat(buffer.b1);
      temp[1].data = temp[1].data
        .slice(1, temp[1].data.length)
        .concat(buffer.b2);
      temp[2].data = temp[2].data
        .slice(1, temp[2].data.length)
        .concat(buffer.b3);

      setOriginal(originalTemp);
      setData(JSON.parse(JSON.stringify(temp)));
      setTabelData(JSON.parse(JSON.stringify(resp.data.results)));
    }
  }, [resp]);

  let sum1 = original.data1.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg1 =
    original.data1.length > 0
      ? (sum1 / original.data1.length).toFixed(3)
      : "0.000";

  let sum2 = original.data2.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg2 =
    original.data2.length > 0
      ? (sum2 / original.data2.length).toFixed(3)
      : "0.000";

  let sum3 = original.data3.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg3 =
    original.data3.length > 0
      ? (sum3 / original.data3.length).toFixed(3)
      : "0.000";

  let peak1 =
    original.data1.length > 0
      ? Math.max(...original.data1).toFixed(3)
      : "0.000";
  let peak2 =
    original.data2.length > 0
      ? Math.max(...original.data2).toFixed(3)
      : "0.000";
  let peak3 =
    original.data3.length > 0
      ? Math.max(...original.data3).toFixed(3)
      : "0.000";
  let value1 =
    original.data1.length > 0
      ? original.data1[original.data1.length - 1]
      : "0.000";
  let value2 =
    original.data2.length > 0
      ? original.data2[original.data2.length - 1]
      : "0.000";
  let value3 =
    original.data3.length > 0
      ? original.data3[original.data3.length - 1]
      : "0.000";
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div style={{ padding: "10px" }}>Real-Time Connections</div>
        <div style={{ height: "30vh", padding: "10px" }}>
          <Chart
            type="area"
            height={"100%"}
            options={{
              chart: {
                animations: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: true,
                },
              },
              stroke: {
                show: true,
                width: 1,
              },
              plotOptions: {
                bar: {
                  distributed: true,
                  horizontal: false,
                  borderRadius: 10,
                },
              },
              colors: ["#1976d2", "#087d94", "#0fcbd0"],
              dataLabels: {
                enabled: false,
              },
              grid: {
                show: false,
              },

              xaxis: {
                position: "top",
                showDuplicates: true,
                categories: reverseXaxis.map((i) => {
                  return i;
                }),
                labels: {
                  formatter: function (value) {
                    if (value != 0 && parseInt(value) % 60 == 0) {
                      return parseInt(value) / 60 + "m";
                    }
                    return "";
                  },
                },
              },
              yaxis: {},
              legend: {
                show: false,
                position: "right",
                offsetY: 40,
              },
            }}
            series={[
              {
                name: "UDP",
                data: data[0].data,
              },
              {
                name: "TCP",
                data: data[1].data,
              },
              {
                name: "Other",
                data: data[2].data,
              },
            ]}
          />
        </div>
        <div>
          <div
            style={{
              backgroundColor: "#d2e1e35c",
              padding: "10px",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "10%" }}>
                UDP: <span>{value1}</span>
              </div>
              <div style={{ width: "10%" }}>
                TCP: <span>{value2}</span>
              </div>
              <div style={{ width: "10%" }}>
                Other: <span>{value3}</span>
              </div>
              <div style={{ width: "10%" }}>
                UDP-Avg: <span>{avg1}</span>
              </div>
              <div style={{ width: "10%" }}>
                TCP-Avg: <span>{avg2}</span>
              </div>
              <div style={{ width: "15%" }}>
                Other-Avg: <span>{avg3}</span>
              </div>
              <div style={{ width: "11%" }}>
                UDP-Peak: <span>{peak1}</span>
              </div>
              <div style={{ width: "11%" }}>
                TCP-Peak: <span>{peak2}</span>
              </div>
              <div style={{ width: "11%" }}>
                Other-Peak: <span>{peak3}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TableContainer
        sx={{ maxHeight: 245 }}
        style={{
          marginTop: "10px",
          borderRadius: "10px",
          // scrollbarWidth: "none",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow key="head">
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: 900 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ overflowY: "scroll", overflowX: "scroll" }}>
            {tabelData.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  style={{ cursor: "pointer" }}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Connections;
