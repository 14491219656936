import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import Badge from "@mui/material/Badge";
import Menu from "@material-ui/core/Menu";
import IconButton from "@mui/material/IconButton";
import TimeAgo from "react-timeago";
import Drawer from "@mui/material/Drawer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";
import moment from "moment";
import { useDispatch } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@mui/material/Avatar";
import { resetFun } from "../redux/reducer";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: "20px !important",
    left: "90% !important",
    top: "6% !important",
  },
  notification: {
    left: "75% !important",
    width: "22%",
  },
  menuList: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  menuPaperMobile: {
    borderRadius: "20px !important",
    left: "40% !important",
    top: "6% !important",
  },
  menuListMobile: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  notificationMobile: {
    left: "5% !important",
    width: "70%",
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: "2px",
    right: "2px",
    fontSize: "0.5rem",
    minWidth: "12px",
    padding: "2px",
    height: "12px",
  },
}));
const Header = ({
  headerName,
  notificationBadgeCount,
  handleClickNoti,
  anchorNoti,
  openNotification,
  handleCloseNoti,
  notifications,
  lastItemRef,
  hasMore,
  notificationTypeFun,
  user,
  handleClick,
  anchorEl,
  open,
  handleClose,
  account,
  logout,
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        borderTopLeftRadius: "30px",
        paddingBottom: "0.2em",
        paddingTop: "0.2em",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          fontSize: "1.5em",
          padding: "7px 7px 7px 40px",
          color: "#085B74",
        }}
      >
        {headerName}
      </div>
      <div
        style={{
          display: "flex",
          marginLeft: "auto",
          marginRight: "2%",
          alignItems: "center",
        }}
        className="tr-hide-mobile"
      >
        <StyledBadge
          badgeContent={notificationBadgeCount}
          color="primary"
          style={{
            position: "relative",
            left: "-15px",
            cursor: "pointer",
          }}
          onClick={handleClickNoti}
        >
          <NotificationsIcon
            color="action"
            style={{
              backgroundColor: "#80808047",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              padding: "5px",
            }}
          />
        </StyledBadge>
        <Menu
          id="basic-menu"
          anchorEl={anchorNoti}
          open={openNotification}
          onClose={handleCloseNoti}
          classes={{
            paper: classes.notification,
          }}
          className="tr-hide-mobile"
        >
          <div
            style={{
              height: "70vh",
              overflow: "auto",
            }}
          >
            {notifications.length > 0 ? (
              notifications.map((iter, index) => {
                if (index + 1 === notifications.length && hasMore) {
                  return (
                    <div
                      ref={lastItemRef}
                      style={{
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      Loading...
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        display: "flex",
                        borderRadius: "10px",
                        backgroundColor: "#cbcbd530",
                        margin: "5px",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: notificationTypeFun(iter.eventType),
                          borderRadius: "10px",
                          margin: "0px 10px 0px 0px",
                          height: "38px",
                        }}
                      >
                        <NotificationsIcon
                          style={{
                            fontSize: "25px",
                            color: "white",
                            padding: "5px",
                          }}
                        />
                      </div>
                      <div>
                        <div
                          style={{
                            overflow: "hidden",
                            fontSize: "12px",
                          }}
                        >
                          {JSON.parse(iter.details)?.message}
                        </div>
                        <div
                          style={{
                            position: "relative",
                            top: "5px",
                            fontSize: "9px",
                            color: "gray",
                          }}
                        >
                          <TimeAgo
                            date={moment(iter.updatedAt).local().valueOf()}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "gray",
                  padding: "50px",
                }}
              >
                No Messages Found
              </div>
            )}
          </div>
        </Menu>
        <Avatar
          alt={user?.name?.toUpperCase()}
          src={user?.image || "/static/images/avatar/2.jpg"}
          style={{
            width: "30px",
            height: "30px",
            position: "relative",
          }}
        />
        <span
          style={{
            cursor: "pointer",
            position: "relative",
            padding: "0px 10px 0px 10px",
            color: "#085B74",
          }}
          onClick={handleClick}
        >
          {user?.name}
        </span>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          className="tr-hide-mobile"
          open={open}
          onClose={handleClose}
          classes={{
            paper: classes.menuPaper,
            list: classes.menuList,
          }}
        >
          <MenuItem
            onClick={() => {
              history("/setting?tab=account");
              handleClose();
            }}
          >
            <img
              src={account}
              alt="Fleet"
              style={{
                position: "relative",
                top: "2px",
                left: "-5px",
              }}
            />{" "}
            <span style={{ color: "#245770" }}> My account </span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              sessionStorage.removeItem("user");
              history("/login");
              handleClose();
              dispatch(resetFun());
            }}
          >
            <img
              src={logout}
              alt="Fleet"
              style={{
                position: "relative",
                top: "2px",
                left: "-5px",
              }}
            />{" "}
            <span style={{ color: "#245770" }}> Logout </span>
          </MenuItem>
        </Menu>
      </div>
      <div className="tr-hide-web">
        <IconButton
          size="large"
          onClick={() => setIsOpen(true)}
          aria-label="logo"
        >
          <MoreVertIcon fontSize="large"></MoreVertIcon>
        </IconButton>
        <Drawer
          anchor="top"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="sideBar tr-hide-web"
          style={{ width: "50%" }}
        >
          <div
            style={{
              display: "flex",
              margin: "0.5em",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledBadge
              badgeContent={notificationBadgeCount}
              color="primary"
              style={{
                position: "relative",
                left: "-15px",
                cursor: "pointer",
              }}
              onClick={handleClickNoti}
            >
              <NotificationsIcon
                color="action"
                style={{
                  backgroundColor: "#80808047",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  padding: "5px",
                }}
              />
            </StyledBadge>
            <Menu
              id="basic-menu"
              anchorEl={anchorNoti}
              open={openNotification}
              onClose={handleCloseNoti}
              classes={{
                paper: classes.notificationMobile,
              }}
            >
              <div
                style={{
                  height: "70vh",
                  overflow: "auto",
                }}
              >
                {notifications.length > 0 ? (
                  notifications.map((iter, index) => {
                    if (index + 1 === notifications.length && hasMore) {
                      return (
                        <div
                          ref={lastItemRef}
                          style={{
                            textAlign: "center",
                            padding: "10px",
                          }}
                        >
                          Loading...
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            display: "flex",
                            borderRadius: "10px",
                            backgroundColor: "#cbcbd530",
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: notificationTypeFun(
                                iter.eventType
                              ),
                              borderRadius: "10px",
                              margin: "0px 10px 0px 0px",
                              height: "38px",
                            }}
                          >
                            <NotificationsIcon
                              style={{
                                fontSize: "25px",
                                color: "white",
                                padding: "5px",
                              }}
                            />
                          </div>
                          <div>
                            <div
                              style={{
                                overflow: "hidden",
                                fontSize: "12px",
                              }}
                            >
                              {JSON.parse(iter.details)?.message}
                            </div>
                            <div
                              style={{
                                position: "relative",
                                top: "5px",
                                fontSize: "9px",
                                color: "gray",
                              }}
                            >
                              <TimeAgo
                                date={moment(iter.updatedAt).local().valueOf()}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: "gray",
                      padding: "50px",
                    }}
                  >
                    No Messages Found
                  </div>
                )}
              </div>
            </Menu>
            <Avatar
              alt={user?.name?.toUpperCase()}
              src="/static/images/avatar/2.jpg"
              style={{
                width: "30px",
                height: "30px",
                position: "relative",
              }}
            />
            <span
              style={{
                cursor: "pointer",
                position: "relative",
                padding: "0px 10px 0px 10px",
                color: "#085B74",
              }}
              onClick={handleClick}
            >
              {user?.name}
            </span>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="tr-hide-web"
              classes={{
                paper: classes.menuPaperMobile,
                list: classes.menuListMobile,
              }}
            >
              <MenuItem
                onClick={() => {
                  history("/setting?tab=account");
                  handleClose();
                }}
              >
                <img
                  src={account}
                  alt="Fleet"
                  style={{
                    position: "relative",
                    top: "2px",
                    left: "-5px",
                  }}
                />{" "}
                <span style={{ color: "#245770" }}> My account </span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  sessionStorage.removeItem("user");
                  history("/login");
                  handleClose();
                  dispatch(resetFun());
                }}
              >
                <img
                  src={logout}
                  alt="Fleet"
                  style={{
                    position: "relative",
                    top: "2px",
                    left: "-5px",
                  }}
                />
                <span style={{ color: "#245770" }}> Logout </span>
              </MenuItem>
            </Menu>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
