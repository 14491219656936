import React from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@mui/material/Box";
import "./index.scss";

const PercentLoader = (props) => {
  return (
    <Box>
      <div className="percentage-indicator">
        <div className="spinner-container">
          <Box>
            <CircularProgress />
          </Box>
          <Box sx={{ minWidth: 35, mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              {props.value}%
            </Typography>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default PercentLoader;
