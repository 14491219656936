import "../index.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { invokeApi } from "./api";
import { VERIFY_EMAIL, API_URL_USER } from "./api/constant";
export const VerifyEmail = () => {
  const history = useNavigate();
  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    invokeApi("POST", `${API_URL_USER}${VERIFY_EMAIL}?token=${token}`)
      .then((response) => {
        if (response.message) {
          // console.log(JSON.stringify(response));
          toast.error("Email verification failed. Please contact support");
          history("/");
        } else {
          // debugger;
          // console.log(JSON.stringify(response));
          // console.log(`COND ${response.code >= 200 && response.code < 300}`);
          toast.info("Account verified completed, please login");
          history("/");
        }
      })
      .catch((err) => {
        // debugger;
        toast.info("Error");
      });
  };

  return (
    <div className="verify-email-container">
      <div className="info">
        {/* Email verification is Failed please contact support team */}
      </div>
    </div>
  );
};
