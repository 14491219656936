import React, { useState, useEffect } from "react";
import Select from "../components/common/Select";
import { Button } from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import DeveloperModeOutlinedIcon from "@mui/icons-material/DeveloperModeOutlined";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";

const columns = [
  { id: "pid", label: "PID" },
  { id: "device", label: "Device" },
  { id: "dateCreated", label: "Date Created(UTC)" },
  { id: "command", label: "Command" },
  { id: "run", label: "Run" },
  { id: "output", label: "Output" },
];
const CommandExecution = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [devicesList, setDevicesList] = useState([]);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [commandList, setCommandList] = useState([]);
  const [nameErr, setNameErr] = useState(false);
  const [modal, setModal] = useState(false);
  const [commandData, setCommandData] = useState({
    command: "",
    groupName: "",
    deviceName: "",
  });

  useEffect(() => {
    props.headerName("Command Execution");
  }, []);

  const modalContent = () => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
          }}
        >
          <span>Command to Run</span>
          <span
            onClick={() => {
              setModal(false);
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div>
          <Grid
            container
            spacing={1}
            style={{
              width: "100%",
              margin: "0px",
              padding: "5px 30px 20px 10px",
            }}
          >
            <Grid item xs={12} md={12}>
              <Grid>
                <div>
                  <h4
                    style={{
                      color: "#075a6a",
                      marginBottom: "5px",
                      marginTop: "10px",
                    }}
                  >
                    Write a Command to Run*
                  </h4>
                  <Grid item xs={12} md={12}>
                    <TextField
                      name="name"
                      className={
                        nameErr ? "createGroupFieldsError" : "createGroupFields"
                      }
                      onChange={(e) => {
                        setCommandData({
                          ...commandData,
                          command: e.target.value,
                        });
                      }}
                      color="primary"
                      required
                      fullWidth
                      style={{ borderRadius: "12px" }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12}>
              <h4
                style={{
                  color: "#075a6a",
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                Select Device*
              </h4>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Select
                    list={[{ name: "Group 1", value: "Group 1" }]}
                    name={"Choose Group"}
                    onChange={(e) => {
                      setCommandData({ ...commandData, groupName: e });
                    }}
                    style={{
                      borderRadius: "12px",
                      padding: "10px",
                      marginTop: "0px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  ></Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    list={[{ name: "Device 1", value: "Device 1" }]}
                    name={"Choose Device"}
                    onChange={(e) => {
                      setCommandData({ ...commandData, deviceName: e });
                    }}
                    style={{
                      borderRadius: "12px",
                      padding: "10px",
                      marginTop: "0px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  ></Select>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <hr style={{ margin: "0px 25px 25px" }} />
        <div className="footer">
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              console.log(commandData);
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              setModal(false);
            }}
          >
            Close
          </Button>
        </div>
      </>
    );
  };

  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, width: "40%" }}>
          {modalContent()}
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    setDeviceFilter(device);
    setGroupFilter(group);
  }, [group, device]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            margin: "10px",
            fontSize: ".8rem",
            paddingLeft: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filter Device
        </div>
        <div>
          <Select
            list={groupList}
            value={groupFilter}
            name={"Choose Group"}
            onChange={(e) => {
              setGroupFilter(e);
            }}
          />
        </div>
        <div>
          <Select
            list={devicesList}
            name={"Choose Device"}
            value={deviceFilter}
            onChange={(e) => {
              setDeviceFilter(e);
            }}
          />
        </div>
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            width: "100px",
            marginTop: "-5px",
            margin: "10px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              height: "25px",
            }}
            onClick={() => {
              dispatch(deviceFun(deviceFilter));
              dispatch(groupFun(groupFilter));
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            color: "rgb(8, 94, 111)",
            marginLeft: "50px",
            marginTop: "20px",
            fontSize: "20px",
          }}
          className="tr-hide-mobile"
        >
          Executed commands
        </div>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <Grid item xs={12} md={6}>
            <div
              onClick={() => {
                setModal(true);
              }}
              style={{
                backgroundColor: "#075a6a",
                borderRadius: "15px",
                padding: "5px 10px 5px 10px",
                color: "white",
                cursor: "pointer",
                width: "max-content",
                marginTop: "20px",
                marginRight: "50px",
              }}
            >
              <AddCircleOutlineIcon
                style={{ paddingTop: "3px", fontSize: "20px" }}
              />{" "}
              <span
                style={{
                  position: "relative",
                  top: "-5px",
                  fontSize: "12px",
                }}
              >
                New Command
              </span>
            </div>
          </Grid>
        </div>
      </div>
      <Paper
        sx={{
          width: "93%",
          overflow: "hidden",
          marginLeft: "50px",
          marginTop: "20px",
        }}
      >
        <TableContainer sx={{ maxHeight: 575 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key="head">
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 900 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {commandList.map((row) => {
                return (
                  <TableRow tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "run") {
                        value = <DeveloperModeOutlinedIcon />;
                      }
                      if (column.id === "output") {
                        value = (
                          <FeedOutlinedIcon
                            style={{
                              fontSize: "40px",
                              color: "#08cacfde",
                              paddingLeft: "10px",
                            }}
                          />
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {modal && modalFun()}
    </div>
  );
};

export default CommandExecution;
