import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Permission from "../resources/icons/permission.png";
import Modal from "@mui/material/Modal";
import { TextField, Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Grid from "@mui/material/Grid";
import Switch from "@material-ui/core/Switch";
import { invokeApi, checkPermission } from "./api/index";
import {
  API_URL_USER,
  USERS,
  API_URL_DEVICE,
  USER_INVITE,
  CHECK_USER_TAKEN,
  EDIT_USER,
  USER_PERMISSION,
  DEVICE_USAGE,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
const columns = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "createdAt", label: "Created Date" },
  { id: "userType", label: "User Type" },
  { id: "designation", label: "Designation" },
  { id: "region", label: "Region" },
  { id: "actions", label: "Actions" },
];

const Users = () => {
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(null);
  const observer = React.useRef();
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = React.useState(1);
  const [pageArray, setPageArray] = React.useState([]);
  const [modal, setModal] = useState({});
  const [inviteDetails, setInviteDetails] = useState({});
  const [users, setUsers] = useState([]);
  const [edit, setEdit] = useState(null);
  const [rowDetails, setRowDetails] = useState(null);
  const [delModal, setDelModal] = useState({
    id: null,
    status: false,
  });
  const [isLoader, setIsLoader] = useState(false);
  const [allowedUsers, setAllowedUsers] = useState({});
  const [editDetails, setEditDetails] = useState({
    name: "",
    email: "",
    userType: "",
    designation: "",
    region: "",
    id: "",
  });
  const [permissions, setpermissions] = useState({
    allowInvitesAndModifyAccounts: false,
    allowBilling: false,
    allowMonitorDevice: false,
    allowRegisterDevice: false,
    allowDeviceCtrl: false,
    allowRollouts: false,
  });
  const [userPermission, setUserPermission] = useState({
    allowInvitesAndModifyAccounts: false,
    allowBilling: false,
    allowMonitorDevice: false,
    allowRegisterDevice: false,
    allowDeviceCtrl: false,
    allowRollouts: false,
  });
  const [isAllowed, setIsAllowed] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("user"))?.user;
  const limits = JSON.parse(sessionStorage.getItem("user"))?.limits;

  useEffect(() => {
    let allowed = checkPermission(
      "ALLOW_INVITES_AND_MODIFY_ACCOUNTS",
      user.isAdmin,
      limits?.allowInvitesAndModifyAccounts
    );
    setIsAllowed(allowed);
  }, []);

  useEffect(() => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE_USAGE}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setAllowedUsers((prev) => ({
            ...prev,

            ...res.users,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setInviteDetails({
      ...inviteDetails,
      permissions: { ...permissions },
    });
  }, [permissions]);

  const getUsers = (pg) => {
    if (!pageArray.includes(pg)) {
      invokeApi("GET", `${API_URL_USER}${USERS}`, null, {
        page: pg,
      })
        .then((response) => {
          if (response.results) {
            let allowed = checkPermission(
              "ALLOW_INVITES_AND_MODIFY_ACCOUNTS",
              user.isAdmin,
              limits?.allowInvitesAndModifyAccounts
            );
            let data = response.results.map((iter, pos) => {
              return createData(
                iter.id,
                iter.name,
                iter.email,
                moment(iter.createdAt).local().format("DD MMM YYYY (hh:mm A)"),
                iter.type,
                iter.designation,
                iter.region,
                iter.limits,
                allowed
              );
            });
            setTotalPages(response.totalPages + 1);
            let array = [...pageArray];
            array.push(pg);
            setPageArray(array);
            if (pages == 1) {
              setUsers(data);
            } else {
              setUsers([...users, ...data]);
            }
          } else {
            toast.error("Fetching user details failed", { autoClose: 2000 });
          }
        })
        .catch((err) => {
          console.log("Fetching user details failed", err);
        });
    }
  };

  function createData(
    id,
    name,
    email,
    createdAt,
    userType,
    designation,
    region,
    limits,
    isAllowed
  ) {
    let actions = (
      <div>
        <DeleteOutlineIcon
          style={{ color: "#28d1d5", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            if (isAllowed) {
              setDelModal((prevVal) => ({ ...prevVal, id: id, status: true }));
              // deleteHandler(id);
            } else {
              toast.error("Not Allowed to delete user", { autoClose: 2000 });
            }
          }}
        />
        <EditIcon
          style={{
            color: "#28d1d5",
            cursor: "pointer",
            height: "25px",
            width: "20px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (isAllowed) {
              setEdit(id);
            } else {
              toast.error("Not Allowed to edit user", { autoClose: 2000 });
            }
          }}
        />
        <img
          src={Permission}
          alt="permission"
          style={{
            width: "1.5em",
            height: "1.5em",
            cursor: "pointer",
            marginLeft: 5,
          }}
          onClick={() => {
            if (isAllowed) {
              setModal({ ...modal, open: true });
            } else {
              toast.error("Not Allowed to change user permissions", {
                autoClose: 2000,
              });
            }
          }}
        />
      </div>
    );
    return {
      id,
      name,
      email,
      createdAt,
      userType,
      designation,
      region,
      actions,
      limits,
    };
  }

  useEffect(() => {
    if (edit != null) {
      let d = users.find((i) => i.id == edit);
      setEditDetails(d);
    }
  }, [edit]);

  const inviteUserHandler = () => {
    invokeApi("POST", `${API_URL_USER}${CHECK_USER_TAKEN}`, {
      email: `${inviteDetails.email}`,
    })
      .then((res) => {
        if (res.taken === true) {
          toast.error("Email Already Taken", { autoClose: 2000 });
          setIsLoader(false);
        } else {
          invokeApi("POST", `${API_URL_USER}${USER_INVITE}`, inviteDetails)
            .then((res) => {
              if (!res.message) {
                toast.success("Successfully Invite User", { autoClose: 2000 });
                setModal({});
                setInviteDetails({});
                setIsLoader(false);
                setpermissions({
                  allowInvitesAndModifyAccounts: false,
                  allowBilling: false,
                  allowMonitorDevice: false,
                  allowRegisterDevice: false,
                  allowDeviceCtrl: false,
                  allowRollouts: false,
                });
                setPages(1);
                setPageArray([]);
                setRefresh(Math.random());
              } else {
                toast.error(res.message, { autoClose: 2000 });
                setIsLoader(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userPermissionHandler = (id) => {
    invokeApi("PUT", `${API_URL_USER}${USER_PERMISSION}${id}`, {
      ...userPermission,
    })
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        let ss = JSON.parse(sessionStorage.getItem("user"));
        let limits = ss.limits;
        if (limits.userId == res.userId) {
          ss.limits = res;
          sessionStorage.setItem("user", JSON.stringify(ss));
        }
        setPages(1);
        setPageArray([]);
        setRefresh(Math.random());
        setModal({});
        setUserPermission({
          allowInvitesAndModifyAccounts: false,
          allowBilling: false,
          allowMonitorDevice: false,
          allowRegisterDevice: false,
          allowDeviceCtrl: false,
          allowRollouts: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const modalFun = () => {
    return (
      <Modal
        open={modal.open == true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal({});
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="userPopUp" style={{ p: 4 }}>
          <div
            style={{
              backgroundColor: "#075a6a",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              padding: "10px",
              color: "#c1d8dd",
            }}
          >
            <span>
              {rowDetails == null
                ? "Invite User"
                : `Edit ${rowDetails.name} Permissions`}{" "}
            </span>
            <span
              onClick={() => {
                setModal({});
                setInviteDetails({});
                setRowDetails(null);
              }}
              style={{ float: "right", cursor: "pointer" }}
            >
              X
            </span>
          </div>
          <Grid
            container
            spacing={2}
            style={{ width: "100%", margin: "0px", paddingRight: "16px" }}
          >
            <Grid item md={12} sm={12} style={{ display: "flex" }}>
              <Grid xs={12} md={6} style={{ display: "flex" }}>
                <Grid item xs={6} md={5}>
                  <div className="emailText">Email</div>
                </Grid>
                <Grid item xs={10} md={7}>
                  {rowDetails == null ? (
                    <TextField
                      name="email"
                      className="emailField"
                      onChange={(e) => {
                        setInviteDetails({
                          ...inviteDetails,
                          permissions: { ...permissions },
                          email: e.target.value,
                        });
                      }}
                      color="primary"
                      placeholder="Email"
                      value={inviteDetails.email}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <TextareaAutosize
                      style={{
                        borderWidth: "1px",
                        backgroundColor: "white",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#06415D",
                        fontFamily: "Roboto-Regular",
                        resize: "none",
                      }}
                      className="emailField"
                      value={rowDetails.email}
                      disabled
                    ></TextareaAutosize>
                    // <div
                    //   className="emailField"
                    //   style={{ padding: "30px 0px 30px 0px" }}
                    // >
                    //   <h4 style={{ margin: "5px" }}>{rowDetails.email}</h4>
                    // </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                <Grid item xs={6} md={6}>
                  <div className="emailText" style={{ paddingRight: 60 }}>
                    Region
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  {rowDetails == null ? (
                    <TextField
                      className="emailField"
                      onChange={(e) => {
                        setInviteDetails({
                          ...inviteDetails,
                          permissions: { ...permissions },
                          region: e.target.value,
                        });
                      }}
                      color="primary"
                      placeholder="Region"
                      value={inviteDetails.region}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <div
                      className="emailField"
                      style={{ padding: "30px 0px 30px 0px" }}
                    >
                      <h4 style={{ margin: "5px" }}>{rowDetails.region}</h4>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{ display: "flex" }}>
              <Grid item xs={12} md={6}>
                <div style={{ display: "flex" }}>
                  <Grid md={5}>
                    <div className="emailText">User Type</div>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    {rowDetails == null ? (
                      <TextField
                        name="email"
                        className="emailField"
                        onChange={(e) => {
                          setInviteDetails({
                            ...inviteDetails,
                            permissions: { ...permissions },
                            type: e.target.value,
                          });
                        }}
                        color="primary"
                        placeholder="User type"
                        value={inviteDetails.type}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    ) : (
                      <div
                        className="emailField"
                        style={{ padding: "30px 0px 30px 0px" }}
                      >
                        <h4 style={{ margin: "5px" }}>{rowDetails.userType}</h4>
                      </div>
                    )}
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex" }}>
                <Grid item xs={12} md={6}>
                  <div className="emailText" style={{ paddingRight: 60 }}>
                    Designation
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  {rowDetails == null ? (
                    <TextField
                      className="emailField"
                      onChange={(e) => {
                        setInviteDetails({
                          ...inviteDetails,
                          permissions: { ...permissions },
                          designation: e.target.value,
                        });
                      }}
                      color="primary"
                      placeholder="Designation"
                      value={inviteDetails.designation}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  ) : (
                    <div
                      className="emailField"
                      style={{ padding: "30px 0px 30px 0px" }}
                    >
                      <h4 style={{ margin: "5px" }}>
                        {rowDetails.designation}
                      </h4>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div
            style={{
              backgroundColor: "white",
              padding: "15px",
              borderRadius: "10px",
              margin: "5%",
            }}
          >
            <div>General Permission</div>
            <Grid
              container
              spacing={2}
              style={{ padding: "0px", margin: "0px", fontSize: "12px" }}
            >
              <Grid item xs={12} md={6} style={{ padding: "3% 5% 0% 5%" }}>
                <div style={{ display: "flex" }}>
                  <div>Invite and Modify accounts</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      color="primary"
                      size={"small"}
                      checked={
                        rowDetails == null
                          ? permissions.allowInvitesAndModifyAccounts
                          : userPermission.allowInvitesAndModifyAccounts
                      }
                      onClick={() => {
                        if (rowDetails == null) {
                          setpermissions((prevPermission) => ({
                            ...prevPermission,
                            allowInvitesAndModifyAccounts:
                              !permissions.allowInvitesAndModifyAccounts,
                          }));
                        } else if (rowDetails !== null) {
                          setUserPermission((prevPermission) => ({
                            ...prevPermission,
                            allowInvitesAndModifyAccounts:
                              !userPermission.allowInvitesAndModifyAccounts,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>Billing</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      color="primary"
                      size={"small"}
                      checked={
                        rowDetails == null
                          ? permissions.allowBilling
                          : userPermission.allowBilling
                      }
                      onClick={() => {
                        if (rowDetails == null) {
                          setpermissions((prevPermission) => ({
                            ...prevPermission,
                            allowBilling: !permissions.allowBilling,
                          }));
                        } else if (rowDetails !== null) {
                          setUserPermission((prevPermission) => ({
                            ...prevPermission,
                            allowBilling: !userPermission.allowBilling,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>Monitor devices</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      color="primary"
                      size={"small"}
                      checked={
                        rowDetails == null
                          ? permissions.allowMonitorDevice
                          : userPermission.allowMonitorDevice
                      }
                      onClick={() => {
                        if (rowDetails == null) {
                          setpermissions((prevPermission) => ({
                            ...prevPermission,
                            allowMonitorDevice: !permissions.allowMonitorDevice,
                          }));
                        } else if (rowDetails !== null) {
                          setUserPermission((prevPermission) => ({
                            ...prevPermission,
                            allowMonitorDevice:
                              !userPermission.allowMonitorDevice,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "3% 5% 0% 5%" }}>
                <div style={{ display: "flex" }}>
                  <div>Register new devices</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      color="primary"
                      size={"small"}
                      checked={
                        rowDetails == null
                          ? permissions.allowRegisterDevice
                          : userPermission.allowRegisterDevice
                      }
                      onClick={() => {
                        if (rowDetails == null) {
                          setpermissions((prevPermission) => ({
                            ...prevPermission,
                            allowRegisterDevice:
                              !permissions.allowRegisterDevice,
                          }));
                        } else if (rowDetails !== null) {
                          setUserPermission((prevPermission) => ({
                            ...prevPermission,
                            allowRegisterDevice:
                              !userPermission.allowRegisterDevice,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>Remote control devices</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      color="primary"
                      size={"small"}
                      checked={
                        rowDetails == null
                          ? permissions.allowDeviceCtrl
                          : userPermission.allowDeviceCtrl
                      }
                      onClick={() => {
                        if (rowDetails == null) {
                          setpermissions((prevPermission) => ({
                            ...prevPermission,
                            allowDeviceCtrl: !permissions.allowDeviceCtrl,
                          }));
                          setInviteDetails({
                            ...inviteDetails,
                            permissions: { ...permissions },
                          });
                        } else if (rowDetails !== null) {
                          setUserPermission((prevPermission) => ({
                            ...prevPermission,
                            allowDeviceCtrl: !userPermission.allowDeviceCtrl,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>Rollouts</div>
                  <div style={{ marginLeft: "auto" }}>
                    <Switch
                      color="primary"
                      size={"small"}
                      checked={
                        rowDetails == null
                          ? permissions.allowRollouts
                          : userPermission.allowRollouts
                      }
                      onClick={() => {
                        if (rowDetails == null) {
                          setpermissions((prevPermission) => ({
                            ...prevPermission,
                            allowRollouts: !permissions.allowRollouts,
                          }));
                          setInviteDetails({
                            ...inviteDetails,
                            permissions: { ...permissions },
                          });
                        } else if (rowDetails !== null) {
                          setUserPermission((prevPermission) => ({
                            ...prevPermission,
                            allowRollouts: !userPermission.allowRollouts,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="footer">
            <Button
              variant="contained"
              disabled={isLoader ? true : false}
              style={{
                textTransform: "none",
                backgroundColor: "#0fcbd0",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              onClick={() => {
                if (rowDetails == null) {
                  inviteUserHandler();
                  setIsLoader(true);
                } else {
                  userPermissionHandler(rowDetails.id);
                }
              }}
            >
              {rowDetails == null
                ? isLoader == true
                  ? "loading..."
                  : "Send Invitation"
                : "Save "}
            </Button>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#075a6a",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              onClick={() => {
                setModal({});
                setInviteDetails({});
                setRowDetails(null);
              }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  const deleteHandler = (id) => {
    invokeApi("DELETE", `${API_URL_USER}${EDIT_USER}${id}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        setPages(1);
        setPageArray([]);
        setRefresh(Math.random());
      })
      .catch((err) => {
        console.log(err);
        setPages(1);
        setRefresh(Math.random());
      });
  };
  const updateAccount = (res) => {
    let ss = JSON.parse(sessionStorage.getItem("user"));
    let user = ss.user;
    if (user.id == res.id) {
      res["paymentPlan"] = user.paymentPlan;
      ss.user = res;
      sessionStorage.setItem("user", JSON.stringify(ss));
    }
  };
  const editHandler = () => {
    invokeApi("PUT", `${API_URL_USER}${EDIT_USER}${editDetails.id}`, {
      type: editDetails.userType,
      designation: editDetails.designation,
      region: editDetails.region,
    })
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        updateAccount(res);
        setPages(1);
        setPageArray([]);
        setRefresh(Math.random());
        setEdit(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editdevice = () => {
    return (
      <>
        <div>
          <Grid>
            <div
              style={{
                backgroundColor: "#075a6a",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                padding: "20px",
                color: "#c1d8dd",
              }}
            >
              <span>Edit User</span>
              <span
                onClick={() => {
                  setEdit(null);
                  setEditDetails({
                    name: "",
                    email: "",
                    userType: "",
                    designation: "",
                    region: "",
                    id: "",
                  });
                }}
                style={{ float: "right", cursor: "pointer" }}
              >
                X
              </span>
            </div>
            <div>
              <Grid
                container
                spacing={1}
                style={{
                  width: "100%",
                  margin: "0px",
                  padding: "5px 10px 20px 10px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ display: "flex", marginBottom: 30 }}
                >
                  <Grid
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Grid md={4}>
                      <h4
                        style={{
                          color: "#075a6a",
                          marginBottom: "5px",
                          marginTop: "4px",
                          paddingRight: 10,
                        }}
                      >
                        Name
                      </h4>
                    </Grid>
                    <Grid md={8}>
                      <div
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "white",
                          padding: "10px 8px 10px 8px",
                          marginRight: "20px",
                          maxWidth: "100%",
                          fontSize: "15px",
                          borderRadius: "10px",
                          color: "rgb(4, 85, 101)",
                          minWidth: "213px",
                        }}
                      >
                        {editDetails.name}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Grid md={3}>
                      <h4
                        style={{
                          color: "#075a6a",
                          marginBottom: "5px",
                          marginTop: "4px",
                          paddingRight: 10,
                        }}
                      >
                        Email
                      </h4>
                    </Grid>
                    <Grid md={8}>
                      <TextareaAutosize
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "white",
                          padding: "9px 9px 9px 9px",
                          marginRight: "20px",
                          maxWidth: "72%",
                          fontSize: "15px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          color: "rgb(4, 85, 101)",
                          minWidth: "213px",
                          fontFamily: "Roboto-Regular",
                          resize: "none",
                        }}
                        value={editDetails.email}
                        disabled
                      ></TextareaAutosize>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ display: "flex", marginBottom: 30 }}
                >
                  <Grid
                    xs={12}
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid md={4}>
                      <h4
                        style={{
                          color: "#075a6a",
                          marginBottom: "5px",
                          marginTop: "10px",
                          paddingRight: 10,
                        }}
                      >
                        Region
                      </h4>
                    </Grid>
                    <Grid md={8}>
                      <TextField
                        name="name"
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "white",
                          padding: "5px 10px 5px 10px",
                          marginRight: "20px",
                          maxWidth: "100%",
                          borderRadius: "10px",
                          minWidth: "213px",
                        }}
                        onChange={(e) => {
                          setEditDetails({
                            ...editDetails,
                            region: e.target.value,
                          });
                        }}
                        placeholder="Name"
                        value={editDetails?.region}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "rgb(4, 85, 101)",
                            fontSize: "15px",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    style={{
                      display: "flex",
                      // maxWidth: "fit-content",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Grid md={3}>
                      <h4
                        style={{
                          color: "#075a6a",
                          marginBottom: "5px",
                          marginTop: "10px",
                          paddingRight: 10,
                        }}
                      >
                        User Type
                      </h4>
                    </Grid>
                    <Grid md={8}>
                      <TextField
                        name="name"
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "white",
                          padding: "5px 10px 5px 10px",
                          marginRight: "20px",
                          maxWidth: "100%",
                          borderRadius: "10px",
                          minWidth: "213px",
                        }}
                        onChange={(e) => {
                          setEditDetails({
                            ...editDetails,
                            userType: e.target.value,
                          });
                        }}
                        placeholder="Name"
                        value={editDetails?.userType}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "rgb(4, 85, 101)",
                            fontSize: "15px",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{ display: "flex", marginBottom: 30 }}
                >
                  <Grid
                    xs={12}
                    md={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid md={4}>
                      <h4
                        style={{
                          color: "#075a6a",
                          marginBottom: "5px",
                          marginTop: "10px",
                          paddingRight: 10,
                        }}
                      >
                        Designation
                      </h4>
                    </Grid>
                    <Grid md={8}>
                      <TextField
                        name="name"
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "white",
                          padding: "5px 10px 5px 10px",
                          marginRight: "20px",
                          maxWidth: "100%",
                          borderRadius: "10px",
                          minWidth: "213px",
                        }}
                        onChange={(e) => {
                          setEditDetails({
                            ...editDetails,
                            designation: e.target.value,
                          });
                        }}
                        placeholder="Name"
                        value={editDetails?.designation}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "rgb(4, 85, 101)",
                            fontSize: "15px",
                            fontWeight: "bold",
                          },
                        }}
                      />
                    </Grid>
                    {/* <div
                        style={{
                          borderWidth: "1px",
                          backgroundColor: "white",
                          padding: "5px 10px 5px 10px",
                          marginRight: "20px",
                          maxWidth: "100%",
                          fontSize: "15px",
                          borderRadius: "10px",
                          color: "rgb(4, 85, 101)",
                          minWidth: "150px",
                        }}
                      >
                        {editDetails.designation}
                      </div> */}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
              width: "100px",
            }}
            onClick={() => {
              editHandler();
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
              width: "100px",
            }}
            onClick={() => {
              setEdit(null);
              setEditDetails({
                name: "",
                email: "",
                userType: "",
                designation: "",
                id: "",
              });
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const editFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={edit != null ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setEdit(null);
            setEditDetails(null);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, maxWidth: "790px" }}>
          {editdevice()}
        </Box>
      </Modal>
    );
  };
  const deleteUser = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
          }}
        >
          <span style={{ paddingLeft: "40%" }}>Are You Sure ?</span>
          <span
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px 20px 0px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              deleteHandler(delModal.id);
              setDelModal((prevVal) => ({
                ...prevVal,
                id: null,
                status: false,
              }));
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };
  const deleteFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={delModal.status === true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setDelModal((prevVal) => ({ ...prevVal, status: false, id: null }));
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, maxWidth: "550px" }}>
          {deleteUser()}
        </Box>
      </Modal>
    );
  };

  const lastItemRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => pages + 1);
          setRefresh(Math.random());
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  useEffect(() => {
    if (totalPages == null) {
      getUsers(pages);
    } else {
      if (pages < totalPages) {
        setHasMore(true);
        getUsers(pages);
      } else {
        setHasMore(false);
      }
    }
  }, [refresh]);

  let inviteFlag =
    (isAllowed && allowedUsers.cur < allowedUsers.allowed) ||
    allowedUsers.allowed == -1;

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key="head">
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 900 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row, index) => {
                if (index + 1 === users.length && hasMore) {
                  return (
                    <TableRow ref={lastItemRef}>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <div>Loading...</div>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => {
                        if (isAllowed) {
                          setUserPermission((prevVal) => ({
                            ...prevVal,

                            allowInvitesAndModifyAccounts:
                              row.limits.allowInvitesAndModifyAccounts,
                            allowBilling: row.limits.allowBilling,
                            allowMonitorDevice: row.limits.allowMonitorDevice,
                            allowRegisterDevice: row.limits.allowRegisterDevice,
                            allowDeviceCtrl: row.limits.allowDeviceCtrl,
                            allowRollouts: row.limits.allowRollouts,
                          }));
                          setRowDetails((prevVal) => ({ ...prevVal, ...row }));
                        }
                      }}
                      // style={{ cursor: user.isAdmin && "pointer" }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div
        onClick={() => {
          if (inviteFlag) {
            setRowDetails(null);
            setUserPermission({});
            setModal({ ...modal, open: true, details: null });
          }
        }}
        style={{
          padding: "5px",
          backgroundColor: inviteFlag ? "#08cacfde" : "#c7c6c3",
          borderRadius: "15px",
          padding: "5px 15px 5px 15px",
          borderRadius: "20px",
          color: "white",
          cursor: inviteFlag ? "pointer" : "not-allowed",
          width: "120px",
          marginTop: "20px",
        }}
      >
        <AddCircleOutlineIcon style={{ paddingTop: "3px" }} />{" "}
        <span style={{ position: "relative", top: "-5px" }}>Invite User</span>
      </div>
      {modalFun()}
      {editFun()}
      {deleteFun()}
    </div>
  );
};

export default Users;
