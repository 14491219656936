import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Chart from "react-apexcharts";
import {
  REVOKE,
  INVOKE,
  API_URL_DEVICE,
  TYPE_LOAD,
  PING,
} from "./api/constant";
import { invokeApi, invokeGraphApi } from "./api/index";
//let interval;
var channelId = null;
const Load = (props) => {
  let xAxis = Array(61)
    .fill()
    .map((_, i) => String(i * 5));
  let reverseXaxis = [...xAxis].reverse();
  const initial = [
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
    {
      data: reverseXaxis.map((i) => {
        return 0;
      }),
    },
  ];
  const [original, setOriginal] = useState({
    data1: [],
    data2: [],
    data3: [],
  });
  const [data, setData] = useState(initial);
  // const [sample, setSample] = useState([
  //   30.34326171875, 60.251953125, 13.751953125,
  // ]);
  // const [refresh, setRefresh] = useState(0);
  const [resp, setResp] = useState(null);
  let deviceFilterLet = props.deviceId;
  let revokeId = null;
  // const process = () => {
  //   let sam = [...sample];
  //   let temp = [...data];
  //   let originalTemp = { ...original };
  //   let index = temp[0].data.length - 1;
  //   if (parseInt(refresh.toString().charAt(5)) % 2 == 0) {
  //     temp[0].data[index] = parseFloat((sample[0] + 1).toFixed(3));
  //     temp[1].data[index] = parseFloat((sample[1] + 1).toFixed(3));
  //     temp[2].data[index] = parseFloat((sample[2] + 1).toFixed(3));

  //     originalTemp.data1.push(parseFloat((sample[0] + 1).toFixed(3)));
  //     originalTemp.data2.push(parseFloat((sample[1] + 1).toFixed(3)));
  //     originalTemp.data3.push(parseFloat((sample[2] + 1).toFixed(3)));

  //     sam[0] = parseFloat((sam[0] + 1).toFixed(3));
  //     sam[1] = parseFloat((sam[1] + 1).toFixed(3));
  //     sam[2] = parseFloat((sam[2] + 1).toFixed(3));
  //   } else {
  //     temp[0].data[index] = parseFloat((sample[0] - 1).toFixed(3));
  //     temp[1].data[index] = parseFloat((sample[1] - 1).toFixed(3));
  //     temp[2].data[index] = parseFloat((sample[2] - 1).toFixed(3));

  //     originalTemp.data1.push(parseFloat((sample[0] - 1).toFixed(3)));
  //     originalTemp.data2.push(parseFloat((sample[1] - 1).toFixed(3)));
  //     originalTemp.data3.push(parseFloat((sample[2] - 1).toFixed(3)));

  //     sam[0] = parseFloat((sam[0] - 1).toFixed(3));
  //     sam[1] = parseFloat((sam[1] - 1).toFixed(3));
  //     sam[2] = parseFloat((sam[2] - 1).toFixed(3));
  //   }

  //   temp[0].data = temp[0].data
  //     .slice(1, temp[0].data.length)
  //     .concat(temp[0].data.slice(0, 1));
  //   temp[1].data = temp[1].data
  //     .slice(1, temp[1].data.length)
  //     .concat(temp[1].data.slice(0, 1));
  //   temp[2].data = temp[2].data
  //     .slice(1, temp[2].data.length)
  //     .concat(temp[2].data.slice(0, 1));
  //   setOriginal(originalTemp);
  //   setSample(sam);
  //   setData(temp);
  // };

  useEffect(() => {
    setData(initial);
    const interval = setInterval(() => {
      if (revokeId !== null) {
        invokeGraphApi("POST", `${API_URL_DEVICE}${PING}${revokeId}`)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);

    if (props.deviceId != null) {
      invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
        type: TYPE_LOAD,
        deviceId: props.deviceId, //"33d1edc5-a76f-4907-b6b9-738a58b09ea4",
        reset: false,
      }).then((res) => {
        revokeId = res.id;
      });
      let channel = props.pusher.subscribe(props.deviceId);
      channelId = props.deviceId;
      channel.bind(TYPE_LOAD, function (res) {
        if (res.clientId == channelId) {
          setResp(res);
        }
      });
      return () => {
        clearInterval(interval);
        if (revokeId != null) {
          setOriginal({
            data1: [],
            data2: [],
            data3: [],
          });
          setData(initial);
          invokeApi("POST", `${API_URL_DEVICE}${REVOKE}` + revokeId, {
            type: TYPE_LOAD,
            deviceId: deviceFilterLet,
            // inf: "en0",
            reset: false,
          });
        }
      };
    }
  }, [props.deviceId]);

  // useEffect(() => {
  //   process();
  // }, [refresh]);

  useEffect(() => {
    if (resp != null) {
      let temp = JSON.parse(JSON.stringify(data));
      let originalTemp = { ...original };
      let buffer = {
        b1: [],
        b2: [],
        b3: [],
      };
      buffer.b1.push(parseFloat(resp.data[0].toFixed(3)));
      buffer.b2.push(parseFloat(resp.data[1].toFixed(3)));
      buffer.b3.push(parseFloat(resp.data[2].toFixed(3)));

      originalTemp.data1.push(parseFloat(resp.data[0].toFixed(3)));
      originalTemp.data2.push(parseFloat(resp.data[1].toFixed(3)));
      originalTemp.data3.push(parseFloat(resp.data[2].toFixed(3)));

      temp[0].data = temp[0].data
        .slice(1, temp[0].data.length)
        .concat(buffer.b1);
      temp[1].data = temp[1].data
        .slice(1, temp[1].data.length)
        .concat(buffer.b2);
      temp[2].data = temp[2].data
        .slice(1, temp[2].data.length)
        .concat(buffer.b3);

      setOriginal(originalTemp);
      setData(JSON.parse(JSON.stringify(temp)));
    }
  }, [resp]);

  let sum1 = original.data1.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg1 =
    original.data1.length > 0
      ? (sum1 / original.data1.length).toFixed(3)
      : "0.000";

  let sum2 = original.data2.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg2 =
    original.data2.length > 0
      ? (sum2 / original.data2.length).toFixed(3)
      : "0.000";

  let sum3 = original.data3.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  );
  let avg3 =
    original.data3.length > 0
      ? (sum3 / original.data3.length).toFixed(3)
      : "0.000";

  let peak1 =
    original.data1.length > 0
      ? Math.max(...original.data1).toFixed(3)
      : "0.000";
  let peak2 =
    original.data2.length > 0
      ? Math.max(...original.data2).toFixed(3)
      : "0.000";
  let peak3 =
    original.data3.length > 0
      ? Math.max(...original.data3).toFixed(3)
      : "0.000";
  let value1 =
    original.data1.length > 0
      ? original.data1[original.data1.length - 1]
      : "0.000";
  let value2 =
    original.data2.length > 0
      ? original.data2[original.data2.length - 1]
      : "0.000";
  let value3 =
    original.data3.length > 0
      ? original.data3[original.data3.length - 1]
      : "0.000";
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div style={{ padding: "10px" }}>Real-Time Load</div>
        <div style={{ height: "60vh", padding: "10px" }}>
          <Chart
            type="area"
            height={"100%"}
            options={{
              chart: {
                animations: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: true,
                },
              },
              stroke: {
                show: true,
                width: 1,
              },
              plotOptions: {
                bar: {
                  distributed: true,
                  horizontal: false,
                  borderRadius: 10,
                },
              },
              colors: ["#1976d2", "#087d94", "#0fcbd0"],
              dataLabels: {
                enabled: false,
              },
              grid: {
                show: false,
              },

              xaxis: {
                position: "top",
                showDuplicates: true,
                categories: reverseXaxis.map((i) => {
                  return i;
                }),
                labels: {
                  formatter: function (value) {
                    if (value != 0 && parseInt(value) % 60 == 0) {
                      return parseInt(value) / 60 + "m";
                    }
                    return "";
                  },
                },
              },
              yaxis: {},
              legend: {
                show: false,
                position: "right",
                offsetY: 40,
              },
            }}
            series={[
              {
                name: "1 minute Load",
                data: data[0].data,
              },
              {
                name: "5 minute Load",
                data: data[1].data,
              },
              {
                name: "15 minute Load",
                data: data[2].data,
              },
            ]}
          />
        </div>
        <div style={{ fontSize: ".8rem" }}>
          <Grid
            container
            spacing={2}
            style={{
              backgroundColor: "#d2e1e35c",
              padding: "10px",
            }}
          >
            <Grid item xs={4} style={{ paddingTop: "0px", paddingLeft: "3%" }}>
              <div>
                1 minute Load: <span>{value1}</span>
              </div>
              <div>
                5 minute Load: <span>{value2}</span>
              </div>
              <div>
                15 minute Load: <span>{value3}</span>
              </div>
            </Grid>
            <Grid item xs={4} style={{ paddingTop: "0px" }}>
              <div>
                1 min-Avg: <span>{avg1}</span>
              </div>
              <div>
                5 min-Avg: <span>{avg2}</span>
              </div>
              <div>
                15 min-Avg: <span>{avg3}</span>
              </div>
            </Grid>
            <Grid item xs={4} style={{ paddingTop: "0px" }}>
              <div>
                1 min-Peak: <span>{peak1}</span>
              </div>
              <div>
                5 min-Peak: <span>{peak2}</span>
              </div>
              <div>
                15 min-Peak: <span>{peak3}</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Load;
