import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import { Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { invokeApi, formatBytes } from "./api/index";
import { makeStyles } from "@material-ui/core/styles";
import {
  API_URL_DEVICE,
  DEVICE,
  GROUPS,
  DEVICE_DROPDOWN,
  GROUP_DROPDOWN,
  SYSTEM_STATUS,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import Select from "./common/Select";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";

function createData3(
  id,
  enabledCheckBox,
  device,
  mountPoint,
  fileSystem,
  options,
  root,
  check,
  actionsButtons
) {
  let enabled = <Checkbox checked={enabledCheckBox} />;
  let actions = (
    <div style={{ display: "flex" }}>
      <EditIcon style={{ color: "#28d1d5" }} />
      <DeleteOutlineIcon style={{ color: "#28d1d5", cursor: "pointer" }} />
    </div>
  );
  return {
    id,
    enabled,
    device,
    mountPoint,
    fileSystem,
    options,
    root,
    check,
    actions,
  };
}

const columns3 = [
  { id: "enabled", label: "Enabled" },
  { id: "device", label: "Device" },
  { id: "mountPoint", label: "Mount Point" },
  { id: "fileSystem", label: "Filesystem" },
  { id: "options", label: "Options" },
  { id: "root", label: "Root" },
  { id: "check", label: "check" },
  { id: "actions", label: "" },
];

const tabledata3 = [
  {
    enabled: true,
    device: "UUID.0002",
    mountPoint: "/mnt/parent",
    fileSystem: "vfat",
    options: "defaults",
    root: "no",
    check: "no",
    actions: "edit",
  },
  {
    enabled: true,
    device: "UUID.0002",
    mountPoint: "/mnt/parent",
    fileSystem: "vfat",
    options: "defaults",
    root: "no",
    check: "no",
    actions: "edit",
  },
  {
    enabled: true,
    device: "UUID.0002",
    mountPoint: "/mnt/parent",
    fileSystem: "vfat",
    options: "defaults",
    root: "no",
    check: "no",
    actions: "edit",
  },
  {
    enabled: true,
    device: "UUID.0002",
    mountPoint: "/mnt/parent",
    fileSystem: "vfat",
    options: "defaults",
    root: "no",
    check: "no",
    actions: "edit",
  },
];

const table3 = tabledata3.map((iter, pos) => {
  return createData3(
    pos,
    iter.enabled,
    iter.device,
    iter.mountPoint,
    iter.fileSystem,
    iter.options,
    iter.root,
    iter.check,
    iter.actions
  );
});

function createData2(id, fileSystem, mountPoint, available, used, unMount) {
  return {
    id,
    fileSystem,
    mountPoint,
    available,
    used,
    unMount,
  };
}

const columns2 = [
  { id: "fileSystem", label: "Filesystem" },
  { id: "mountPoint", label: "Mount Point" },
  { id: "available", label: "Available Storage" },
  { id: "used", label: "Used" },
];

const tabledata2 = [
  {
    fileSystem: "vfat",
    mountPoint: "/mnt/parent",
    available: "defaults",
    used: "no",
    unMount: "",
  },
  {
    fileSystem: "vfat",
    mountPoint: "/mnt/parent",
    available: "defaults",
    used: "no",
    unMount: "",
  },
  {
    fileSystem: "vfat",
    mountPoint: "/mnt/parent",
    available: "defaults",
    used: "no",
    unMount: "",
  },
  {
    fileSystem: "vfat",
    mountPoint: "/mnt/parent",
    available: "defaults",
    used: "no",
    unMount: "",
  },
];

const table2 = tabledata2.map((iter, pos) => {
  return createData2(
    pos,
    iter.fileSystem,
    iter.mountPoint,
    iter.available,
    iter.used,
    iter.unMount
  );
});

function createData1(
  id,
  hostName,
  ipv4Address,
  macAddress,
  leaseTimeRemaining
) {
  return {
    id,
    hostName,
    ipv4Address,
    macAddress,
    leaseTimeRemaining,
  };
}

const columns1 = [
  { id: "hostName", label: "Host Name" },
  { id: "ipv4Address", label: "IPV4 Address" },
  { id: "macAddress", label: "MAC-Address" },
  { id: "leaseTimeRemaining", label: "Lease-Time Remaining" },
];

const tabledata1 = [
  {
    hostName: "vfat",
    ipv4Address: "/mnt/parent",
    macAddress: "defaults",
    leaseTimeRemaining: "no",
  },
  {
    hostName: "vfat",
    ipv4Address: "/mnt/parent",
    macAddress: "defaults",
    leaseTimeRemaining: "no",
  },
  {
    hostName: "vfat",
    ipv4Address: "/mnt/parent",
    macAddress: "defaults",
    leaseTimeRemaining: "no",
  },
  {
    hostName: "vfat",
    ipv4Address: "/mnt/parent",
    macAddress: "defaults",
    leaseTimeRemaining: "no",
  },
];

const table1 = tabledata1.map((iter, pos) => {
  return createData1(
    pos,
    iter.hostName,
    iter.ipv4Address,
    iter.macAddress,
    iter.leaseTimeRemaining
  );
});

const containerStyles = {
  height: 25,
  width: "100%",
  backgroundColor: "#8fbac2ba",
  borderRadius: 50,
  position: "relative",
  textAlign: "center",
  overflow: "hidden",
};

const fillerStyles = {
  height: "100%",
  width: "100%",
  backgroundColor: "#0fcbd0",
  borderRadius: "inherit",
};

const labelStyles = {
  padding: "5px",
  fontSize: "12px",
  color: "white",
  fontWeight: "bold",
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
};

const useStyles = makeStyles((theme) => ({
  systemLeft: {
    paddingTop: "0px !important",
    height: "35px",
    color: "#000000c4",
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  systemRight: {
    paddingTop: "0px !important",
    height: "35px",
    color: "#0000008a",
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  box: {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "20px",
    margin: "10px",
    fontSize: ".8rem",
  },
  network: {
    backgroundColor: "white",
    borderRadius: "12px",
    padding: "10px",
    border: "none",
    fontSize: ".8rem",
    // width: "99.75%",
    // marginLeft: "-1.8%",
  },
}));

const SystemStatus = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modal, setModal] = useState({});
  const [devices, setDevices] = useState([]);
  const [groupDevices, setGroupDevices] = useState([]);
  const [groups, setGroups] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(0);
  const [system, setSystem] = useState(null);
  const [temperature, setTemperature] = useState(null);
  const [memory, setMemory] = useState(null);
  const [network, setNetwork] = useState(null);
  const [mount, setMount] = useState(null);

  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = [];
        devicedata = res.map((iter) => {
          return { value: iter.id, name: iter.deviceName };
        });
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
  };

  const groupUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  useEffect(() => {
    props.headerName("System Status");
    groupUpdate();
  }, []);
  const systemStatusData = (deviceFilter) => {
    if (deviceFilter !== null) {
      invokeApi("GET", `${API_URL_DEVICE}${SYSTEM_STATUS}` + device)
        .then((response) => {
          setMemory(response.data.memory);
          setNetwork(response.data.network);
          let mp = response.data.mount.map((iter, pos) => {
            return createData2(
              pos,
              iter.fs,
              iter.mp,
              `${formatBytes(iter.total - iter.used)[0]} ${
                formatBytes(iter.total - iter.used)[1]
              } /${formatBytes(iter.total)[0]} ${formatBytes(iter.total)[1]} (${
                // iter.percent
                formatBytes(iter.total - iter.used)[0]
              }%)`,
              // iter.percent + " %",
              (100 - iter.percent).toFixed(2) + " %",
              ""
            );
          });
          setMount(mp);
          setSystem(response.data.system);
          setTemperature(response.data.temperature || "NA");
        })
        .catch((err) => {
          setSystem(null);
          setMemory(null);
          setNetwork(null);
          setMount(null);
          setTemperature("NA");
          // toast.error("System status details fetching failed", {
          //   autoClose: 2000,
          // });
        });
    }
  };
  useEffect(() => {
    setDeviceFilter(device);
    setGroupFilter(group);
    if (device != null) {
      invokeApi("GET", `${API_URL_DEVICE}${SYSTEM_STATUS}` + device)
        .then((response) => {
          setMemory(response.data.memory);
          setNetwork(response.data.network);
          let mp = response.data.mount.map((iter, pos) => {
            return createData2(
              pos,
              iter.fs,
              iter.mp,
              `${formatBytes(iter.total - iter.used)[0]} ${
                formatBytes(iter.total - iter.used)[1]
              } /${formatBytes(iter.total)[0]} ${formatBytes(iter.total)[1]} (${
                // iter.percent
                (
                  (formatBytes(iter.total - iter.used)[0] /
                    formatBytes(iter.total)[0]) *
                  100
                ).toFixed(2)
              }%)`,
              // iter.percent + " %",
              (
                100 -
                (
                  (formatBytes(iter.total - iter.used)[0] /
                    formatBytes(iter.total)[0]) *
                  100
                ).toFixed(2)
              ).toFixed(2) + " %",
              ""
            );
          });
          setMount(mp);
          setSystem(response.data.system);
          setTemperature(response.data.temperature || "NA");
        })
        .catch((err) => {
          setSystem(null);
          setMemory(null);
          setNetwork(null);
          setMount(null);
          setTemperature("NA");
          console.log("System status details fetching failed", err);
        });
    }
  }, [group, device]);

  useEffect(() => {
    if (groupFilter != null) {
      groupChange(groupFilter);
    }
  }, [groupFilter]);

  let totalAvailable = memory
    ? `${formatBytes(memory.free)[0]} ${formatBytes(memory.free)[1]} / ` +
      `${formatBytes(memory.totalAvailable)[0]} ${
        formatBytes(memory.totalAvailable)[1]
      } (` +
      parseInt(
        (memory.free == 0 ? 0 : memory.free / memory.totalAvailable) * 100
      ) +
      "%)"
    : "";
  let free = memory
    ? `${formatBytes(memory.totalAvailable - memory.free)[0]} ${
        formatBytes(memory.totalAvailable - memory.free)[1]
      } / ` +
      `${formatBytes(memory.totalAvailable)[0]} ${
        formatBytes(memory.totalAvailable)[1]
      } (` +
      // memory.totalAvailable -
      //   memory.free +
      //   " kb / " +
      //   memory.totalAvailable +
      // " kb ("
      parseInt(
        (memory.totalAvailable - memory.free == 0
          ? 0
          : (memory.totalAvailable - memory.free) / memory.totalAvailable) * 100
      ) +
      "%)"
    : "";
  let buffered = memory
    ? `${formatBytes(memory.buffered)[0]} ${
        formatBytes(memory.buffered)[1]
      } / ` +
      `${formatBytes(memory.totalAvailable)[0]} ${
        formatBytes(memory.totalAvailable)[1]
      } (` +
      // memory.buffered +
      //   " kb / " +
      //   memory.totalAvailable +
      // " kb ("
      +parseInt(
        (memory.buffered == 0 ? 0 : memory.buffered / memory.totalAvailable) *
          100
      ) +
      "%)"
    : "";

  let totalPercentage = memory
    ? parseInt(
        (memory.free == 0 ? 0 : memory.free / memory.totalAvailable) * 100
      ) + "%"
    : "0%";
  let freePercentage = memory
    ? parseInt(
        (memory.totalAvailable - memory.free == 0
          ? 0
          : (memory.totalAvailable - memory.free) / memory.totalAvailable) * 100
      ) + "%"
    : "0%";
  let bufferedPercentage = memory
    ? parseInt(
        (memory.buffered == 0 ? 0 : memory.buffered / memory.totalAvailable) *
          100
      ) + "%"
    : "0%";
  return (
    <div>
      <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            margin: "10px",
            fontSize: ".8rem",
            paddingLeft: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filter Devices
        </div>
        <div>
          <Select
            list={groupList}
            value={groupFilter}
            name={"Group"}
            onChange={(e) => {
              if (e == null) {
                setDeviceFilter(null);
                setDevicesList([]);
                setGroupFilter(null);
                setSystem(null);
                setMemory(null);
                setNetwork(null);
                setMount(null);
                setTemperature(null);
              } else {
                setGroupFilter(e);
              }
            }}
          />
        </div>
        <div>
          <Select
            list={devicesList}
            name={"Device"}
            value={deviceFilter}
            onChange={(e) => {
              setDeviceFilter(e);
            }}
          />
        </div>
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            width: "100px",
            marginTop: "-5px",
            margin: "10px",
            fontFamily: "sans-serif",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              height: "25px",
            }}
            onClick={() => {
              dispatch(deviceFun(deviceFilter));
              dispatch(groupFun(groupFilter));
              systemStatusData(deviceFilter);
              // setFilter(Math.random());
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <div>
        <div className="systemStatus">
          <div>
            <div className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ fontSize: "1rem", paddingLeft: 13 }}>
                    System
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                conatiner
                md={12}
                sm={12}
                style={{ paddingLeft: 15, marginTop: 10 }}
              >
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    HostName
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system ? system.hostname : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Model
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system ? system.model : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Architecture
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system ? system.architecture.join(", ") : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Firmware Version
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system ? system.firmwareVersion : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Kernel Version
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system ? system.kernalVersion : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Local Time
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system
                      ? moment(system.localTime * 1000)
                          .local()
                          .format("ddd MMM DD hh:mm:ss YYYY")
                      : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Uptime
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system ? system.upTime : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    CPU (%)
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {system && system.cpu ? `${system.cpu}` : "NA"}
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Temperature (C)
                  </Grid>
                  <Grid item xs={12} md={10} className={classes.systemRight}>
                    {temperature ? temperature : "NA"}
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
          <div
          //  item xs={12} style={{ paddingTop: "0px", minHeight: "24vh" }}
          >
            <div className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ fontSize: "1rem", paddingLeft: 13 }}>
                    Memory
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                conatiner
                md={12}
                sm={12}
                style={{ paddingLeft: 15, marginTop: 10 }}
              >
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Total Available
                  </Grid>
                  <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
                    {memory ? (
                      <div style={containerStyles}>
                        <div
                          style={{
                            height: "100%",
                            width: totalPercentage,
                            backgroundColor: "#0fcbd0",
                            borderRadius: "inherit",
                            textAlign: "center",
                          }}
                        >
                          <div style={labelStyles}>{totalAvailable}</div>
                        </div>
                      </div>
                    ) : (
                      <div style={containerStyles}>
                        <span style={labelStyles}>NA</span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
                    <br />
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Free
                  </Grid>
                  <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
                    {memory ? (
                      <div style={containerStyles}>
                        <div
                          style={{
                            height: "100%",
                            width: freePercentage,
                            backgroundColor: "#0fcbd0",
                            borderRadius: "inherit",
                            textAlign: "center",
                          }}
                        >
                          <span style={labelStyles}>{free}</span>
                        </div>
                      </div>
                    ) : (
                      <div style={containerStyles}>
                        <span style={labelStyles}>NA</span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
                    <br />
                  </Grid>
                </div>
                <div style={{ display: "flex" }}>
                  <Grid item xs={12} md={2} className={classes.systemLeft}>
                    Buffered
                  </Grid>
                  <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
                    {memory ? (
                      <div style={containerStyles}>
                        <div style={labelStyles}>{buffered}</div>
                        <div
                          style={{
                            height: "100%",
                            width: bufferedPercentage,
                            backgroundColor: "#0fcbd0",
                            borderRadius: "inherit",
                            textAlign: "center",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div style={containerStyles}>
                        <span style={labelStyles}>NA</span>
                      </div>
                    )}
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
          <div
          //</div>item xs={12} style={{ paddingTop: "0px", minHeight: "57vh" }}
          >
            <div className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ fontSize: "1rem", paddingLeft: 13 }}>
                    Network
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                md={12}
                sm={12}
                style={{ paddingLeft: 5, marginTop: 10 }}
              >
                <Grid item xs={12}>
                  <div style={{ overflow: "auto", whiteSpace: "nowrap" }}>
                    {/* <Grid container spacing={4}> */}
                    {/* <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ padding: "30px 0px 0px 50px" }}
                      > */}
                    {network?.map((iter) => {
                      return (
                        <div className="networkSlideBlock">
                          <div style={{ padding: "10px" }}> {iter.name} </div>
                          <div className={classes.network}>
                            <Grid
                              container
                              spacing={2}
                              style={{ margin: "0px" }}
                            >
                              <Grid
                                item
                                xs={12}
                                md={2.2}
                                className={classes.systemLeft}
                              >
                                Protocol:
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={8}
                                className={classes.systemRight}
                              >
                                IPV4
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={2.2}
                                className={classes.systemLeft}
                              >
                                Address
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={8}
                                className={classes.systemRight}
                              >
                                {iter.address_and_netmask_list[0].addresss}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={2.2}
                                className={classes.systemLeft}
                              >
                                Netmask:
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={8}
                                className={classes.systemRight}
                              >
                                {iter.address_and_netmask_list[0].net_mask !==
                                null
                                  ? iter.address_and_netmask_list[0].net_mask
                                  : "NA"}
                              </Grid>
                              {/* <Grid
                                item
                                xs={12}
                                md={2}
                                className={classes.systemLeft}
                              >
                                Connected:
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={10}
                                className={classes.systemRight}
                              >
                                ledge
                              </Grid> */}

                              <Grid
                                container
                                spacing={1}
                                style={{
                                  margin: "0px",
                                  width: "97%",
                                  backgroundColor: "#d2e1e3",
                                  padding: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                {/* <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  className={classes.systemLeft}
                                >
                                  Device:
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={8}
                                  className={classes.systemRight}
                                >
                                  ""
                                </Grid> */}
                                <Grid
                                  item
                                  xs={12}
                                  md={2.3}
                                  className={classes.systemLeft}
                                >
                                  MAC-Address:
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={8}
                                  className={classes.systemRight}
                                >
                                  {iter.address_and_netmask_list[0].mac}
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      );
                    })}
                    {/* </Grid> */}
                    {/* <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ padding: "30px 0px 0px 50px" }}
                      >
                        <div
                          style={{
                            backgroundColor: "#6fa5af",
                            boxShadow: "1px",
                            borderRadius: "12px",
                          }}
                        >
                          <div style={{ padding: "10px" }}> IPV4 Upstream</div>
                          <div className={classes.network}>
                            <Grid
                              container
                              spacing={2}
                              style={{ margin: "0px" }}
                            >
                              <Grid
                                item
                                xs={12}
                                md={2}
                                className={classes.systemLeft}
                              >
                                Protocol:
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={10}
                                className={classes.systemRight}
                              >
                                ledge
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={2}
                                className={classes.systemLeft}
                              >
                                Address
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={10}
                                className={classes.systemRight}
                              >
                                ledge
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={2}
                                className={classes.systemLeft}
                              >
                                Gateway:
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={10}
                                className={classes.systemRight}
                              >
                                ledge
                              </Grid>
                              <Grid item xs={12}>
                                <br />
                              </Grid>

                              <Grid
                                container
                                spacing={2}
                                style={{
                                  margin: "0px",
                                  width: "97%",
                                  backgroundColor: "#d2e1e3",
                                  padding: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  className={classes.systemLeft}
                                >
                                  Device:
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={8}
                                  className={classes.systemRight}
                                >
                                  ledge
                                </Grid>
                                <Grid item xs={12}>
                                  <br />
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid> */}
                    {/* </Grid> */}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <br />
                </Grid>
                {/* <Grid item xs={12} md={2} className={classes.systemLeft}>
                  Active Connections
                </Grid>
                <Grid item xs={12} md={5} style={{ paddingTop: "0px" }}>
                  <div style={containerStyles}>
                    <div style={fillerStyles}>
                      <div style={labelStyles}>
                        {"9000000kb/1000000kb 98%"}
                      </div>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
          </div>
          {/* <div>
            <div className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ fontSize: "1rem" }}> Active DHCP leases</div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: "10px",
                    }}
                  >
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow key="head">
                            {columns1.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: 900,
                                  backgroundColor: "#d2e1e3",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {table1.map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                onClick={() => {
                                  setModal({
                                    ...modal,
                                    open: true,
                                    details: row,
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {columns1.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </div>
          </div> */}
          <div>
            <div className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ fontSize: "1rem", paddingLeft: 13 }}>
                    Mounted File systems
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                  <Paper
                    sx={{
                      width: "99%",
                      overflow: "hidden",
                      borderRadius: "10px",
                      marginLeft: 1,
                    }}
                  >
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow key="head">
                            {columns2.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: 900,
                                  backgroundColor: "#d2e1e3",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {mount?.map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                onClick={() => {
                                  setModal({
                                    ...modal,
                                    open: true,
                                    details: row,
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {columns2.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </div>
          </div>
          {/* <div>
            <div className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div style={{ fontSize: "1rem" }}> Mount Points</div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                  <div style={{ fontSize: "11px", color: "gray" }}>
                    This list gives an overview over currently running system
                    processes and their status.
                  </div>
                  <br />
                  <Paper
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      borderRadius: "10px",
                    }}
                  >
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow key="head">
                            {columns3.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  minWidth: column.minWidth,
                                  fontWeight: 900,
                                  backgroundColor: "#d2e1e3",
                                }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {table3.map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                                onClick={() => {
                                  setModal({
                                    ...modal,
                                    open: true,
                                    details: row,
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {columns3.map((column) => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {column.format &&
                                      typeof value === "number"
                                        ? column.format(value)
                                        : value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SystemStatus;
