import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { invokeApi } from "./api/index";
import { API_URL_DEVICE, ADD_CARD, CREATE_SUBSCRIPTION } from "./api/constant";
import AddCardImage from "../resources/images/add-card.png";
import { toast } from "react-toastify";
import {
  Elements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
} from "@stripe/react-stripe-js";
import "./index.scss";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
const stripePromise = loadStripe(
  "pk_live_51KBconKrhx0TkMplNxJ8VYE29JSRCvzpmuZI9JtGrDxlNZ4TDr47wUKror99JmUjlk9ojbcDltnEJYsiwQFNnHO700pOGzw5Me"
);
const useStyles = makeStyles((theme) => ({
  inputField: {
    backgroundColor: "#E3ECF2",
    padding: 6,
    borderRadius: 20,
  },
  label: {
    color: "rgb(4, 85, 101)",
    marginBottom: 5,
    paddingLeft: 10,
    marginTop: "5px",
  },
  container: {
    border: "1px solid white",
    padding: 10,
    margin: "10px 40px 10px 40px",
    backgroundColor: "white",
    borderRadius: 20,
  },
  buttonContainer: {
    display: "flex",
    marginBottom: 10,
    minWidth: "30%",
    justifyContent: "space-around",
  },
}));

const AddCard = (props) => {
  const classes = useStyles();
  const history = useNavigate();

  useEffect(() => {
    props.headerName("Card");
  }, []);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [userDetails, setUserDetails] = useState({
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      zipCode: "",
      phoneNumber: "",
    });

    const countryOptions = Country.getAllCountries().map((i) => {
      return { label: i.name, value: i };
    });
    const [cityOptions, setCityOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);

    useEffect(() => {
      if (country != null) {
        setStateOptions(
          State.getStatesOfCountry(country.isoCode).map((i) => {
            return { label: i.name, value: i };
          })
        );
        setState(null);
        setCityOptions([]);
        setCity(null);
      }
    }, [country]);

    useEffect(() => {
      if (state != null) {
        setCityOptions(
          City.getCitiesOfState(country.isoCode, state.isoCode).map((i) => {
            return { label: i.name, value: i };
          })
        );
        setCity(null);
      }
    }, [state]);

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      if (
        userDetails.firstName !== "" &&
        userDetails.address1 !== "" &&
        userDetails.zipCode !== "" &&
        state !== null &&
        city !== null &&
        country !== null
      ) {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const res = await stripe.createToken(cardNumberElement, {
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          address_line1: `${userDetails.address1}`,
          address_line2: `${userDetails.address2}`,
          address_city: `${city.name}`,
          address_state: `${state.name}`,
          address_zip: `${userDetails.zipCode}`,
          address_country: `${country.name}`,
        });
        if (!res.error) {
          const Cardtoken = res.token.id;
          invokeApi("POST", `${API_URL_DEVICE}${ADD_CARD}`, {
            tok: Cardtoken,
          })
            .then((response) => {
              if (response.message) {
                toast.success(`${response.message}`, { autoClose: 2000 });
                elements.getElement(CardNumberElement).clear();
                elements.getElement(CardExpiryElement).clear();
                elements.getElement(CardCvcElement).clear();
                setUserDetails({
                  firstName: "",
                  lastName: "",
                  address1: "",
                  address2: "",
                  zipCode: "",
                });
                setCity(null);
                setCountry(null);
                setState(null);
              }
              history("/setting?tab=account");
            })
            .catch((error) => {
              console.log(error.message);
            });
        } else {
          toast.error(res.error.message, { autoClose: 2000 });
        }
      } else {
        toast.error("Incomplete User or Address Details");
      }
    };

    return (
      <div style={{ height: "100%", overflow: "auto" }}>
        <div className={classes.container}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <h2
                style={{
                  color: "rgb(4, 85, 101)",
                  paddingLeft: 10,
                  marginTop: "5px",
                }}
              >
                Card Details
              </h2>
              <Grid item md={12} xs={12} style={{ display: "flex" }}>
                <Grid md={12} xs={12}>
                  <Grid item md={10} xs={12}>
                    <div>
                      <h4 className={classes.label}>Card Number</h4>
                      <div
                        style={{
                          maxWidth: "90%",
                          backgroundColor: "#E3ECF2",
                          borderRadius: 20,
                          paddingLeft: 20,
                          padding: 13,
                          marginBottom: 10,
                        }}
                      >
                        <CardNumberElement />
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={10} xs={12} style={{ display: "flex" }}>
                    {" "}
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4 className={classes.label}>CVC</h4>
                        <div
                          style={{
                            maxWidth: "80%",
                            backgroundColor: "#E3ECF2",
                            borderRadius: 20,
                            paddingLeft: 20,
                            padding: 13,
                            marginBottom: 10,
                          }}
                        >
                          <CardCvcElement />
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4 className={classes.label}>Exp.</h4>
                        <div
                          style={{
                            maxWidth: "80%",
                            backgroundColor: "#E3ECF2",
                            borderRadius: 20,
                            paddingLeft: 20,
                            padding: 13,
                            marginBottom: 10,
                          }}
                        >
                          <CardExpiryElement />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={10} xs={12} style={{ display: "flex" }}>
                    {" "}
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4 className={classes.label}>Name on Card</h4>
                        <TextField
                          name="name"
                          onChange={(e) => {
                            setUserDetails((prevVal) => ({
                              ...prevVal,
                              firstName: e.target.value,
                            }));
                          }}
                          className={classes.inputField}
                          value={userDetails.firstName}
                          color="primary"
                          placeholder="First Name"
                          variant="standard"
                          style={{ width: "80%", backgroundColor: "none" }}
                          required
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4
                          className={classes.label}
                          style={{ color: "white" }}
                        >
                          .
                        </h4>
                        <TextField
                          name="name"
                          onChange={(e) => {
                            setUserDetails((prevVal) => ({
                              ...prevVal,
                              lastName: e.target.value,
                            }));
                          }}
                          className={classes.inputField}
                          color="primary"
                          value={userDetails.lastName}
                          placeholder="Last Name"
                          variant="standard"
                          style={{ width: "80%" }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid md={6} xs={12}>
              <img
                src={AddCardImage}
                alt="Add Card"
                style={{ width: 450, height: 305, marginTop: 10 }}
              />
            </Grid>
          </div>
        </div>
        <div className={classes.container}>
          <div>
            <h2
              style={{
                color: "rgb(4, 85, 101)",
                paddingLeft: 10,
                marginTop: "5px",
              }}
            >
              Address
            </h2>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12} style={{ display: "flex" }}>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>Line 1</h4>
                  <TextField
                    onChange={(e) => {
                      setUserDetails((prevVal) => ({
                        ...prevVal,
                        address1: e.target.value,
                      }));
                    }}
                    className={classes.inputField}
                    color="primary"
                    placeholder="Line 1"
                    required
                    value={userDetails.address1}
                    variant="standard"
                    style={{ width: "75%" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>Line 2</h4>
                  <TextField
                    onChange={(e) => {
                      setUserDetails((prevVal) => ({
                        ...prevVal,
                        address2: e.target.value,
                      }));
                    }}
                    className={classes.inputField}
                    color="primary"
                    placeholder="Line 2"
                    value={userDetails.address2}
                    variant="standard"
                    style={{ width: "75%" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>Country</h4>
                  <Select
                    classNamePrefix="custom_select"
                    placeholder="Country"
                    options={countryOptions}
                    onChange={(val) => {
                      setCountry(val.value);
                    }}
                    value={
                      country
                        ? countryOptions.find((i) => i.value === country)
                        : ""
                    }
                  />
                </Grid>
              </Grid>
              <Grid item md={12} xs={12} style={{ display: "flex" }}>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>State</h4>
                  <Select
                    classNamePrefix="custom_select"
                    placeholder="State"
                    options={stateOptions}
                    onChange={(val) => {
                      setState(val.value);
                    }}
                    value={
                      state ? stateOptions.find((i) => i.value === state) : ""
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>City</h4>
                  <Select
                    classNamePrefix="custom_select"
                    placeholder="City"
                    value={
                      city ? cityOptions.find((i) => i.value === city) : ""
                    }
                    options={cityOptions}
                    onChange={(val) => {
                      setCity(val.value);
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <div>
                    <h4 className={classes.label}>Zip Code</h4>
                    <TextField
                      name="name"
                      onChange={(e) => {
                        setUserDetails((prevVal) => ({
                          ...prevVal,
                          zipCode: e.target.value,
                        }));
                      }}
                      className={classes.inputField}
                      color="primary"
                      placeholder="Zip Code"
                      required
                      value={userDetails.zipCode}
                      variant="standard"
                      style={{ width: "75%" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        <Grid
          item
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgba(8, 202, 207, 0.87) ",
                borderRadius: 20,
                paddingRight: 60,
                paddingLeft: 60,
                color: "white",
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#06415d ",
                borderRadius: 20,
                paddingRight: 60,
                paddingLeft: 60,
                color: "white",
              }}
              onClick={() => {
                history("/setting?tab=account");
              }}
            >
              Back
            </Button>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default AddCard;
