import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { API_URL_USER, REFRESH } from "./constant";

const getToken = async () => {
  let tokens = JSON.parse(sessionStorage.getItem("user"))?.tokens;
  if (tokens === "" || tokens == undefined) {
    return;
  }
  let expiredAt =
    (tokens && tokens.access && new Date(tokens.access.expires)) ||
    new Date(1970);
  if (expiredAt > new Date()) {
    return (tokens && tokens.access && tokens.access.token) || "";
  } else if (tokens && tokens.refresh && tokens.refresh.token) {
    return await getRefreshToken(tokens.refresh.token);
  } else {
    return "";
  }
};

const getRefreshToken = async (refreshToken) => {
  try {
    const response = await trackPromise(
      fetch(`${API_URL_USER}${REFRESH}`, {
        method: "POST",
        body: JSON.stringify({
          refreshToken: refreshToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
    );
    const tokens = await response.json();
    if (tokens.code === 401 || tokens.code) {
      sessionStorage.clear();
    } else {
      let user = JSON.parse(sessionStorage.getItem("user"));
      user.tokens = tokens;
      sessionStorage.setItem("user", JSON.stringify(user));
    }
    return tokens.access.token || "";
  } catch (error) {
    console.error("Error:", error);
    return "";
  }
};

export const invokeApi = async (method, url, data, params, noToken) => {
  let headers = {};
  if (noToken) {
    headers["Content-Type"] = "application/json";
    headers["Content-Length"] = Number.MAX_SAFE_INTEGER;
  } else {
    headers["Content-Type"] = "application/json";
    headers["Content-Length"] = Number.MAX_SAFE_INTEGER;
    headers["Authorization"] = `Bearer ${await getToken()}`;
  }
  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }
  return trackPromise(
    fetch(url, {
      method: method,
      body: (data && JSON.stringify(data)) || undefined,
      headers: headers,
      params: params,
    }).then((response) => {
      if (response.status === 401) {
        sessionStorage.removeItem("user");
      }

      if (response.status === 204) {
        return response;
      } else {
        return response.json();
      }
    })
  );
};

export const plainApi = async (method, url, data, params) => {
  let headers = {
    "Content-Type": "application/json",
    "Content-Length": Number.MAX_SAFE_INTEGER,
    Authorization: `Bearer ${await getToken()}`,
  };

  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }

  return fetch(url, {
    method: method,
    body: (data && JSON.stringify(data)) || undefined,
    headers: headers,
    params: params,
  }).then((response) => {
    if (response.status === 401) {
      sessionStorage.removeItem("user");
    }

    if (response.status === 204) {
      return response;
    } else {
      return response.json();
    }
  });
};

export const invokeGraphApi = async (method, url, data, params) => {
  let headers = {
    "Content-Type": "application/json",
    "Content-Length": Number.MAX_SAFE_INTEGER,
    Authorization: `Bearer ${await getToken()}`,
  };

  if (params) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }
  fetch(url, {
    method: method,
    body: (data && JSON.stringify(data)) || undefined,
    headers: headers,
    params: params,
  }).then((response) => {
    if (response.status === 401) {
      sessionStorage.removeItem("user");
    }
    return response.json();
  });
};

export const checkPermission = (limitType, isAdmin, permission) => {
  let isAllowed = false;
  if (limitType === "ALLOW_INVITES_AND_MODIFY_ACCOUNTS") {
    isAllowed = isAdmin || permission;
    return isAllowed;
  }
  if (limitType === "ALLOW_BILLING") {
    isAllowed = isAdmin || permission;
    return isAllowed;
  }
  if (limitType === "ALLOW_MONITOR_DEVICE") {
    isAllowed = isAdmin || permission;
    return isAllowed;
  }
  if (limitType === "ALLOW_REGISTER_DEVICE") {
    isAllowed = isAdmin || permission;
    return isAllowed;
  }
};
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return [0, "B"];

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]];
};
export const progressiveInvokeApi = async (method, url, data, params) => {
  let headers = {
    Authorization: `Bearer ${await getToken()}`,
  };

  return axios({
    method: method,
    url: url,
    data: data,
    headers: headers,
    params: params,
  }).then((response) => {
    return response;
  });
};

export const invokeUploadFile = async (url, formData, setLoadPercentage) => {
  const options = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${await getToken()}`,
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let precentage = Math.floor((loaded * 100) / total);
      if (precentage < 100) {
        setLoadPercentage(precentage);
      }
    },
  };
  return axios.post(url, formData, options).then((response) => {
    setLoadPercentage(100);
    setLoadPercentage(() => {
      setTimeout(() => {
        setLoadPercentage(-1);
      }, 1000);
    });
    return response;
  });
};
