import React from "react";
import Add from "../../resources/images/add.png";
const buttonContainerStyle = {
  backgroundColor: "#0A4F6F",
  borderRadius: "15px",
  padding: "4px 10px 4px 4px",
  color: "white",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  width: "auto",
  marginTop: "20px",
  marginBottom: "20px",
};
const CreateButton = ({ title, style, onClickFunc }) => {
  return (
    <div style={{ ...buttonContainerStyle, ...style }} onClick={onClickFunc}>
      <div
        style={{
          width: 25,
          height: 25,
          borderRadius: "50%",
          backgroundColor: "white",
          display: "flex",
          margin: 1,
          marginRight: 5,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Add} alt="+" />
      </div>
      <p
        style={{
          margin: 0,
          fontSize: "12px",
          fontWeight: "normal",
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default CreateButton;
